import React, { useState } from 'react'
import LogoTop from '../../../Layout/LogoTop'
import {
  Button,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
  useTheme
} from '@mui/material'
import { useNavigate } from 'react-router-dom';
import ComboBox from '../../../DevComponents/ComboBox';

export default function NovoOrcamento () {

  const [dados, setDados] = useState( {
    idDentista: 1,
    idDia: 0,
    idHora: 0
  } )

  // const [erros, setErros] = useState( {} )

  const rsDia = [
    { idDia: 1, descricao: '29/10/2023' },
    { idDia: 2, descricao: '30/10/2023' },
    { idDia: 2, descricao: '31/10/2023' }
  ]

  const rsHora = [
    { idHora: 1, descricao: '09:00' },
    { idHora: 2, descricao: '10:00' },
    { idHora: 3, descricao: '10:30' },
    { idHora: 4, descricao: '15:00' }
  ]

  const theme = useTheme()

  const nav = useNavigate()

  const btOpcao = ( caminho: string ) => {
    nav( caminho )
  }

  return (
    <>
      <div className='imagemFundoBranco'>
        <LogoTop />

        <Grid
          container
          justifyContent='center'
          minHeight='100vh'
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <div>

              <Typography sx={{ ml: 5, mt: 5 }} variant='h6' fontWeight='bold' color='secondary'>
                Marque nas datas< br /> e horários disponíveis.
              </Typography>

              <Divider sx={{ mt: 1, ml: 5, borderBottomWidth: '2px', opacity: '70%', backgroundColor: theme.palette.primary.main }} />

            </div>

          </Grid>

          <Grid item xs={11} sm={8} md={6} lg={4} sx={{ mt: 2 }}>
            <Paper sx={{ backgroundColor: theme.cores.cinzaFundo, padding: 2, mb: 2 }}>

              <Grid container>
                <Grid item xs={12} mt={2}>

                  <TextField fullWidth disabled value='Divinópolis - Av. Primeiro de Junho, 725 - Centro' />

                </Grid>

                <Grid item xs={7} sx={{ pr: 1 }}>
                  <ComboBox
                    campoDescricao='descricao'
                    campoID='idDia'
                    opcoes={rsDia}
                    dados={dados}
                    field='idDia'
                    label='Dia:'
                    setState={setDados}
                    mensagemPadraoCampoEmBranco='Escolha o dia'
                  />
                </Grid>

                <Grid item xs={5}>
                  <ComboBox
                    campoDescricao='descricao'
                    campoID='idHorario'
                    opcoes={rsHora}
                    dados={dados}
                    field='idHorario'
                    label='Horário:'
                    setState={setDados}
                    mensagemPadraoCampoEmBranco='Escolha o dia'
                  />
                </Grid>

              </Grid>

              <Grid item xs={12} px={3} my={4}>
                <Button
                  fullWidth
                  variant='contained'
                  onClick={() => btOpcao( '/ConsultarConsultas' )}
                  sx={{ borderRadius: 2, backgroundColor: theme.palette.secondary.main }}>
                  Confirmar Novo Orçamento
                </Button>
              </Grid>

            </Paper>

          </Grid>

          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Button
              onClick={() => btOpcao( '/PacienteMenu' )}
              sx={{
                color: theme.palette.secondary.main,
                fontWeight: 'bold',
                mb: 2
              }}
              variant='text'>Voltar
            </Button>
          </Grid>

          <img alt='Vamos Sorrir Publicidade' src="/imagens/paciente/bannermenupaciente.png" width={'100%'} />

        </Grid>

      </div>
    </>
  )

}