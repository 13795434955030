import { StatusSessao } from '../../ImportApiWhatsAPP/tipos/dataTypes'

import QRCode from "react-qr-code";
import Condicional from '../../Layout/Condicional';

interface PropsInterface {
  status: StatusSessao
  urlCode: string
}

export default function QrCodeView ( { status, urlCode }: PropsInterface ) {

  /*
  const clsApi = new BackEndAPI()
  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface

  const [dados, setDados] = useState<ApiWhatsAppResponseInterface>( {
    mensagem: '',
    ok: false,
    status: StatusSessao.cadastroInicial
  } )

  const qrCode = () => {
    const query: string = `
      qrCode {
        ok
        status
        mensagem
      }
    `

    clsApi.mutation<ApiWhatsAppResponseInterface>( query, 'qrCode', 'Buscando QRCode...', contexto ).then( rs => {

      setDados( rs )

    } )
  }

  useEffect( () => {
    qrCode()
    //eslint-disable-next-line
  }, [] )

  */

  return (
    <>

      <Condicional condicao={status === StatusSessao.iniciando && urlCode.length > 0}>
        <QRCode
          size={256}
          style={{ height: "auto", maxWidth: "300px", width: "300px" }}
          value={urlCode}
          viewBox={`0 0 256 256`}
        />
      </Condicional>

    </>
  )

}