import React, { useContext, useEffect, useState } from 'react'
import { Box, Container, Grid, IconButton, Paper, Typography } from '@mui/material'
import InputText from '../../DevComponents/InputText'
import { ContextoGlobal, ContextoGlobalInterface } from '../../GlobalStates/ContextoGlobal'
import Condicional from '../../Layout/Condicional'
import Button from '@mui/material/Button'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

import DataTable, { DataTableCabecalhoInterface } from '../../DevComponents/DataTable'
import { useNavigate } from 'react-router-dom'
import { DentistaInterface, DentistaPesquisaInterface } from '../../ImportBackend/Interfaces/DentistaInterfaces'
import ClsCrud from '../../Utils/ClsCrud'

import ClsValidacao from '../../Utils/ClsValidacao'
import { ENDPOINT } from '../../ImportBackend/Config/emDesenvolvimento'
import PesquisarTabela from '../../DevComponents/PesquisarTabela'
import { DentistaEcuroInterface } from '../../ImportBackend/Interfaces/EcuroInterfaces'
import BackEndAPI from '../../Services/BackEndAPI'
import { MensagemTipo } from '../../GlobalStates/MensagemState'
import ComboBox from '../../DevComponents/ComboBox'

import axios from 'axios'

import CameraAltIcon from '@mui/icons-material/CameraAlt';
import ClsAcesso from '../../Utils/ClsAcesso'
import { SISTEMA_PERMISSOES } from '../../ImportBackend/types/AcessosDataTypes'
import DentistasAgenda from './DentistasAgenda'
import ExibirJSONDev from '../../DevComponents/ExibirJSONDev'
import DentistasEspecialidades from './DentistasEspecialidades'

export enum StatusForm {
  Incluindo,
  Excluindo,
  Pesquisando,
  Editando,
  EditandoAgenda,
  EditandoEspecialidades
}

export default function Dentistas () {

  const [statusForm, setStatusForm] = useState<StatusForm>( StatusForm.Pesquisando )

  const Cabecalho: Array<DataTableCabecalhoInterface> = [
    {
      campo: 'nome',
      cabecalho: 'Nome',
      alinhamento: 'left'
    },
    {
      campo: 'cro',
      cabecalho: 'CRO',
      alinhamento: 'left'
    },
    /*
    {
      campo: 'minutoEntreConsultas',
      cabecalho: 'Tempo Consultas',
      alinhamento: 'right'
    },
    {
      campo: 'marcarAvaliacao',
      cabecalho: 'Avaliação',
      alinhamento: 'left',
      format: ( v ) => v ? 'Sim' : 'Não'
    },
    {
      campo: 'marcarOrtodontia',
      cabecalho: 'Orto',
      alinhamento: 'left',
      format: ( v ) => v ? 'Sim' : 'Não'
    },
    {
      campo: 'marcarOutras',
      cabecalho: 'Outras',
      alinhamento: 'left',
      format: ( v ) => v ? 'Sim' : 'Não'
    },
    */
    {
      campo: 'ativo',
      cabecalho: 'Ativo',
      alinhamento: 'left',
      format: ( v ) => v ? 'Sim' : 'Não'
    }
  ]

  const ResetDados: DentistaInterface = {
    idDentista: 0,
    idClinica: 0,
    idEcuroDentista: '',
    ativo: false,
    nome: '',
    cro: ''
  }

  const TituloForm = {
    [StatusForm.Incluindo]: 'Inclusão de Novo Dentista',
    [StatusForm.Excluindo]: 'Exclusão de Dentista',
    [StatusForm.Pesquisando]: 'Dentistas disponívels no APP',
    [StatusForm.Editando]: 'Alteração de Dados de Dentistas',
    [StatusForm.EditandoAgenda]: 'Bloqueio / Liberação de Horários para o APP',
    [StatusForm.EditandoEspecialidades]: 'Especialidades dos Dentistas'
  }

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface
  const { mensagemState, setMensagemState } = contexto

  const [dados, setDados] = useState<DentistaInterface>( ResetDados )

  const [erros, setErros] = useState( {} )
  const [errosPesquisa, setErrosPesquisa] = useState( {} )

  const [pesquisa, setPesquisa] = useState<DentistaPesquisaInterface>( { descricao: '', idClinica: 0 } )

  const [rsPesquisa, setRsPesquisa] = useState<Array<DentistaInterface>>( [] )

  const [rsDentistasEcuro, setRsDentistasEcuro] = useState<Array<DentistaEcuroInterface>>( [] )

  const [refreshFoto, setRefreshFoto] = useState( 1 )

  const navigate = useNavigate()

  const validarDados = (): boolean => {

    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.naoVazio( 'nome', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'cro', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'idEcuroDentista', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'idClinica', dados, erros, retorno )

    setErros( erros )

    return retorno

  }

  const clsCrud: ClsCrud<DentistaInterface> = new ClsCrud(
    navigate,
    ResetDados,
    setStatusForm as any,
    setDados,
    setErros,
    mensagemState,
    setMensagemState,
    setRsPesquisa,
    contexto,
    validarDados,
    {
      confirmarMutation: 'updateDentista',
      excluirMutation: 'delDentista',
      campoId: 'idDentista',
      camposPesquisa: '{idDentista ativo nome cro}',
      pesquisaQuery: 'getDentistas',
      pesquisaPorId: 'getDentistaPorId',
      camposPesquisaPorId: '{idDentista idClinica idEcuroDentista ativo nome cro}'
    },
    {
      confirmando: 'Atualizando Dentista',
      erroCadastro: 'Erro ao Cadastrar Dentista',
      erroExclusao: 'Erro ao Excluir Dentista',
      erroPesquisa: 'Erro ao Pesquisar Dentista',
      pesquisando: 'Pesquisando Dados de Dentistas...',
      sucessoCadastro: 'Dentista Cadastrada com Sucesso!',
      atualizacaoSucesso: 'Dentista Atualizada com Sucesso!',
      tituloConfirmado: 'Confirmado!',
      sucessoExclusao: 'Dentista Excluída com Sucesso...',
      tituloConfirmacaoExclusao: 'Confirma?',
      tituloErroCadastro: 'Erro!',
      tituloErroExclusao: 'Erro!',
      tituloErroPesquisa: 'Erro!',
      excluindo: 'Excluindo Dentista...'
    }
  )

  const abortController: AbortController = new AbortController()

  const pesquisarDentistasEcuro = () => {

    if ( pesquisa.idClinica !== 0 ) {

      const clsApi = new BackEndAPI()

      const query = `
      getEcuroDentistasPorClinica(idClinica: ${pesquisa.idClinica}) {
        idEcuroDentista
        nome
        email
        cro
      }
    `

      clsApi.query<Array<DentistaEcuroInterface>>( query, 'getEcuroDentistasPorClinica', 'Pesquisando Dentistas No Ecuro...', contexto, abortController ).then( rsDentistas => {
        setRsDentistasEcuro( rsDentistas )

      } ).catch( () => {

        setMensagemState( {
          ...mensagemState,
          titulo: 'Erro! Consulte Suporte!',
          exibir: true,
          mensagem: 'Erro ao Consultar Dentistas no Ecuro!',
          tipo: MensagemTipo.Error,
          exibirBotao: true
        } )

      } )

    }

  }

  useEffect( () => {
    pesquisarDentistasEcuro()
    // eslint-disable-next-line
  }, [pesquisa.idClinica] )

  const validarPesquisa = (): boolean => {
    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.naoVazio( 'idClinica', pesquisa, erros, retorno )

    setErrosPesquisa( erros )

    return retorno
  }

  const onKeyPesquisa = () => {
    if ( validarPesquisa() )
      clsCrud.onClickPesquisa( pesquisa, mensagemState )
  }

  const btIncluir = () => {
    if ( validarPesquisa() )
      clsCrud.btIncluir()
  }

  const alterouDentista = ( v: DentistaEcuroInterface ) => {
    setDados( { ...dados, idEcuroDentista: v.idEcuroDentista, ativo: true, cro: v.cro, idClinica: pesquisa.idClinica, nome: v.nome } )
  }

  const getToken = () => {
    const token = localStorage.getItem( 'token' )
    return token ? token : ''
  }

  const btUpload = () => {

    if ( validarDados() ) {

      let input: HTMLInputElement = document.createElement( 'input' )

      input.type = 'file'
      input.name = 'nomeArquivo'
      input.accept = "image/png"

      input.onchange = () => {

        if ( input.files && dados.idDentista ) {

          const formulario: FormData = new FormData()

          formulario.append( 'nomeArquivo', input.files[0] )
          formulario.append( 'idDentista', dados.idDentista.toString() )
          formulario.append( 'token', getToken() )

          setMensagemState( {
            mensagem: 'Carregando Foto...',
            exibir: true,
            exibirBotao: false,
            titulo: 'Aguarde!',
            tipo: 'Loading',
            cb: null
          } )

          axios.post( ENDPOINT.concat( 'uploadFotoDentista' ), formulario, {
            headers: {
              'content-type': 'multipart/form-data'
            } //,
            // onUploadProgress: this.calculaprogressoUpload
          } ).then( ( rs ) => {

            if ( !rs.data.ok ) {

              setMensagemState( {
                mensagem: rs.data.mensagem,
                exibir: true,
                exibirBotao: true,
                titulo: 'Erro!',
                tipo: 'error',
                cb: null
              } )

            } else {
              setMensagemState( { ...mensagemState, exibir: false } )
              // setDados( { descricao: '' } )
              setRefreshFoto( refreshFoto + 1 )
            }

          } ).catch( () => {
            setMensagemState( {
              mensagem: 'Erro no Servidor!',
              exibir: true,
              exibirBotao: true,
              titulo: 'Consulte Suporte!',
              tipo: 'error',
              cb: null
            } )
          } )

        }


      }

      input.click()

    }

  }

  const [rsFotoDentista, setRsFotoDentista] = useState( '' )

  const downloadFotoDentista = () => {

    if ( dados.idDentista ) {

      const clsApi = new BackEndAPI()

      const query = `
        downloadFoto(idDentista: ${dados.idDentista})
      `

      clsApi.query<string>( query, 'downloadFoto', 'Download Foto...', contexto, abortController ).then( rsFoto => {
        setRsFotoDentista( rsFoto )
      } ).catch( () => {

        setMensagemState( {
          ...mensagemState,
          titulo: 'Erro! Consulte Suporte!',
          exibir: true,
          mensagem: 'Erro ao Baixar Foto do Dentista!',
          tipo: MensagemTipo.Error,
          exibirBotao: true
        } )

      } )

    }

  }

  useEffect( () => {

    if ( statusForm === StatusForm.Editando || statusForm === StatusForm.Excluindo ) {
      downloadFotoDentista()
    }

    // eslint-disable-next-line
  }, [refreshFoto, dados.idDentista] )

  const btAgendaDentista = ( rs: DentistaInterface ) => {
    setDados( rs )
    setStatusForm( StatusForm.EditandoAgenda )
  }

  const btEspecialidadesDentista = ( rs: DentistaInterface ) => {
    setDados( rs )
    setStatusForm( StatusForm.EditandoEspecialidades )
  }

  const btCancelarEdicao = () => {
    setDados( ResetDados )
    setStatusForm( StatusForm.Pesquisando )
  }

  const clsAcesso: ClsAcesso = new ClsAcesso()

  const permiteAgenda = clsAcesso.chkAcesso( contexto.loginState.permissoes, SISTEMA_PERMISSOES.DENTISTAS.MODULO, SISTEMA_PERMISSOES.DENTISTAS.PERMISSOES.AGENDA )
  const permiteDentista = clsAcesso.chkAcesso( contexto.loginState.permissoes, SISTEMA_PERMISSOES.DENTISTAS.MODULO, SISTEMA_PERMISSOES.DENTISTAS.PERMISSOES.MANUTENCAO )

  const acoesPermitidas = ( [] as any ).concat(
    permiteDentista ? [
      { icone: 'delete', toolTip: 'Exluir', onAcionador: clsCrud.btExcluir },
      { icone: 'create', toolTip: 'Editar', onAcionador: clsCrud.btEditar }] : []
  ).concat(
    permiteAgenda ? [
      { icone: 'schedule', toolTip: 'Agenda', onAcionador: btAgendaDentista },
      { icone: 'work', toolTip: 'Especialidades', onAcionador: btEspecialidadesDentista }
    ] : []
  )

  return (
    <>
      <Container maxWidth="md" sx={{ mt: 5 }}>

        <Paper variant="outlined" sx={{ padding: 2 }}>
          <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography component="h5" variant="h5" align="left">
                Cadastro de Dentistas
                <Typography variant="body2" gutterBottom>
                  {TituloForm[statusForm]}
                </Typography>
              </Typography>

              <IconButton onClick={() => clsCrud.btFechar()}>
                <CloseIcon />
              </IconButton>
            </Grid>

            <Grid item xs={12}>

              <PesquisarTabela<any>
                setState={setPesquisa}
                field='idClinica'
                fieldSet='idClinica'
                label='Clínica'
                dados={pesquisa}
                campoQueryPesquisaID='idClinica'
                campoQueryPesquisa='pesquisa'
                camposRetornoQueryPesquisa='{idClinica, descricao}'
                campoLabelQueryPesquisa='descricao'
                nomeQueryPesquisa='getClinicas'
                nomeQueryPesquisaID='getClinicaPorId'
                mensagemPesquisa='Procurando Clínicas...'
                pesquisarTudoAoIniciar
                erros={errosPesquisa}
                disabled={statusForm !== StatusForm.Pesquisando}
              />

            </Grid>

            <Condicional condicao={statusForm === StatusForm.Pesquisando}>

              <Grid item xs={12} sm={10} >

                <InputText
                  dados={pesquisa}
                  field='descricao'
                  label='Pesquisar'
                  setState={setPesquisa}
                  iconeEnd="search"
                  onClickIconeEnd={() => onKeyPesquisa()}
                  mapKeyPress={[{ key: 'Enter', onKey: onKeyPesquisa }]}
                />

              </Grid>

              <Grid item xs={12} sm={2} sx={{ mt: { xs: 3, sm: 4.5 }, textAlign: { xs: 'right', sm: 'center' } }}>
                <Button variant='contained' onClick={
                  () => {
                    btIncluir()
                  }
                }>Incluir</Button>
              </Grid>

            </Condicional>

            <Condicional condicao={statusForm !== StatusForm.Pesquisando && statusForm !== StatusForm.EditandoAgenda && statusForm !== StatusForm.EditandoEspecialidades}>

              <Grid item xs={12}>
                <ComboBox
                  campoDescricao='nome'
                  campoID='idEcuroDentista'
                  dados={dados}
                  field='idEcuroDentista'
                  label='Dentista'
                  opcoes={rsDentistasEcuro}
                  erros={erros}
                  onChange={( v: DentistaEcuroInterface ) => alterouDentista( v )}
                  disabled={statusForm === StatusForm.Excluindo}
                />
              </Grid>
              <Grid item xs={12}>

                <InputText
                  dados={dados}
                  field='nome'
                  label='Nome'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  maxLength={50}
                />

              </Grid>

              <Grid item xs={8}>

                <InputText
                  dados={dados}
                  field='cro'
                  label='CRO'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  maxLength={20}
                />

              </Grid>

              {/*
              

              <Grid item xs={4} sm={5} sx={{ pl: 1 }}>

                <InputText
                  dados={dados}
                  field='minutoEntreConsultas'
                  label='Tempo entre Consultas (min)'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  maxLength={3}
                  tipo='number'
                  mask='000'
                />

              </Grid>
              */}

              <Grid item xs={4} sx={{ pl: { xs: 1 }, mt: 3 }}>
                <Box display="flex" justifyContent="center" alignContent='center' sx={{ height: '100%' }}>
                  <InputText
                    dados={dados}
                    field='ativo'
                    label='Ativo'
                    setState={setDados}
                    tipo='checkbox'
                    disabled={statusForm === StatusForm.Excluindo}
                    erros={erros}
                  />
                </Box>
              </Grid>

              {/*

              <Grid item xs={12} sx={{ mt: 3 }}>

                <Box display="flex" alignItems='center' justifyContent="center">

                  <Box>
                    <InputText
                      dados={dados}
                      field='marcarAvaliacao'
                      label='Avaliação'
                      setState={setDados}
                      tipo='checkbox'
                      disabled={statusForm === StatusForm.Excluindo}
                      erros={erros}
                    />
                    <InputText
                      dados={dados}
                      field='marcarOrtodontia'
                      label='Ortodontia'
                      setState={setDados}
                      tipo='checkbox'
                      disabled={statusForm === StatusForm.Excluindo}
                      erros={erros}
                    />
                    <InputText
                      dados={dados}
                      field='marcarOutras'
                      label='Outras Especialidades'
                      setState={setDados}
                      tipo='checkbox'
                      disabled={statusForm === StatusForm.Excluindo}
                      erros={erros}
                    />
                  </Box>
                        </Box>
                        </Grid>

            */}

              <Condicional condicao={statusForm !== StatusForm.Incluindo}>
                <Grid item xs={12} sx={{ mt: 3 }}>
                  <Box>
                    <IconButton
                      disabled={statusForm !== StatusForm.Editando}
                      className='fotoDentistaBotao'
                      onClick={() => btUpload()} >
                      <CameraAltIcon />
                    </IconButton>
                    <div className='fotoDentista'>
                      <img width='100%' height='auto' key={refreshFoto.toString()} src={"data:image/png;base64,".concat( rsFotoDentista )} alt='FotoDentista' />
                    </div>
                  </Box>
                </Grid>
              </Condicional>

              <Grid item xs={12} sx={{ mt: 3 }}>

                <Condicional condicao={statusForm === StatusForm.Excluindo}>
                  <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => clsCrud.btConfirmarExclusao( dados, mensagemState, pesquisa )}>Confirmar</Button>
                </Condicional>

                <Condicional condicao={statusForm !== StatusForm.Excluindo}>
                  <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => clsCrud.btConfirmar( dados, mensagemState, statusForm as any, pesquisa )}>Confirmar</Button>
                </Condicional>

                <Button variant='contained' startIcon={<CloseIcon />} sx={{ py: 1 }} onClick={() => clsCrud.btCancelar()}>Cancelar</Button>

              </Grid>

            </Condicional>

            <Condicional condicao={statusForm === StatusForm.Pesquisando}>
              <Grid item xs={12} sx={{ mt: 3 }}>
                <DataTable dados={rsPesquisa} cabecalho={Cabecalho} acoes={acoesPermitidas} />
              </Grid>
            </Condicional>

            <DentistasAgenda
              statusForm={statusForm as unknown as any}
              dados={dados}
              onCancelar={btCancelarEdicao}
            />

            <DentistasEspecialidades
              statusForm={statusForm as unknown as any}
              dados={dados}
              onCancelar={btCancelarEdicao}
            />

            <ExibirJSONDev oque={[
              'Token',
              contexto.loginState.token,
              'Dados',
              dados,
              'Erros',
              erros
            ]}></ExibirJSONDev>

          </Grid>
        </Paper >

      </Container >
    </>
  )
}