import React, { useContext, useState } from 'react'
import { Container, Grid, IconButton, Paper, Typography } from '@mui/material'
import InputText from '../../DevComponents/InputText'
import { ContextoGlobal, ContextoGlobalInterface } from '../../GlobalStates/ContextoGlobal'
import Condicional from '../../Layout/Condicional'
import Button from '@mui/material/Button'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

import DataTable, { DataTableCabecalhoInterface } from '../../DevComponents/DataTable'
import { useNavigate } from 'react-router-dom'
import ClsCrud from '../../Utils/ClsCrud'

import ClsValidacao from '../../Utils/ClsValidacao'
import DentistasAgenda from './DentistasAgenda'
import ExibirJSONDev from '../../DevComponents/ExibirJSONDev'
import { UnidadeOrganizacionalInterface } from '../../ImportBackend/Interfaces/UnidadeOrganizacionalInterfaces'
import UnidadesOrganizacionaisUsuarios from './UnidadesOrganizacionaisUsuarios'

export enum StatusForm {
  Incluindo,
  Excluindo,
  Pesquisando,
  Editando,
  EditandoUsuarios
}

interface PesquisaInterface {
  descricao: string
}

export default function UnidadesOrganizacionais () {

  const [statusForm, setStatusForm] = useState<StatusForm>( StatusForm.Pesquisando )

  const Cabecalho: Array<DataTableCabecalhoInterface> = [
    {
      campo: 'descricao',
      cabecalho: 'Descrição',
      alinhamento: 'left'
    }
  ]

  const ResetDados: UnidadeOrganizacionalInterface = {
    idUnidadeOrganizacional: 0,
    descricao: ''
  }

  const TituloForm = {
    [StatusForm.Incluindo]: 'Inclusão de nova Unidade Organizacional',
    [StatusForm.Excluindo]: 'Exclusão de Unidade Organizacional',
    [StatusForm.Pesquisando]: 'Unidades Organizacionais cadastradas',
    [StatusForm.Editando]: 'Alteração de dados de Unidade Organizacional',
    [StatusForm.EditandoUsuarios]: 'Edição de Usuários da Unidade Organizacional',
  }

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface
  const { mensagemState, setMensagemState } = contexto

  const [dados, setDados] = useState<UnidadeOrganizacionalInterface>( ResetDados )

  const [erros, setErros] = useState( {} )

  const [pesquisa, setPesquisa] = useState<PesquisaInterface>( { descricao: '' } )

  const [rsPesquisa, setRsPesquisa] = useState<Array<UnidadeOrganizacionalInterface>>( [] )

  const navigate = useNavigate()

  const validarDados = (): boolean => {

    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.naoVazio( 'descricao', dados, erros, retorno )

    setErros( erros )

    return retorno

  }

  const clsCrud: ClsCrud<UnidadeOrganizacionalInterface> = new ClsCrud(
    navigate,
    ResetDados,
    setStatusForm as any,
    setDados,
    setErros,
    mensagemState,
    setMensagemState,
    setRsPesquisa,
    contexto,
    validarDados,
    {
      confirmarMutation: 'updateUnidadeOrganizacional',
      excluirMutation: 'delUnidadeOrganizacional',
      campoId: 'idUnidadeOrganizacional',
      camposPesquisa: '{idUnidadeOrganizacional descricao}',
      pesquisaQuery: 'getUnidadesOrganizacionais',
      pesquisaPorId: 'getUnidadeOrganizacionalPorId',
      camposPesquisaPorId: '{idUnidadeOrganizacional descricao}'
    },
    {
      confirmando: 'Atualizando Unidade Organizacional',
      erroCadastro: 'Erro ao Cadastrar Unidade Organizacional',
      erroExclusao: 'Erro ao Excluir Unidade Organizacional',
      erroPesquisa: 'Erro ao Pesquisar Unidade Organizacional',
      pesquisando: 'Pesquisando Dados de Unidades Organizacionais...',
      sucessoCadastro: 'Unidade Organizacional Cadastrada com Sucesso!',
      atualizacaoSucesso: 'Unidade Organizacional Atualizada com Sucesso!',
      tituloConfirmado: 'Confirmado!',
      sucessoExclusao: 'Unidade Organizacional Excluída com Sucesso...',
      tituloConfirmacaoExclusao: 'Confirma?',
      tituloErroCadastro: 'Erro!',
      tituloErroExclusao: 'Erro!',
      tituloErroPesquisa: 'Erro!',
      excluindo: 'Excluindo Unidade Organizacional...'
    }
  )

  const onKeyPesquisa = () => {
    clsCrud.onClickPesquisa( pesquisa.descricao, mensagemState )
  }

  const btEditar = ( rs: UnidadeOrganizacionalInterface ) => {
    setDados( rs )
    setStatusForm( StatusForm.Editando )
  }

  const btExcluir = ( rs: UnidadeOrganizacionalInterface ) => {
    setDados( rs )
    setStatusForm( StatusForm.Excluindo )
  }

  const btEditarUsuarios = ( rs: UnidadeOrganizacionalInterface ) => {
    setDados( rs )
    setStatusForm( StatusForm.EditandoUsuarios )
  }
  return (
    <>
      <Container maxWidth="md" sx={{ mt: 5 }}>

        <Paper variant="outlined" sx={{ padding: 2 }}>
          <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography component="h5" variant="h5" align="left">
                Cadastro de Unidades Organizacionais
                <Typography variant="body2" gutterBottom>
                  {TituloForm[statusForm]}
                </Typography>
              </Typography>

              <IconButton onClick={() => clsCrud.btFechar()}>
                <CloseIcon />
              </IconButton>
            </Grid>

            <Condicional condicao={statusForm === StatusForm.Pesquisando}>

              <Grid item xs={12} sm={10} >

                <InputText
                  dados={pesquisa}
                  field='descricao'
                  label='Pesquisar'
                  setState={setPesquisa}
                  iconeEnd="search"
                  onClickIconeEnd={() => onKeyPesquisa()}
                  mapKeyPress={[{ key: 'Enter', onKey: onKeyPesquisa }]}
                />

              </Grid>

              <Grid item xs={12} sm={2} sx={{ mt: { xs: 3, sm: 4.5 }, textAlign: { xs: 'right', sm: 'center' } }}>
                <Button variant='contained' onClick={() => { clsCrud.btIncluir() }}>Incluir</Button>
              </Grid>

            </Condicional>

            <Condicional condicao={statusForm !== StatusForm.Pesquisando && statusForm !== StatusForm.EditandoUsuarios}>

              <Grid item xs={12}>

                <InputText
                  dados={dados}
                  field='descricao'
                  label='Descrição'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo}
                  erros={erros}
                  maxLength={50}
                />

              </Grid>

              <Grid item xs={12} sx={{ mt: 3 }}>

                <Condicional condicao={statusForm === StatusForm.Excluindo}>
                  <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => clsCrud.btConfirmarExclusao( dados, mensagemState, pesquisa.descricao )}>Confirmar</Button>
                </Condicional>

                <Condicional condicao={statusForm !== StatusForm.Excluindo}>
                  <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => clsCrud.btConfirmar( dados, mensagemState, statusForm as any, pesquisa.descricao )}>Confirmar</Button>
                </Condicional>

                <Button variant='contained' startIcon={<CloseIcon />} sx={{ py: 1 }} onClick={() => clsCrud.btCancelar()}>Cancelar</Button>

              </Grid>

            </Condicional>

            <Condicional condicao={statusForm === StatusForm.Pesquisando}>
              <Grid item xs={12} sx={{ mt: 3 }}>
                <DataTable dados={rsPesquisa} cabecalho={Cabecalho} acoes={[
                  { icone: 'delete', toolTip: 'Excluir', onAcionador: btExcluir },
                  { icone: 'create', toolTip: 'Editar', onAcionador: btEditar },
                  { icone: 'person_rounded', toolTip: 'Usuários', onAcionador: btEditarUsuarios },
                ]
                } />
              </Grid>
            </Condicional>

            <UnidadesOrganizacionaisUsuarios
              statusForm={statusForm as unknown as any}
              rsUnidadeOrganizacional={dados}
            />


            <ExibirJSONDev oque={[
              'Dados',
              dados,
              'Erros',
              erros
            ]}></ExibirJSONDev>

          </Grid>
        </Paper >

      </Container >
    </>
  )
}