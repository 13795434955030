export enum TipoConfirmacaoCadastro {
  EMAIL = 'Email',
  WHATSAPP = 'WhatsApp',
  RESET_SENHA = 'ResetSenha'
}

export enum TipoUsuario {
  SISTEMA = 1,
  PACIENTE = 2,
  NAO_LOGADO = 0
}

/*
export enum StatusRole {
  NOVO = 10,
  TRATATIVA = 20,
  OPORTUNIDADE = 25,
  NEGOCIACAO = 30,
  NAO_ATENDE = 40,
  CURSO_GRATIS = 45,
  INCORRETO = 50,
  SEM_INTERESSE = 60,
  CONTATO_FUTURO = 70,
  PRE_MATRICULA = 75,
  MATRICULA = 80
}



// Revisado em Utilização...
export const StatusTiposUsuariosPermitidoAlterarStatusNovoCliente = [TipoUsuarioRole.ADMIN, TipoUsuarioRole.SUPERVISOR, TipoUsuarioRole.VENDEDOR]
export const StatusPermitidoVendedorNovosClientes = [StatusRole.TRATATIVA, StatusRole.OPORTUNIDADE, StatusRole.NEGOCIACAO, StatusRole.NAO_ATENDE, StatusRole.CURSO_GRATIS, StatusRole.INCORRETO, StatusRole.SEM_INTERESSE, StatusRole.CONTATO_FUTURO, StatusRole.PRE_MATRICULA]

// A Revisar
export const StatusTravadoParaSupervisor: Array<number> = [StatusRole.NEGOCIACAO, StatusRole.OPORTUNIDADE, StatusRole.PRE_MATRICULA]
export const StatusFecharLeadRole: Array<number> = [StatusRole.NAO_ATENDE, StatusRole.CURSO_GRATIS, StatusRole.INCORRETO, StatusRole.SEM_INTERESSE, StatusRole.CONTATO_FUTURO, StatusRole.MATRICULA]

export const StatusRoleDescricao: Array<StatusInterface> = [
  { idStatus: StatusRole.NOVO, descricao: 'Novo' },
  { idStatus: StatusRole.TRATATIVA, descricao: 'Tratativa' },
  { idStatus: StatusRole.OPORTUNIDADE, descricao: 'Oportunidade' },
  { idStatus: StatusRole.NEGOCIACAO, descricao: 'Negociação' },
  { idStatus: StatusRole.NAO_ATENDE, descricao: 'Não Atende' },
  { idStatus: StatusRole.CURSO_GRATIS, descricao: 'Curso Grátis' },
  { idStatus: StatusRole.INCORRETO, descricao: 'Incorreto' },
  { idStatus: StatusRole.SEM_INTERESSE, descricao: 'Sem Interesse' },
  { idStatus: StatusRole.CONTATO_FUTURO, descricao: 'Contato Futuro' },
  { idStatus: StatusRole.PRE_MATRICULA, descricao: 'Pré Matrícula' },
  { idStatus: StatusRole.MATRICULA, descricao: 'Matrícula' }
]

export enum CanalRole {
  SITE = 1,
  EMAIL = 2,
  WHATSAPP = 3,
  VISITA = 4,
  TELEFONE = 5,
  INSTAGRAM = 6,
  FACEBOOK = 7
}

export const CanalRoleDescricao: Array<CanalInterface> = [
  { idCanal: CanalRole.SITE, descricao: 'Site' },
  { idCanal: CanalRole.EMAIL, descricao: 'Email' },
  { idCanal: CanalRole.WHATSAPP, descricao: 'Whatsapp' },
  { idCanal: CanalRole.VISITA, descricao: 'Visita' },
  { idCanal: CanalRole.TELEFONE, descricao: 'Telefone' },
  { idCanal: CanalRole.INSTAGRAM, descricao: 'Instagram' },
  { idCanal: CanalRole.FACEBOOK, descricao: 'Facebook' }
]


export enum TipoLeadRole {
  ATIVO = 1,
  RECEPTIVO = 2
}

export const TipoLeadRoleDescricao: Array<TipoLeadInterface> = [
  { idTipo: TipoLeadRole.ATIVO, descricao: 'Ativo' },
  { idTipo: TipoLeadRole.RECEPTIVO, descricao: 'Receptivo' }
]

export enum ClienteUpdateRole {
  NOVO = 1,
  EXISTENTE_SEM_LEAD = 2,
  EXISTENTE_COM_LEAD = 3
}
*/