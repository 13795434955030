export enum StatusSessao {
  instanciaNaoCadastrada = 'instanciaNaoCadastrada',
  cadastroInicial = 'cadastroInicial',
  iniciando = 'iniciando',
  naoConectada = 'naoConectada',
  erro = 'erro',
  conectada = 'conectada',
  finalizada = 'finalizada',
}

export enum StateConnection {
  CONFLICT = 'CONFLICT',
  CONNECTED = 'CONNECTED',
  DEPRECATED_VERSION = 'DEPRECATED_VERSION',
  OPENING = 'OPENING',
  PAIRING = 'PAIRING',
  PROXYBLOCK = 'PROXYBLOCK',
  SMB_TOS_BLOCK = 'SMB_TOS_BLOCK',
  TIMEOUT = 'TIMEOUT',
  TOS_BLOCK = 'TOS_BLOCK',
  UNLAUNCHED = 'UNLAUNCHED',
  UNPAIRED = 'UNPAIRED',
  UNPAIRED_IDLE = 'UNPAIRED_IDLE'
}

export enum StatusMensagem {
  MD_DOWNGRADE = -7,
  INACTIVE = -6,
  CONTENT_UNUPLOADABLE = -5,
  CONTENT_TOO_BIG = -4,
  CONTENT_GONE = -3,
  EXPIRED = -2,
  FAILED = -1,
  CLOCK = 0,
  SENT = 1,
  RECEIVED = 2,
  READ = 3,
  PLAYED = 4
}

export enum StatusMensagemAgendada {
  AGENDADA = 1,
  ENVIADA = 2,
  CANCELADA = 3,
  ERRO_NO_ENVIO = 4,
  EXPIRADA = 5
}

export interface ApiWhatsAppResponseInterface {
  ok: boolean
  status: StatusSessao
  mensagem: string
}

export interface ApiWhatsAppStatusInterface {
  ok: boolean
  status: StatusSessao
  mensagem: string
  tempoMinStatus: number
  urlCode: string
}

export interface ApiWhatsAppRespostaPadraoInterface {
  ok: boolean
  mensagem: string
}
