import React from 'react'

import './LogoBottom.css'

export default function LogoBottom () {

  return (
    <>
      <img alt='Logomarca Vamos Sorrir'
        className="logoBottom"
        width='100%'
        src={'/imagens/home/logo.png'}
      >
      </img>
    </>
  )

}