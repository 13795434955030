import React, { useContext, useEffect, useState } from 'react'

import { Avatar, Grid, Typography, useTheme } from '@mui/material'

import axios from 'axios'
import { ENDPOINT } from '../../../ImportBackend/Config/emDesenvolvimento'
import { ContextoGlobal, ContextoGlobalInterface } from '../../../GlobalStates/ContextoGlobal'

export default function DescritivoDentista ( { nome, idDentista, cro }: { nome: string, idDentista: number, cro: string } ) {

  const theme = useTheme()

  const [conteudo, setConteudo] = useState<string>( '' )

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface

  const carregarArquivo = ( url: string ) => {

    console.log( url )

    axios.get( url ).then( ( rs ) => {
      setConteudo( 'data:image/jpeg;base64,'.concat( rs.data ) )
    } )

  }

  useEffect( () => {
    carregarArquivo( ENDPOINT.concat( 'downloadFotoDentista/' ).concat( contexto.loginState.token ).concat( '/' ).concat( idDentista.toString() ) )
  }, [idDentista] )

  return (
    <>
      <Grid container sx={{ mt: 0, pt: 0, pb: 0, mb: 0 }}>
        <Grid item xs={2} sx={{ mt: 0, pt: 0, pb: 0, mb: 0 }} >
          <Avatar
            sx={{ width: 36, height: 36 }}
            variant='rounded'
            alt={nome}
            src={conteudo} />
        </Grid>
        <Grid item xs={10} sx={{ mt: 0, pt: 0, pb: 0, mb: 0 }} >
          <Typography 
            sx={{ fontStyle: 'italic' }}
            component="p"
            variant="button"
            color={theme.cores.cinzaTexto}
          >
            {nome}
          </Typography>
          <Typography
            sx={{ fontStyle: 'italic' }}
            component="p"
            variant="caption"
            color={theme.cores.cinzaTexto}
          >
            CRO {cro}
          </Typography>
        </Grid>
      </Grid>
    </>
  )

}