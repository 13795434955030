import React, { useContext } from 'react'
import { ContextoGlobal, ContextoGlobalInterface } from '../../../GlobalStates/ContextoGlobal'
import BackEndAPI from '../../../Services/BackEndAPI'
import { Grid, Typography } from '@mui/material'
import { MensagemTipo } from '../../../GlobalStates/MensagemState'
import Condicional from '../../../Layout/Condicional'
import ComboBox from '../../../DevComponents/ComboBox'
import { AgendamentoClienteCRMInputInterface } from '../../../ImportBackend/Interfaces/AgendamentoClienteInterfaces'
import { StatusForm } from './ClienteAgendamentos'
import { EspecialidadesEcuroTypes } from '../../../ImportBackend/types/ConstantesDataTypes'
import InputText from '../../../DevComponents/InputText'
import { rsDisponibilidadeDentistaInterface } from '../../../ImportBackend/Interfaces/AgendamentoInterfaces'
import ClsValidacao from '../../../Utils/ClsValidacao'
import { DateTime } from 'luxon'
import { DTFORMAT } from '../../../Utils/ClsFormatacao'

interface PropsInterface {
  dados: AgendamentoClienteCRMInputInterface
  setDados: React.Dispatch<React.SetStateAction<AgendamentoClienteCRMInputInterface>>
  erros: Record<string, string>
  statusForm: StatusForm
  setRsDentistasDisponiveis: React.Dispatch<React.SetStateAction<Array<rsDisponibilidadeDentistaInterface>>>
  rsDentistasDisponiveis: Array<rsDisponibilidadeDentistaInterface>
  idClinica: number
}

export default function ClienteAgendamentoForm ( {
  dados,
  setDados,
  erros,
  statusForm,
  rsDentistasDisponiveis,
  setRsDentistasDisponiveis,
  idClinica
}: PropsInterface ) {

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface

  const { setMensagemState } = ( useContext( ContextoGlobal ) as ContextoGlobalInterface )

  const abortController: AbortController = new AbortController()

  const clsApi = new BackEndAPI()

  const pesquisarDentistas = ( data: string, idEcuroEspecialidade: string ) => {

    const query = `
        getHorariosDisponiveisPorTipoConsulta(idClinica: ${idClinica}, data: "${data}", idEcuroEspecialidade: "${idEcuroEspecialidade}") {
          idDentista
          nome
          cro
          horarios
          data
        }
      `

    clsApi.query<Array<rsDisponibilidadeDentistaInterface>>( query, 'getHorariosDisponiveisPorTipoConsulta', 'Procurando Dentistas...', contexto, abortController ).then( rsDisponibilidade => {

      if ( rsDisponibilidade.length > 0 && rsDisponibilidade[0].data === data ) {

        /*
        if ( data === dadosEdicaoAgendamento.data ) {
          rsDisponibilidade[0].horarios.push( dadosEdicaoAgendamento.horario )
          rsDisponibilidade[0].horarios = rsDisponibilidade[0].horarios.sort()
        }
        */

        setRsDentistasDisponiveis( rsDisponibilidade )

      } else {

        setMensagemState( {
          cb: null,
          exibir: true,
          exibirBotao: true,
          tipo: MensagemTipo.Error,
          mensagem: 'Não há disponibilidade nesta data',
          titulo: "Indisponível!"
        } )

        setRsDentistasDisponiveis( [] )

      }

    } )

  }

  const onChangeDataOuTipoConsulta = ( data: string, idEcuroEspecialidade: string ) => {

    const clsValidacao: ClsValidacao = new ClsValidacao()
    const tmpDados: Partial<AgendamentoClienteCRMInputInterface> = { data: data, idEcuroEspecialidade: idEcuroEspecialidade, idDentista: 0, horario: '' }

    let retorno: boolean = clsValidacao.naoVazio( 'idEcuroEspecialidade', tmpDados, {}, true )
    retorno = clsValidacao.eData( 'data', tmpDados, {}, retorno )

    if ( retorno && ( tmpDados.data as string ) >= DateTime.now().toFormat( DTFORMAT.BANCO ) ) {
      pesquisarDentistas( data, idEcuroEspecialidade )
    }

    setDados( { ...dados, ...tmpDados } )

  }

  const getHorariosDisponiveisDentistaSelecionado = (): Array<Record<string, string>> => {

    let retorno: Array<Record<string, string>> = []

    if ( dados.idDentista > 0 ) {

      const indiceDentista: number = rsDentistasDisponiveis.findIndex( v => v.idDentista === dados.idDentista )

      if ( indiceDentista >= 0 ) {
        retorno = rsDentistasDisponiveis[indiceDentista].horarios.map( v => { return { hora: v } } )
      }

    }

    return retorno
  }

  return (
    <>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'left', mt: 3 }}>
        <Typography component="h5" variant="h5" align="left">
          Novo Agendamento
          <Condicional condicao={statusForm === StatusForm.Editando}>
            <Typography variant="body2" gutterBottom>
              Remarcar Agendamento para:
            </Typography>
          </Condicional>
        </Typography>
      </Grid>

      <Grid item xs={12} md={6}>

        <ComboBox
          dados={dados}
          opcoes={EspecialidadesEcuroTypes}
          field='idEcuroEspecialidade'
          label='Especialidade'
          erros={erros}
          campoDescricao='descricao'
          campoID='specialityId'
          onChange={( rs: typeof EspecialidadesEcuroTypes[0] ) => onChangeDataOuTipoConsulta( dados.data, rs && rs.specialityId ? rs.specialityId : '' )}
        />

      </Grid>

      <Grid item xs={12} md={6} sx={{ pl: { md: 1 } }}>

        <InputText
          dados={dados}
          field='data'
          label='Data'
          type='date'
          setState={setDados}
          erros={erros}
          onChange={( rs: string ) => onChangeDataOuTipoConsulta( rs, dados.idEcuroEspecialidade )}
        />

      </Grid>

      <Grid item xs={12} md={9}>

        <ComboBox
          campoDescricao='nome'
          campoID='idDentista'
          dados={dados}
          field='idDentista'
          label='Dentista'
          erros={erros}
          opcoes={rsDentistasDisponiveis}
          setState={setDados}
        />

      </Grid>

      <Grid item xs={12} md={3} sx={{ pl: { md: 1 } }}>

        <ComboBox
          campoDescricao='hora'
          erros={erros}
          campoID='hora'
          dados={dados}
          field='horario'
          label='Horário'
          valorPadraoCampoEmBranco={""}
          opcoes={getHorariosDisponiveisDentistaSelecionado()}
          setState={setDados}
        />

      </Grid>

    </>
  )

}