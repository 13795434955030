import React, { useState } from 'react'
import InputText from '../DevComponents/InputText'

export default function Teste () {

  const [dados, setDados] = useState( { valor: -0.35 } )

  return (
    <>
      <InputText
        dados={dados}
        field='valor'
        label='Valor'
        setState={setDados}
        tipo='currency'
        scale={18}
      />
      {JSON.stringify( dados )}
    </>
  )

}