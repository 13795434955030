import React, { useContext, useEffect, useState } from 'react'
import { ContextoGlobal, ContextoGlobalInterface } from '../../GlobalStates/ContextoGlobal'
import BackEndAPI from '../../Services/BackEndAPI'
import { Button, Container, Grid, IconButton, Paper, Typography } from '@mui/material'
import InputText from '../../DevComponents/InputText'
import { EMDESENVOLVIMENTO } from '../../ImportBackend/Config/emDesenvolvimento'
import { useNavigate } from 'react-router-dom'

import CloseIcon from '@mui/icons-material/Close'
import Condicional from '../../Layout/Condicional'
import { ApiWhatsAppResponseInterface, ApiWhatsAppStatusInterface, StatusSessao } from '../../ImportApiWhatsAPP/tipos/dataTypes'
import ExibirJSONDev from '../../DevComponents/ExibirJSONDev'
import QrCodeView from './QrCodeView'

export default function SetupWhatsAPP () {

  const [dados, setDados] = useState<ApiWhatsAppStatusInterface>( {
    ok: false,
    status: StatusSessao.cadastroInicial,
    mensagem: '',
    tempoMinStatus: 0,
    urlCode: ''
  } )

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface

  const navigate = useNavigate()

  const clsApi = new BackEndAPI()

  const getStatus = () => {

    const query: string = `
      statusApiWhatsAPP {
        ok
        status
        mensagem
        tempoMinStatus
        urlCode
      }
    `

    clsApi.mutation<ApiWhatsAppStatusInterface>( query, 'statusApiWhatsAPP', 'Recebendo Status', contexto ).then( rs => {

      setDados( rs )

    } )

  }

  useEffect( () => {

    getStatus()

    const timeoutId = setInterval( () => {
      getStatus()
    }, 19000 );

    return () => clearInterval( timeoutId )

    //eslint-disable-next-line
  }, [] )


  const iniciarWhatsAPP = () => {
    const query: string = `
      iniciarWhatsAPP {
        ok
        status
        mensagem
      }
    `

    clsApi.mutation<ApiWhatsAppResponseInterface>( query, 'iniciarWhatsAPP', 'Iniciando...', contexto ).then( rs => {

      setDados( { ...rs, tempoMinStatus: 0, urlCode: '' } )
      getStatus()

    } )
  }

  const finalizarWhatsAPP = () => {
    const query: string = `
      finalizarInstancia {
        ok
        status
        mensagem
      }
    `

    clsApi.mutation<ApiWhatsAppResponseInterface>( query, 'finalizarInstancia', 'Finalizando...', contexto ).then( rs => {

      setDados( { ...rs, tempoMinStatus: 0, urlCode: '' } )
      getStatus()

    } )
  }

  return (
    <>

      <Container maxWidth="md" sx={{ mt: 5 }}>

        <Paper variant="outlined" sx={{ padding: 2 }}>
          <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography component="h5" variant="h5" align="left">
                WhatsAPP
                <Typography variant="body2" gutterBottom>
                  Setup para Mensagens de WhatsAPP
                </Typography>
              </Typography>

              <IconButton onClick={() => navigate( '/' )}>
                <CloseIcon />
              </IconButton>
            </Grid>

            <Grid item xs={12}>
              <InputText
                dados={dados}
                field='mensagem'
                label='Mensagem da API'
                disabled
              />
            </Grid>

            <Grid item xs={12}>
              <InputText
                dados={dados}
                field='status'
                label='Status da API'
                disabled
              />
            </Grid>

            <Grid item xs={12}>
              <Button onClick={() => getStatus()}>Status</Button>
            </Grid>

            <Condicional condicao={![StatusSessao.iniciando, StatusSessao.conectada, StatusSessao.erro].includes( dados.status )}>
              <Grid item xs={12}>
                <Button onClick={() => iniciarWhatsAPP()}>Conectar</Button>
              </Grid>
            </Condicional>

            <Condicional condicao={dados.status === StatusSessao.conectada || ( dados.status === StatusSessao.iniciando && dados.tempoMinStatus >= 3 )}>
              <Grid item xs={12}>
                <Button onClick={() => finalizarWhatsAPP()}>Desconectar</Button>
              </Grid>
            </Condicional>

            <Condicional condicao={dados.status === StatusSessao.iniciando}>
              <QrCodeView status={dados.status} urlCode={dados.urlCode} />
            </Condicional>

          </Grid>
        </Paper>
      </Container>

      <ExibirJSONDev
        exibir={EMDESENVOLVIMENTO}
        oque={['Dados: ', dados]}
      />

    </>
  )

}