import React, { useContext, useEffect, useRef, useState } from 'react'
import LogoTop from '../../../Layout/LogoTop'
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
  useTheme
} from '@mui/material'
import { useNavigate } from 'react-router-dom';
import ComboBox from '../../../DevComponents/ComboBox';
import CabecalhoTela from '../Componentes/CabecalhoTela';
import BackEndAPI from '../../../Services/BackEndAPI';
import { ContextoGlobal, ContextoGlobalInterface } from '../../../GlobalStates/ContextoGlobal';
import { MensagemTipo } from '../../../GlobalStates/MensagemState';
import { ClinicasDisponiveisAgendamentoInterface, rsDisponibilidadeDentistaInterface } from '../../../ImportBackend/Interfaces/AgendamentoInterfaces';
import InputText from '../../../DevComponents/InputText';
import { DateTime } from 'luxon';
import { DTFORMAT } from '../../../Utils/ClsFormatacao';
import ExibirJSONDev from '../../../DevComponents/ExibirJSONDev';
import DescritivoDentista from './DescritivoDentista';
import ClsValidacao from '../../../Utils/ClsValidacao';
import Condicional from '../../../Layout/Condicional';
import ClsUtils from 'zlib-utils';
import { RespostaPadraoInterface } from '../../../ImportBackend/Interfaces/PadraoInterfaces';
import { AgendamentoPacienteInputInterface } from '../../../ImportBackend/Interfaces/AgendamentoPacienteInterfaces';

interface idDescricaoInterface {
  id: string
  descricao: string
}

export default function AgendarManutencao () {

  const abortController: AbortController = new AbortController()
  const pesquisandoDentistas = useRef( false )
  const pesquisaDentistasRealizada = useRef( false )

  const clsApi = new BackEndAPI()

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface

  const [rsClinicasDisponiveis, setRsClinicasDisponiveis] = useState<Array<ClinicasDisponiveisAgendamentoInterface>>( [] )

  const [rsHorario, setRsHorario] = useState<Array<idDescricaoInterface>>( [] )

  const [rsDentistasDisponiveis, setRsDentistasDisponiveis] = useState<Array<rsDisponibilidadeDentistaInterface>>( [] )

  const [dados, setDados] = useState<AgendamentoPacienteInputInterface>( {
    data: '',
    horario: '',
    idClinica: 0,
    idDentista: 0,
    idEcuroEspecialidade: ''
  } )

  const [erros, setErros] = useState<Record<string, string>>( {} )

  const theme = useTheme()

  const nav = useNavigate()

  const btOpcao = ( caminho: string ) => {
    nav( caminho )
  }

  const consultaClinicaDisponivelPaciente = () => {
    const query = `
      clinicasDisponiveisPaciente {
        idClinica
        descricao
      }
    `

    clsApi.query<Array<ClinicasDisponiveisAgendamentoInterface>>( query, 'clinicasDisponiveisPaciente', 'Encontrando Clínica...', contexto ).then( rsClinicasDisponiveis => {

      if ( rsClinicasDisponiveis.length === 0 ) {

        contexto.setMensagemState( {
          cb: null,
          exibir: true,
          exibirBotao: true,
          mensagem: 'Não Há Clínicas Disponíveis.',
          tipo: MensagemTipo.Error,
          titulo: 'Consulte a Vamos Sorrir!'
        } )

      } else if ( rsClinicasDisponiveis.length === 1 ) {

        setDados( {
          data: '',
          horario: '',
          idClinica: rsClinicasDisponiveis[0].idClinica,
          idDentista: 0,
          idEcuroEspecialidade: ''
        } )

      }

      setRsClinicasDisponiveis( rsClinicasDisponiveis )

    } )

  }

  useEffect( () => {

    consultaClinicaDisponivelPaciente()

    // eslint-disable-next-line
  }, [] )

  const alterarClinica = ( rs: ClinicasDisponiveisAgendamentoInterface ) => {

    if ( pesquisandoDentistas.current ) {
      abortController.abort()
      pesquisandoDentistas.current = false
    }

    setDados( { ...dados, idClinica: rs.idClinica, idDentista: 0, horario: '' } )
    pesquisaDentistasRealizada.current = false

  }

  const alterarData = ( rs: string ) => {

    if ( pesquisandoDentistas.current ) {
      abortController.abort()
      pesquisandoDentistas.current = false
    }

    setDados( { ...dados, data: rs, idDentista: 0, horario: '' } )
    pesquisaDentistasRealizada.current = false

  }

  const validarPesquisa = () => {
    return dados.idClinica !== 0 && DateTime.fromFormat( dados.data, DTFORMAT.BANCO ).isValid && !pesquisaDentistasRealizada.current
  }

  const pesquisarDentistas = () => {

    if ( !pesquisandoDentistas.current && validarPesquisa() ) {

      const query = `
        getHorariosDisponiveisPorTipoConsulta(idClinica: ${dados.idClinica}, data: "${dados.data}", idEcuroEspecialidade: "${dados.idEcuroEspecialidade}") {
          idDentista
          nome
          cro
          horarios
          data
        }
      `

      pesquisandoDentistas.current = true

      clsApi.query<Array<rsDisponibilidadeDentistaInterface>>( query, 'getHorariosDisponiveisPorTipoConsulta', 'Procurando Dentistas...', contexto, abortController ).then( rsDentistasDisponiveis => {

        pesquisandoDentistas.current = false
        pesquisaDentistasRealizada.current = true

        setRsDentistasDisponiveis( rsDentistasDisponiveis )

      } )

    }

  }

  const alterarDentista = ( idDentista: number ) => {

    const indice: number = rsDentistasDisponiveis.findIndex( v => v.idDentista === idDentista )

    let tmpHorario: Array<idDescricaoInterface> = []

    setDados( { ...dados, idDentista: idDentista } )

    if ( indice >= 0 ) {

      rsDentistasDisponiveis[indice].horarios.forEach( hr => {

        tmpHorario.push( {
          descricao: hr,
          id: hr
        } )

      } )

    }

    setRsHorario( tmpHorario )

  }

  const validarDados = (): boolean => {

    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.eHorario( 'horario', dados, erros, retorno )
    retorno = clsValidacao.eData( 'data', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'idClinica', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'idDentista', dados, erros, retorno )

    setErros( erros )

    return retorno

  }

  const btConfirmarAgendamento = () => {

    if ( validarDados() ) {

      const mutation = `
        agendarHorarioPaciente (dados: ${( new ClsUtils() ).ConverterEmGql( dados )}) {
          ok
          mensagem
        }
      `

      clsApi.mutation<RespostaPadraoInterface>( mutation, 'agendarHorarioPaciente', 'Agendando Consulta...', contexto ).then( rsAgendarHorario => {

        if ( rsAgendarHorario.ok ) {
          btOpcao( '/ConsultarConsultas' )
        } else {

          contexto.setMensagemState( {
            cb: null,
            exibir: true,
            exibirBotao: true,
            mensagem: 'Erro ao Agendar Consulta!',
            tipo: MensagemTipo.Error,
            titulo: 'Tente Novamente. Se persistir, consulte a Vamos Sorrir!'
          } )

        }

      } )

    }

  }

  return (
    <>

      <div className='imagemFundoBranco'>

        <LogoTop />

        <Grid
          container
          minHeight='100VH'
        >

          <Grid item xs={12} md={5} sx={{ margin: 'auto' }}>

            <Grid container>

              <Grid item xs={12} mb={5}>

                <CabecalhoTela titulo={'Consulte as datas e horários <br /> disponíveis do seu dentista.'} />

              </Grid>

              <Grid item xs={12} sx={{ padding: 1 }}>

                <Paper sx={{ backgroundColor: theme.cores.cinzaFundo, padding: 2, mb: 2 }}>

                  <Grid container>

                    <Grid item xs={12} mt={2}>
                      <ComboBox
                        campoDescricao='descricao'
                        campoID='idClinica'
                        opcoes={rsClinicasDisponiveis}
                        dados={dados}
                        field='idClinica'
                        label='Clínica:'
                        mensagemPadraoCampoEmBranco='Escolha a Clínica'
                        erros={erros}
                        onChange={( rs: ClinicasDisponiveisAgendamentoInterface ) => alterarClinica( rs )}
                      />
                    </Grid>

                    <Grid item xs={12} sx={{ pr: 1 }}>
                      <InputText
                        dados={dados}
                        field='data'
                        label='Data'
                        erros={erros}
                        type='date'
                        onChange={( rs: string ) => alterarData( rs )}
                      />
                    </Grid>

                    <Grid item xs={12} mt={2}>

                      <FormControl fullWidth size='small' >

                        <InputLabel id='select-dentista'>Selecione o seu Dentista</InputLabel>
                        <Select
                          id='select-dentista'
                          fullWidth
                          label="Selecione o seu Dentista"
                          value={dados.idDentista ? dados.idDentista : ''}
                          onChange={( event ) => alterarDentista( event.target.value as number )}
                          onFocus={() => pesquisarDentistas()}
                          onClick={() => pesquisarDentistas()}
                        >

                          {rsDentistasDisponiveis.map( rsDentista =>
                            <MenuItem value={rsDentista.idDentista} key={rsDentista.idDentista}>
                              <DescritivoDentista
                                nome={rsDentista.nome}
                                idDentista={rsDentista.idDentista}
                                cro={rsDentista.cro}
                              />
                            </MenuItem>
                          )}
                        </Select>
                        <Condicional condicao={erros.idDentista !== 'undefined'}>
                          <Typography variant='caption' textAlign='left' color='warning.main' >{erros.idDentista}</Typography>
                        </Condicional>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      <ComboBox
                        campoDescricao='descricao'
                        campoID='id'
                        opcoes={rsHorario}
                        dados={dados}
                        field='horario'
                        label='Horário:'
                        setState={setDados}
                        mensagemPadraoCampoEmBranco='Escolha o Horário'
                        erros={erros}
                      />
                    </Grid>

                  </Grid>

                  <Grid item xs={12} px={3} my={4}>
                    <Button
                      fullWidth
                      variant='contained'
                      onClick={() => btConfirmarAgendamento()}
                      sx={{ borderRadius: 2, backgroundColor: theme.palette.primary.main }}>
                      Confirmar Agendamento
                    </Button>
                  </Grid>

                </Paper>

              </Grid>

              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Button
                  onClick={() => btOpcao( '/PacienteMenu' )}
                  sx={{
                    color: theme.palette.secondary.main,
                    fontWeight: 'bold',
                    mb: 2
                  }}
                  variant='text'>Voltar
                </Button>
              </Grid>

              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <img alt='Vamos Sorrir Publicidade'
                  src="/imagens/paciente/bannermenupaciente.png"
                  className='imgResponsive'
                />
              </Grid>

            </Grid>

          </Grid>

        </Grid>

        <ExibirJSONDev oque={[
          'Token',
          contexto.loginState.token,
          'Dados',
          dados,
          'Erros',
          erros
        ]}></ExibirJSONDev>

      </div>

    </>
  )

}