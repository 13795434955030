import React, { useState, useContext, useEffect } from 'react'
import { PacienteInterface, getPacienteClinicaInterface } from '../../ImportBackend/Interfaces/PacienteInterfaces'
import { StatusForm } from './Pacientes'
import BackEndAPI from '../../Services/BackEndAPI'
import DataTable, { DataTableCabecalhoInterface } from '../../DevComponents/DataTable'
import { Container, Grid, Paper } from '@mui/material'
import PesquisarTabela from '../../DevComponents/PesquisarTabela'
import ExibirJSONDev from '../../DevComponents/ExibirJSONDev'
import { ContextoGlobal, ContextoGlobalInterface } from '../../GlobalStates/ContextoGlobal'
import { MensagemTipo } from '../../GlobalStates/MensagemState'
import Condicional from '../../Layout/Condicional'
import { RespostaPadraoInterface } from '../../ImportBackend/Interfaces/PadraoInterfaces'

interface PropsInterface {
  statusForm: StatusForm
  rsPaciente: PacienteInterface
}

export default function PacientesClinicas ( { statusForm, rsPaciente }: PropsInterface ) {

  const clsApi: BackEndAPI = new BackEndAPI()

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface

  const [pesquisa, setPesquisa] = useState( { descricao: '', idClinica: 0 } )

  const [dados, setDados] = useState<Array<getPacienteClinicaInterface>>( [] )

  const Cabecalho: Array<DataTableCabecalhoInterface> = [
    {
      campo: 'clinica',
      cabecalho: 'Clínica',
      alinhamento: 'left'
    }
  ]

  const onChangeClinica = ( rs: { idClinica: number, descricao: string } ) => {

    if ( rs && rs.idClinica > 0 ) {

      const cadastrado = dados.findIndex( v => v.idClinica === rs.idClinica )

      if ( cadastrado < 0 ) {

        const mutation: string = `
          incluirPacienteClinica(idPaciente: ${rsPaciente.idPaciente}, idClinica: ${rs.idClinica}) {
            ok
            mensagem
          }
        `

        clsApi.mutation<RespostaPadraoInterface>( mutation, 'incluirPacienteClinica', 'Pesquisando Paciente...', contexto ).then( rs => {

          if ( rs.ok ) {

            loadRsPacientesClinicas()

          } else {

            contexto.setMensagemState( {
              exibir: true,
              mensagem: 'Paciente Não Cadastrado Nesta Clínica!',
              tipo: MensagemTipo.Info,
              exibirBotao: true,
              cb: null,
              titulo: 'Inclusão de Pacientes na Clínica'
            } )

          }

        } )

      }

    }

    setPesquisa( { idClinica: 0, descricao: '' } )

  }


  const btExcluirPacienteClinica = ( rs: getPacienteClinicaInterface, indice: number ) => {

    const mutation: string = `
      delPacienteClinica(idPaciente: ${rsPaciente.idPaciente}, idClinica: ${rs.idClinica}) {
        ok
        mensagem
      }
    `

    clsApi.mutation<RespostaPadraoInterface>( mutation, 'delPacienteClinica', 'Excluindo Clínica...', contexto ).then( () => {

      loadRsPacientesClinicas()

    } )

  }

  const loadRsPacientesClinicas = () => {

    const query: string = `
      getPacientesClinica(idPaciente: ${rsPaciente.idPaciente}) {
        idPaciente
        idClinica
        idEcuroPaciente
        idEcuroClinica
        ativo
        clinica
      }
    `

    clsApi.query<Array<getPacienteClinicaInterface>>( query, 'getPacientesClinica', 'Pesquisando Clínicas...', contexto ).then( rs => {

      setDados( rs )

    } )

  }

  useEffect( () => {
    if ( rsPaciente && rsPaciente.idPaciente && rsPaciente.idPaciente > 0 ) {
      loadRsPacientesClinicas()
    } else {
      setDados( [] )
    }
    //eslint-disable-next-line
  }, [rsPaciente.idPaciente] )

  return (
    <>

      <Condicional condicao={statusForm === StatusForm.Editando || statusForm === StatusForm.Excluindo || statusForm === StatusForm.EditandoClinicas}>
        <Container maxWidth="md" sx={{ mt: 5 }}>

          <Paper variant="outlined" sx={{ padding: 2 }}>
            <Grid item xs={12}>

              <PesquisarTabela<any>
                onChange={( rs ) => onChangeClinica( rs )}
                field='idClinica'
                fieldSet='idClinica'
                label='Clínica'
                dados={pesquisa}
                campoQueryPesquisaID='idClinica'
                campoQueryPesquisa='pesquisa'
                camposRetornoQueryPesquisa='{idClinica, descricao, idEcuroClinica}'
                campoLabelQueryPesquisa='descricao'
                nomeQueryPesquisa='getClinicas'
                nomeQueryPesquisaID='getClinicaPorId'
                mensagemPesquisa='Procurando Clínicas...'
                disabled={statusForm === StatusForm.Excluindo || rsPaciente.nome.length === 0}
              />

            </Grid>

            <Grid item xs={12} sx={{ mt: 3 }}>
              <DataTable dados={dados} cabecalho={Cabecalho}
                acoes={[{ icone: 'delete', toolTip: 'Exluir', onAcionador: btExcluirPacienteClinica }]}
              />
            </Grid>

            <ExibirJSONDev oque={['Dados Pacientes Clínicas: ', dados]} />

            <ExibirJSONDev oque={['Pesquisa Pacientes Clínicas: ', pesquisa]} />

          </Paper>
        </Container>
      </Condicional>
    </>
  )

}
