import React, { useContext, useEffect, useState } from 'react'
import LogoTop from '../../../Layout/LogoTop'
import { Button, Grid, Typography, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom';

import './Paciente.css'
import CabecalhoTela from '../Componentes/CabecalhoTela';
import ComboBox from '../../../DevComponents/ComboBox';
import { ClinicasDisponiveisAgendamentoInterface } from '../../../ImportBackend/Interfaces/AgendamentoInterfaces';
import BackEndAPI from '../../../Services/BackEndAPI';
import { ContextoGlobal, ContextoGlobalInterface } from '../../../GlobalStates/ContextoGlobal';
import { MensagemTipo } from '../../../GlobalStates/MensagemState';
import ClsFormatacao from '../../../Utils/ClsFormatacao';
import ExibirJSONDev from '../../../DevComponents/ExibirJSONDev';

export default function MarcarOutrasEspecialidades () {

  const [rsClinicasDisponiveis, setRsClinicasDisponiveis] = useState<Array<ClinicasDisponiveisAgendamentoInterface>>( [] )
  const [rsWhatsAPP, setRsWhatsAPP] = useState( '' )

  const clsApi = new BackEndAPI()

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface

  const [dados, setDados] = useState( {
    idClinica: 0,
  } )

  const clsFormatacao: ClsFormatacao = new ClsFormatacao()

  const theme = useTheme()

  const nav = useNavigate()

  const go = ( local: string ) => {
    window.location.href = local
  }

  const btOpcao = ( caminho: string ) => {
    nav( caminho )
  }

  const consultaClinicaDisponivelPaciente = () => {
    const query = `
      clinicasDisponiveisPaciente {
        idClinica
        descricao
        whatsAPP
      }
    `

    clsApi.query<Array<ClinicasDisponiveisAgendamentoInterface>>( query, 'clinicasDisponiveisPaciente', 'Encontrando Clínica...', contexto ).then( rsClinicasDisponiveis => {

      if ( rsClinicasDisponiveis.length === 0 ) {

        contexto.setMensagemState( {
          cb: null,
          exibir: true,
          exibirBotao: true,
          mensagem: 'Não Há Clínicas Disponíveis.',
          tipo: MensagemTipo.Error,
          titulo: 'Consulte a Vamos Sorrir!'
        } )

      } else if ( rsClinicasDisponiveis.length === 1 ) {

        alterarClinica( rsClinicasDisponiveis[0] )

      }

      setRsClinicasDisponiveis( rsClinicasDisponiveis )

    } )

  }

  useEffect( () => {

    consultaClinicaDisponivelPaciente()

    // eslint-disable-next-line
  }, [] )

  const alterarClinica = ( rs: ClinicasDisponiveisAgendamentoInterface ) => {

    // 'https://wa.me/5537988441821'

    setRsWhatsAPP( 'https://wa.me/55'.concat( clsFormatacao.somenteNumeros( rs.whatsAPP ) ) )
    setDados( { idClinica: rs.idClinica } )

  }

  return (
    <>
      <div className='imagemFundoBranco'>

        <LogoTop />

        <Grid
          container
          minHeight='100VH'
        >

          <Grid item xs={12} md={5} sx={{ margin: 'auto' }}>

            <Grid container>

              <Grid item xs={12} mb={5}>

                <CabecalhoTela titulo={'Converse conosco sobre outras especialidades'} />

              </Grid>

              <Grid item xs={12} mt={2}>
                <ComboBox
                  campoDescricao='descricao'
                  campoID='idClinica'
                  opcoes={rsClinicasDisponiveis}
                  dados={dados}
                  field='idClinica'
                  label='Clínica:'
                  mensagemPadraoCampoEmBranco='Escolha a Clínica'
                  onChange={( rs: ClinicasDisponiveisAgendamentoInterface ) => alterarClinica( rs )}
                />
              </Grid>

              <Grid item
                xs={12}
                sx={{ textAlign: 'center', mt: 5 }}
                onClick={() => go( rsWhatsAPP )}
              >

                <img alt='Logo Whats APP'
                  className='imgLogoWhatsAPP'
                  src={'/imagens/icones/whatsapp.png'}>
                </img>

              </Grid>

              <Grid item
                xs={12}
                sx={{ textAlign: 'center', mt: 1 }}
                onClick={() => go( rsWhatsAPP )}
              >
                <Typography
                  variant='body1'
                  fontSize={18}
                  fontWeight='bold'
                  color='primary'>
                  Clique Aqui e Agende.
                </Typography>

              </Grid>

              <Grid item xs={12} sx={{ textAlign: 'center', my: 5 }}>
                <Button
                  onClick={() => btOpcao( '/PacienteMenu' )}
                  sx={{
                    color: theme.palette.secondary.main,
                    fontWeight: 'bold',
                    mb: 2
                  }}
                  variant='text'>Voltar
                </Button>
              </Grid>

              <img alt='Vamos Sorrir Publicidade' src="/imagens/paciente/bannermenupaciente.png" width={'100%'} />

            </Grid>

          </Grid>

        </Grid>

        <ExibirJSONDev oque={[
          'WhatsAPP',
          rsWhatsAPP,
          'rsClinicasDisponiveis',
          rsClinicasDisponiveis
        ]}></ExibirJSONDev>

      </div >
    </>
  )

}