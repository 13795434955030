import React, { useContext, useState } from 'react'
import { ContextoGlobal, ContextoGlobalInterface } from '../../../GlobalStates/ContextoGlobal'
import BackEndAPI from '../../../Services/BackEndAPI'
import { Box, Button, Grid, Paper, Typography } from '@mui/material'
import InputText from '../../../DevComponents/InputText'
import { MensagemTipo } from '../../../GlobalStates/MensagemState'
import { useNavigate, useParams } from 'react-router-dom'
import { RespostaPadraoInterface } from '../../../ImportBackend/Interfaces/PadraoInterfaces'
import LogoTop from '../../../Layout/LogoTop'
import CabecalhoTela from '../Componentes/CabecalhoTela'

interface NovaSenhaInterface {
  novaSenha: string
  confirmarNovaSenha: string
}

export default function PacienteAlterarSenhaLink () {

  const nav = useNavigate()

  const params = useParams()

  const ResetDados: NovaSenhaInterface = {
    novaSenha: '',
    confirmarNovaSenha: ''
  }

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface

  const { mensagemState, setMensagemState } = ( useContext( ContextoGlobal ) as ContextoGlobalInterface )

  const [dados, setDados] = useState<NovaSenhaInterface>( ResetDados )
  const [erros, setErros] = useState( {} )

  const clsApi = new BackEndAPI()

  const validarDados = (): boolean => {

    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    if ( dados.novaSenha.length < 6 || dados.novaSenha.length > 15 ) {
      erros.novaSenha = 'Senha deve estar entre 6 e 15 caracteres'
      retorno = false
    }

    if ( dados.confirmarNovaSenha !== dados.novaSenha ) {
      erros.confirmarNovaSenha = 'Senha e Nova Senha devem ser iguais'
      retorno = false
    }

    setErros( erros )

    return retorno

  }

  const confirmarAlteracaoSenha = () => {

    if ( validarDados() ) {

      const mutation: string = `
        pacienteResetSenha(token: "${params.token}", senha: "${dados.novaSenha}") {
          ok
          mensagem
        }
      `

      clsApi.mutation<RespostaPadraoInterface>( mutation, 'pacienteResetSenha', 'Alterando a Senha...', contexto ).then( rs => {

        if ( rs.ok ) {

          setMensagemState( {
            ...mensagemState,
            exibir: true,
            titulo: 'Senha Alterada!',
            mensagem: 'Você deve fazer seu Login com a Nova Senha!',
            tipo: MensagemTipo.Info,
            exibirBotao: true
          } )

          nav( '/' )

        } else {
          setMensagemState( {
            ...mensagemState,
            exibir: true,
            titulo: 'Erro ao Alterar Senha!',
            mensagem: 'Tente novamente!',
            tipo: MensagemTipo.Error,
            exibirBotao: true
          } )
        }

        // ( new ClsLogout() ).efetuarLogout( contexto.setLoginState, contexto.setLayoutState, navigate )

      } ).catch( () => {

        setMensagemState( {
          ...mensagemState,
          exibir: true,
          titulo: 'Erro!!!',
          mensagem: 'Alteração de Senha Não Realizada! Consulte Suporte!!!',
          tipo: MensagemTipo.Error,
          exibirBotao: true
        } )

      } )

    }

  }

  return (
    <>

      <div className='imagemFundoBranco'>
        <LogoTop />

        <Grid
          container
          minHeight='100VH'
        >

          <Grid item xs={10} sm={8} md={4} sx={{ margin: 'auto' }}>

            <Grid container>

              <Grid item xs={12} mb={5}>

                <CabecalhoTela titulo={'Altere sua Senha!'} />

              </Grid>

              <Grid item xs={12}>

                <Paper>

                  <Box sx={{ backgroundColor: 'white', padding: 2, mx: 5 }}            >

                    <Grid>

                      <Grid item xs={12}>

                        <InputText
                          dados={dados}
                          field='novaSenha'
                          label='Nova Senha'
                          setState={setDados}
                          erros={erros}
                          maxLength={15}
                        />

                      </Grid>

                      <Grid item xs={12} >

                        <InputText
                          dados={dados}
                          field='confirmarNovaSenha'
                          label='Confirme sua Nova Senha'
                          setState={setDados}
                          erros={erros}
                          maxLength={15}
                        />

                      </Grid>

                      <Grid item textAlign='right'>
                        <Button variant='contained' onClick={() => confirmarAlteracaoSenha()} sx={{ mt: 5 }}>Alterar Senha</Button>
                      </Grid>

                    </Grid>

                  </Box>

                </Paper>

              </Grid>

            </Grid>

          </Grid>

        </Grid>

      </div>
      {/*
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        height='100vh'
      >

      </Grid>
*/}

    </>
  )

}