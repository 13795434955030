/*
interface SISTEMA_PERMISSOES_INTERFACE {
  [key: string]: {
    MODULO: string
    PERMISSOES: {
      [key: string]: string
      // EXCLUIR: string,

    }
  }
}
*/

// TODO - Incluir Novo Módulo / Link

export interface SISTEMA_PERMISSOES_INTERFACE {

  RELATORIOS_CRM: {
    MODULO: string
    PERMISSOES: {
      AGENDAMENTOS: string
      FATURAMENTO: string
    }
  }

  API_WHATS_APP: {
    MODULO: string
    PERMISSOES: {
      MANUTENCAO: string
    }
  },

  CLIENTES: {
    MODULO: string
    PERMISSOES: {
      MANUTENCAO: string
      ACESSAR_OUTROS_USUARIOS: string
    }
  },

  CLINICAS: {
    MODULO: string
    PERMISSOES: {
      MANUTENCAO: string
      FERIADOS: string
    }
  },

  DENTISTAS: {
    MODULO: string
    PERMISSOES: {
      MANUTENCAO: string
      AGENDA: string
    }
  },

  ECURO: {
    MODULO: string
    PERMISSOES: {
      DOWNLOAD_MOVIMENTACAO: string
    }
  }

  FERIADOS_NACIONAIS: {
    MODULO: string
    PERMISSOES: {
      MANUTENCAO: string
    }
  },

  GRUPOS: {
    MODULO: string
    PERMISSOES: {
      MANUTENCAO: string
    }
  },

  PACIENTES: {
    MODULO: string
    PERMISSOES: {
      MANUTENCAO: string
    }
  },

  PARAMETROS: {
    MODULO: string
    PERMISSOES: {
      MANUTENCAO: string
    }
  },

  UNIDADEORGANIZACIONAL: {
    MODULO: string
    PERMISSOES: {
      MANUTENCAO: string
    }
  },

  USUARIOS: {
    MODULO: string
    PERMISSOES: {
      USUARIOS_GLOBAIS: string
      CADASTRO_USUARIO_CLINICA: string
    }
  }

}

export const MAXIMO_ERRO_LOGIN: number = 3

export const MAXIMO_ERRO_LOGIN_PACIENTE: number = 3

export const SISTEMA_PERMISSOES: SISTEMA_PERMISSOES_INTERFACE = {

  RELATORIOS_CRM: {
    MODULO: 'Relatórios CRM',
    PERMISSOES: {
      AGENDAMENTOS: 'Relatório de Agendamentos',
      FATURAMENTO: 'Relatório de Faturamento'
    }
  },

  CLIENTES: {
    MODULO: 'Cadastro de Clientes',
    PERMISSOES: {
      ACESSAR_OUTROS_USUARIOS: 'Acessar Clientes de Outros Usuários',
      MANUTENCAO: 'Manutenção do Cadastro de Clientes'
    }
  },

  UNIDADEORGANIZACIONAL: {
    MODULO: 'Unidades Organizacionais',
    PERMISSOES: {
      MANUTENCAO: 'Manutenção de Unidades Organizacionais'
    }
  },

  PARAMETROS: {
    MODULO: 'Parâmetros do Sistema',
    PERMISSOES: {
      MANUTENCAO: 'Atualizar Parâmetros do Sistema'
    }
  },

  PACIENTES: {
    MODULO: 'Cadastro de Pacientes',
    PERMISSOES: {
      MANUTENCAO: 'Manutenção de Pacientes'
    }
  },

  CLINICAS: {
    MODULO: 'Cadastro de Clínicas',
    PERMISSOES: {
      MANUTENCAO: 'Manutenção de Clínicas',
      FERIADOS: 'Manutenção dos Feriados das Clínicas'
    }
  },

  DENTISTAS: {
    MODULO: 'Cadastro de Dentistas',
    PERMISSOES: {
      MANUTENCAO: 'Manutenção de Dentistas',
      AGENDA: 'Bloqueio / Desbloqueio de Agenda dos Dentistas'
    }
  },

  GRUPOS: {
    MODULO: 'Cadastro de Grupos de Usuários',
    PERMISSOES: {
      MANUTENCAO: 'Manutenção de Grupos'
    }
  },

  USUARIOS: {
    MODULO: 'Cadastro de Usuário',
    PERMISSOES: {
      USUARIOS_GLOBAIS: 'Manutenção de Usuários Globais',
      CADASTRO_USUARIO_CLINICA: 'Manutenção de Usuários da Clínica'
    }
  },

  FERIADOS_NACIONAIS: {
    MODULO: 'Cadastro de Feriados Nacionais',
    PERMISSOES: {
      MANUTENCAO: 'Manutenção de Feriados Nacionais'
    }
  },

  API_WHATS_APP: {
    MODULO: 'WhatsAPP',
    PERMISSOES: {
      MANUTENCAO: 'Manutenção de WhatsAPP'
    }
  },

  ECURO: {
    MODULO: 'Ecuro',
    PERMISSOES: {
      DOWNLOAD_MOVIMENTACAO: 'Baixar Planilha de Movimentação'
    }
  }


}
