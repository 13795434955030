import React, { useContext, useState } from 'react'
import { Box, Container, Grid, IconButton, Paper, Typography } from '@mui/material'
import InputText from '../../DevComponents/InputText'
import { ContextoGlobal, ContextoGlobalInterface } from '../../GlobalStates/ContextoGlobal'
import Condicional from '../../Layout/Condicional'
import Button from '@mui/material/Button'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'

import DataTable, { DataTableCabecalhoInterface } from '../../DevComponents/DataTable'
import { useNavigate } from 'react-router-dom'
import { PacienteInterface } from '../../ImportBackend/Interfaces/PacienteInterfaces'
import ClsCrud from '../../Utils/ClsCrud'
import ClsValidacao from '../../Utils/ClsValidacao'
import ClsFormatacao from '../../Utils/ClsFormatacao'
import { PacienteEcuroInterface } from '../../ImportBackend/Interfaces/EcuroInterfaces'
import PacientesClinicas from './PacientesClinicas'
import ExibirJSONDev from '../../DevComponents/ExibirJSONDev'
import BackEndAPI from '../../Services/BackEndAPI'
import { MensagemTipo } from '../../GlobalStates/MensagemState'
import { RespostaPadraoInterface } from '../../ImportBackend/Interfaces/PadraoInterfaces'
import PacientesAtribuirSenha from './PacientesAtribuirSenha'

interface PesquisaInterface {
  descricao: string
}

export enum StatusForm {
  Incluindo,
  Excluindo,
  Pesquisando,
  Editando,
  EditandoClinicas,
  AtribuindoSenha
}

export default function Pacientes () {

  const [statusForm, setStatusForm] = useState<StatusForm>( StatusForm.Pesquisando )

  const clsApi = new BackEndAPI()

  const Cabecalho: Array<DataTableCabecalhoInterface> = [
    {
      campo: 'cpf',
      cabecalho: 'CPF',
      alinhamento: 'left'
    },
    {
      campo: 'nome',
      cabecalho: 'Nome',
      alinhamento: 'left'
    },
    {
      campo: 'whatsAPP',
      cabecalho: 'Whats APP',
      alinhamento: 'left'
    },
    /*
    {
      campo: 'email',
      cabecalho: 'e-mail',
      alinhamento: 'left'
    },
    */
    {
      campo: 'dataNascimento',
      cabecalho: 'Data de Nascimento',
      alinhamento: 'left',
      format: ( v ) => ( new ClsFormatacao() ).dataISOtoUser( v )
    },
    {
      campo: 'ativo',
      cabecalho: 'Ativo',
      alinhamento: 'left',
      format: ( v ) => v ? 'Sim' : 'Não'
    }
  ]

  const ResetDados: PacienteInterface = {
    idPaciente: 0,
    nome: '',
    cpf: '',
    whatsAPP: '',
    dataNascimento: '',
    email: '',
    ativo: true
  }

  const TituloForm = {
    [StatusForm.Incluindo]: 'Inclusão de Novo Paciente',
    [StatusForm.Excluindo]: 'Exclusão de Paciente',
    [StatusForm.Pesquisando]: 'Pacientes disponívels no APP',
    [StatusForm.Editando]: 'Alteração de Dados de Pacientes',
    [StatusForm.EditandoClinicas]: 'Bloqueio / Liberação de Clínicas para o Paciente',
    [StatusForm.AtribuindoSenha]: 'Atribuir Senha para Paciente',
  }

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface
  const { mensagemState, setMensagemState } = contexto

  const [dados, setDados] = useState<PacienteInterface>( ResetDados )

  const [erros, setErros] = useState( {} )

  const [pesquisa, setPesquisa] = useState<PesquisaInterface>( { descricao: '' } )

  const [rsPesquisa, setRsPesquisa] = useState<Array<PacienteInterface>>( [] )

  const navigate = useNavigate()

  const validarDados = (): boolean => {

    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.naoVazio( 'nome', dados, erros, retorno )
    retorno = clsValidacao.eTelefone( 'whatsAPP', dados, erros, retorno )
    retorno = clsValidacao.eData( 'dataNascimento', dados, erros, retorno )
    retorno = clsValidacao.eEmail( 'email', dados, erros, retorno, true )

    setErros( erros )

    return retorno

  }

  const clsCrud: ClsCrud<PacienteInterface> = new ClsCrud(
    navigate,
    ResetDados,
    setStatusForm as any,
    setDados,
    setErros,
    mensagemState,
    setMensagemState,
    setRsPesquisa,
    contexto,
    validarDados,
    {
      confirmarMutation: 'updatePaciente',
      excluirMutation: 'delPaciente',
      campoId: 'idPaciente',
      camposPesquisa: '{idPaciente nome cpf whatsAPP dataNascimento email ativo}',
      pesquisaQuery: 'getPacientes',
      pesquisaPorId: 'getPacientePorId',
      camposPesquisaPorId: '{idPaciente nome cpf whatsAPP dataNascimento email ativo}'
    },
    {
      confirmando: 'Atualizando Paciente',
      erroCadastro: 'Erro ao Cadastrar Paciente',
      erroExclusao: 'Erro ao Excluir Paciente',
      erroPesquisa: 'Erro ao Pesquisar Paciente',
      pesquisando: 'Pesquisando Dados de Pacientes...',
      sucessoCadastro: 'Paciente Cadastrada com Sucesso!',
      atualizacaoSucesso: 'Paciente Atualizada com Sucesso!',
      tituloConfirmado: 'Confirmado!',
      sucessoExclusao: 'Paciente Excluída com Sucesso...',
      tituloConfirmacaoExclusao: 'Confirma?',
      tituloErroCadastro: 'Erro!',
      tituloErroExclusao: 'Erro!',
      tituloErroPesquisa: 'Erro!',
      excluindo: 'Excluindo Paciente...'
    }
  )

  const onKeyPesquisa = () => {
    clsCrud.onClickPesquisa( pesquisa.descricao, mensagemState )
  }

  const onChangeCPF = ( cpf: string ) => {

    if ( cpf.length === 14 ) {

      const query: string = `
        getPacienteEcuro(cpf: "${cpf}") {
          idEcuroPaciente
          idEcuroClinica
          nome
          cpf
          dataNascimento
          fone
        }
      `

      clsApi.query<PacienteEcuroInterface>( query, 'getPacienteEcuro', 'Pesquisando Paciente...', contexto ).then( rsPacienteEcuro => {

        if ( rsPacienteEcuro.cpf.length === 14 ) {

          setDados( {
            idPaciente: 0,
            cpf: cpf,
            nome: rsPacienteEcuro.nome,
            dataNascimento: rsPacienteEcuro.dataNascimento,
            ativo: true,
            email: '',
            whatsAPP: ''
          } )

        } else {
          setDados( { ...dados, nome: '', dataNascimento: '', email: '', whatsAPP: '' } )
        }

      } ).catch( () => {
        setMensagemState( {
          ...mensagemState,
          exibir: true,
          mensagem: 'Erro Ao Pesquisar Paciente no Ecuro. Consulte Suporte.',
          tipo: MensagemTipo.Error,
          exibirBotao: true
        } )
      } )


    } else {
      setDados( { ...dados, cpf: cpf, nome: '', dataNascimento: '' } )
    }

  }

  const btEditarClinicas = ( rs: PacienteInterface ) => {
    setDados( rs )
    setStatusForm( StatusForm.EditandoClinicas )
  }

  const btAtribuirSenha = ( rs: PacienteInterface ) => {
    setDados( rs )
    setStatusForm( StatusForm.AtribuindoSenha )
  }

  const btResetSenha = ( rsPaciente: PacienteInterface ) => {
    const mutation = `
      resetSenhaPacientePorEmail(idPaciente: ${rsPaciente.idPaciente}) {
        ok
        mensagem
      }
    `

    clsApi.mutation<RespostaPadraoInterface>( mutation, 'resetSenhaPacientePorEmail', 'Reset Senha...', contexto ).then( rsResetSenha => {

      setMensagemState( {
        ...mensagemState,
        titulo: rsResetSenha.ok ? 'Sucesso!' : 'Erro!',
        exibir: true,
        mensagem: rsResetSenha.mensagem,
        tipo: rsResetSenha.ok ? MensagemTipo.Info : MensagemTipo.Error,
        exibirBotao: true
      } )

    } )

  }

  const atribuirSenhaPaciente = ( idPaciente: number, senha: string ) => {

    const mutation: string = `
      atribuirSenhaPaciente(idPaciente: ${idPaciente}, senha: "${senha}") {
        ok
        mensagem
      }
    `

    clsApi.mutation<RespostaPadraoInterface>( mutation, 'atribuirSenhaPaciente', 'Alterando a Senha do Paciente...', contexto ).then( rs => {

      if ( rs.ok ) {

        contexto.setMensagemState( {
          ...contexto.mensagemState,
          titulo: 'Confirmado!',
          exibir: true,
          mensagem: 'Senha Alterada com Sucesso!',
          tipo: MensagemTipo.Info,
          exibirBotao: true
        } )

        setDados( ResetDados )
        setStatusForm( StatusForm.Pesquisando )

      } else {

        contexto.setMensagemState( {
          ...contexto.mensagemState,
          titulo: 'Erro ao Alterar Senha!',
          exibir: true,
          mensagem: rs.mensagem,
          tipo: MensagemTipo.Error,
          exibirBotao: true
        } )

      }

    } )

  }

  return (
    <>
      <Container maxWidth="md" sx={{ mt: 5 }}>

        <Paper variant="outlined" sx={{ padding: 2 }}>
          <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography component="h5" variant="h5" align="left">
                Cadastro de Pacientes
                <Typography variant="body2" gutterBottom>
                  {TituloForm[statusForm]}
                </Typography>
              </Typography>

              <IconButton onClick={() => clsCrud.btFechar()}>
                <CloseIcon />
              </IconButton>
            </Grid>

            <Condicional condicao={statusForm === StatusForm.Pesquisando}>

              <Grid item xs={12} sm={10} >

                <InputText
                  dados={pesquisa}
                  field='descricao'
                  label='Pesquisar'
                  setState={setPesquisa}
                  iconeEnd="search"
                  onClickIconeEnd={() => onKeyPesquisa()}
                  mapKeyPress={[{ key: 'Enter', onKey: onKeyPesquisa }]}
                />

              </Grid>

              <Grid item xs={12} sm={2} sx={{ mt: { xs: 3, sm: 4.5 }, textAlign: { xs: 'right', sm: 'center' } }}>
                <Button variant='contained' onClick={
                  () => {
                    clsCrud.btIncluir()
                  }
                }>Incluir</Button>
              </Grid>

            </Condicional>

            <Condicional condicao={statusForm !== StatusForm.Pesquisando && statusForm !== StatusForm.AtribuindoSenha}>

              <Grid item xs={12} md={3}>

                <InputText
                  dados={dados}
                  field='cpf'
                  label='CPF'
                  onChange={( v: string ) => onChangeCPF( v )}
                  mask="cpf"
                  disabled={statusForm !== StatusForm.Incluindo}
                  erros={erros}
                  maxLength={14}
                />

              </Grid>

              <Grid item xs={12} md={9} sx={{ pl: { md: 1 } }}>

                <InputText
                  dados={dados}
                  field='nome'
                  label='Nome'
                  setState={setDados}
                  disabled={true}
                  erros={erros}
                  maxLength={50}
                />

              </Grid>

              <Grid item xs={12} md={2}>
                <InputText
                  dados={dados}
                  field='dataNascimento'
                  label='Data Nascimento'
                  setState={setDados}
                  disabled={true}
                  erros={erros}
                  type='date'
                />
              </Grid>

              <Grid item xs={12} md={3} sx={{ pl: { md: 1 } }}>
                <InputText
                  dados={dados}
                  field='whatsAPP'
                  label='Whats App'
                  erros={erros}
                  type='tel'
                  mask='tel'
                  disabled={statusForm === StatusForm.Excluindo || statusForm === StatusForm.EditandoClinicas}
                  setState={setDados}
                />
              </Grid>

              <Grid item xs={12} md={7} sx={{ pl: { md: 1 } }}>
                <InputText
                  dados={dados}
                  field='email'
                  label='e-mail'
                  setState={setDados}
                  disabled={statusForm === StatusForm.Excluindo || statusForm === StatusForm.EditandoClinicas}
                  erros={erros}
                  maxLength={255}
                />
              </Grid>

              <Grid item xs={12}>
                <Box display="flex" justifyContent="center" alignContent='center' sx={{ height: '100%' }}>
                  <InputText
                    dados={dados}
                    field='ativo'
                    label='Ativo'
                    setState={setDados}
                    tipo='checkbox'
                    disabled={statusForm === StatusForm.Excluindo || statusForm === StatusForm.EditandoClinicas}
                    erros={erros}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sx={{ mt: 5 }}>
                <PacientesClinicas
                  statusForm={statusForm as unknown as any}
                  rsPaciente={dados}
                />
              </Grid>

              <Grid item xs={12} sx={{ mt: 3 }}>

                <Condicional condicao={statusForm === StatusForm.Excluindo}>
                  <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => clsCrud.btConfirmarExclusao( dados, mensagemState, pesquisa.descricao )}>Confirmar</Button>
                </Condicional>

                <Condicional condicao={statusForm !== StatusForm.Excluindo && statusForm !== StatusForm.EditandoClinicas}>
                  <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => clsCrud.btConfirmar( dados, mensagemState, statusForm as any, pesquisa.descricao )}>Confirmar</Button>
                </Condicional>

                <Button variant='contained' startIcon={<CloseIcon />} sx={{ py: 1 }} onClick={() => clsCrud.btCancelar()}>Cancelar</Button>

              </Grid>

            </Condicional>

            <PacientesAtribuirSenha
              statusForm={statusForm}
              dados={dados}
              onConfirmar={atribuirSenhaPaciente}
              onCancelar={clsCrud.btCancelar}
            />

            <Condicional condicao={statusForm === StatusForm.Pesquisando}>
              <Grid item xs={12} sx={{ mt: 3 }}>
                <DataTable
                  dados={rsPesquisa}
                  cabecalho={Cabecalho}
                  acoes={[
                    { icone: 'create', toolTip: 'Editar', onAcionador: clsCrud.btEditar },
                    { icone: 'apartment', toolTip: 'Clinicas', onAcionador: btEditarClinicas },
                    { icone: 'key', toolTip: 'Atribuir Senha', onAcionador: btAtribuirSenha },
                    { icone: 'vpn_lock', toolTip: 'Senha Por e-mail', onAcionador: btResetSenha }
                  ]}
                />
              </Grid>
            </Condicional>

            <ExibirJSONDev exibir={false} oque={['Dados Pacientes: ', dados, 'Erros Pacientes:', erros]} />

          </Grid>
        </Paper >

      </Container >
    </>
  )
}