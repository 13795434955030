import React from 'react'

import { Button, Grid, Paper, Typography, Divider, Box } from '@mui/material'

import './ConfirmaAgendamento.css'
import { THEME } from '../../../Config/Theme'
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import { useLocation, useNavigate } from 'react-router-dom'
import CardConsulta from '../Componentes/CardConsulta'
import { rsAgendamentoClienteSiteRealizadoInterface } from '../../../ImportBackend/Interfaces/AgendamentoClienteInterfaces'
import ClsFormatacao from '../../../Utils/ClsFormatacao'
import { LINK_FACEBOOK_PAGINA_OBRIGADO, LINK_INSTAGRAM_PAGINA_OBRIGADO } from '../../../Config/ConfigFrontEnd';

export default function ConfirmaAgendamento () {

  const clsFormatacao: ClsFormatacao = new ClsFormatacao()

  const location = useLocation()

  const rsAgendamentoRealizado: rsAgendamentoClienteSiteRealizadoInterface & { nome: string } = location.state

  const primeiroNome: string = ( new ClsFormatacao() ).primeiraPalavra( rsAgendamentoRealizado.nome )

  const nav = useNavigate()

  const btNav = ( local: string ) => {
    nav( local )
  }

  const btGo = ( local: string ) => {
    window.location.href = local
  }

  return (
    <>
      <div className='imagemFundoConfirmaAgendamentoAvaliacao'>

        <Grid
          container
          minHeight='100VH'
        >

          <Grid item xs={12} md={6} sx={{ margin: 'auto' }}>

            <Grid container>

              <Grid item xs={12} sx={{ padding: 2, mt: 5 }}  >

                <Grid container>

                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      width: 'fit-content',
                      mx: 0.5,
                    }}
                  >

                    <Divider orientation='vertical' sx={{ backgroundColor: THEME.palette.primary.main, mr: 2, width: '3px' }} flexItem />

                    <Typography variant='h5' fontWeight='bold' color='white'>
                      Muito bem, {primeiroNome}! <br />Seu agendamento acaba <br />de ser confirmado!
                    </Typography>

                  </Box>


                </Grid>
              </Grid>

              <Grid item xs={12} sx={{ padding: 2 }}  >

                <CardConsulta
                  data={clsFormatacao.dataISOtoUser( rsAgendamentoRealizado.data )}
                  diaSemana={rsAgendamentoRealizado.diaSemana}
                  horario={rsAgendamentoRealizado.hora}
                  clinica={rsAgendamentoRealizado.descricaoClinica}
                  centralizarTitulos
                />

              </Grid>
              {/*
          <Grid item xs={12} sx={{ padding: 2 }}  >

            <Typography variant='h6' fontStyle='italic' align='center' fontWeight='bold' color='white'>
              Fique por dentro de suas consultas através do nosso app.
            </Typography>

          </Grid>

          <Grid item xs={12} sx={{ textAlign: 'center', mb: 5, mt: 1 }}>
            <BotaoConfirmar>Quero Receber Meu Usuário e Senha no WhatsAPP!</BotaoConfirmar>
          </Grid>
            */}

              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Paper sx={{ borderRadius: 0, backgroundColor: THEME.palette.primary.main, paddingLeft: 4, paddingY: 2 }}>
                  <Typography variant='h5' align='center' fontWeight='bold' color='white'>
                    Nos Acompanhe nas <br />nossas REDES SOCIAIS
                  </Typography>
                </Paper>
              </Grid>

              <Grid item xs={12} sx={{ textAlign: 'center', mt: 4 }}>
                <InstagramIcon sx={{ color: 'white', fontSize: 50, mr: 3 }} onClick={() => btGo( LINK_INSTAGRAM_PAGINA_OBRIGADO )} />
                <FacebookIcon sx={{ color: 'white', fontSize: 50 }} onClick={() => btGo( LINK_FACEBOOK_PAGINA_OBRIGADO )} />
              </Grid>

              <Grid item xs={12} sx={{ textAlign: 'center', pb: 3, mt: 2 }}>
                {/*
            <img alt='Vamos Sorrir Apontador'
            className='imgSetaVoltar'
            src={'/imagens/icones/setavoltar.png'}>
            </img>
              */}
                <Button
                  variant='text'
                  sx={{ color: 'white' }}
                  onClick={() => btNav( '/' )}
                >
                  Voltar à tela inicial
                </Button>

              </Grid>

            </Grid>

          </Grid>

        </Grid>

      </div >

    </>
  )

}