import React, { useContext, useEffect, useState } from 'react'
import LogoTop from '../../../Layout/LogoTop'
import {
  Button,
  Grid,
  Paper,
  Typography,
  useTheme
} from '@mui/material'
import { useNavigate } from 'react-router-dom';
import CabecalhoTela from '../Componentes/CabecalhoTela';
import BackEndAPI from '../../../Services/BackEndAPI';
import { ContextoGlobal, ContextoGlobalInterface } from '../../../GlobalStates/ContextoGlobal';
import { MensagemTipo } from '../../../GlobalStates/MensagemState';
import { ListarConsultasMarcadasInterface } from '../../../ImportBackend/Interfaces/AgendamentoInterfaces';
import { DateTime } from 'luxon';
import { DTFORMAT } from '../../../Utils/ClsFormatacao';
import ExibirJSONDev from '../../../DevComponents/ExibirJSONDev';
import { RespostaPadraoInterface } from '../../../ImportBackend/Interfaces/PadraoInterfaces';
import CardConsulta from '../Componentes/CardConsulta';


export default function ConsultarConsultas () {

  const abortController: AbortController = new AbortController()

  const clsApi = new BackEndAPI()

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface

  const theme = useTheme()

  const [rsConsultasMarcadas, setRsConsultasMarcadas] = useState<Array<ListarConsultasMarcadasInterface>>( [] )
  const [rsTitulo, setRsTitulo] = useState<string>( '' )

  const nav = useNavigate()

  const go = ( local: string ) => {
    window.location.href = local
  }

  const btOpcao = ( caminho: string ) => {
    nav( caminho )
  }

  const pesquisarConsultas = () => {
    const query = `
        listaConsultasMarcadas {
          idEcuroAgendamento
          data
          dia
          horario
          clinica
        }
      `

    clsApi.query<Array<ListarConsultasMarcadasInterface>>( query, 'listaConsultasMarcadas', 'Procurando Consultas...', contexto, abortController ).then( rsTmpConsultasMarcadas => {

      setRsConsultasMarcadas( rsTmpConsultasMarcadas )

      let txtTitulo: string = 'Você Não Tem Consultas Marcadas.'

      if ( rsTmpConsultasMarcadas.length === 1 ) {
        txtTitulo = 'Você tem 1 consulta marcada.'
      } else if ( rsTmpConsultasMarcadas.length > 1 ) {
        txtTitulo = 'Você tem '.concat( rsTmpConsultasMarcadas.length.toString() ).concat( ' consultas marcadas.' )
      }

      setRsTitulo( txtTitulo )

    } )

  }

  useEffect( () => {
    pesquisarConsultas()
    //eslint-disable-next-line
  }, [] )

  const btCancelarConsulta = ( idEcuroAgendamento: string, data: string ): void => {
    const mutation = `
        cancelarAgendamento (idEcuroAgendamento: "${idEcuroAgendamento}", data: "${DateTime.fromFormat( data, DTFORMAT.USUARIO ).toFormat( DTFORMAT.BANCO )}") {
          ok
          mensagem
        }
      `

    clsApi.mutation<RespostaPadraoInterface>( mutation, 'cancelarAgendamento', 'Cancelando Consulta...', contexto, abortController ).then( rsCancelamento => {

      if ( rsCancelamento.ok ) {

        pesquisarConsultas()

      } else {

        contexto.setMensagemState( {
          cb: null,
          exibir: true,
          exibirBotao: true,
          mensagem: 'Não Foi Possível Cancelar a Consulta!',
          tipo: MensagemTipo.Error,
          titulo: 'Consulte a Vamos Sorrir!'
        } )

      }

    } )

  }

  return (
    <>

      <div className='imagemFundoBranco'>

        <LogoTop />

        <Grid
          container
          minHeight='100VH'
        >

          <Grid item xs={12} md={5} sx={{ margin: 'auto' }}>

            <Grid container>

              <Grid item xs={12} mb={3}>

                <CabecalhoTela titulo={rsTitulo} />

              </Grid>

              <Grid item xs={12} sx={{ padding: 1 }}>

                <Paper sx={{ backgroundColor: theme.cores.cinzaFundo, padding: 2, mb: 2 }}>

                  <Grid container>
                    {rsConsultasMarcadas.map( ( rsConsulta, index ) =>
                      <Grid item xs={12} mt={2} key={index} sx={{ textAlign: 'right' }}>
                        <CardConsulta
                          data={rsConsulta.data}
                          diaSemana={rsConsulta.dia}
                          horario={rsConsulta.horario}
                          clinica={rsConsulta.clinica}
                          centralizarTitulos={true}
                        />
                        <Button variant='text' onClick={() => btCancelarConsulta( rsConsulta.idEcuroAgendamento, rsConsulta.data )}>Cancelar</Button>
                      </Grid>
                    )}

                  </Grid>

                </Paper>

              </Grid>

              <Grid item xs={12} sx={{ textAlign: 'center', mt: 5 }}>
                <Paper sx={{
                  borderRadius: 0,
                  backgroundColor: theme.palette.primary.main,
                  paddingLeft: 4,
                  paddingY: 2
                }}
                  onClick={() => go( 'https://g.page/r/CSAjw09MK0LAEBM/review' )}
                >
                  <Grid container>
                    <Grid item xs={8} pr={3}>
                      <Typography variant='h6' align='left' fontWeight='bold' color='white'>
                        Clique aqui e deixe sua avaliação
                      </Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ mt: 1 }}>
                      <img src="imagens/icones/googlebusiness.png" width={50} alt="Vouchers CSSJD" />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

              <Grid item xs={12} onClick={() => go( 'https://g.page/r/CSAjw09MK0LAEBM/review' )}>
                <Typography
                  sx={{ ml: 4, mt: 5 }}
                  variant='h6'
                  align='left'
                  fontWeight='bold'
                  color={theme.palette.secondary.main}>
                  Sua opinião é muito importante <br /> para nós!
                </Typography>
              </Grid>

              <Grid item xs={12} sx={{ textAlign: 'center', my: 5 }}>
                <Button
                  onClick={() => btOpcao( '/PacienteMenu' )}
                  sx={{
                    color: theme.palette.secondary.main,
                    fontWeight: 'bold',
                    mb: 2
                  }}
                  variant='text'>Voltar
                </Button>
              </Grid>

              <img alt='Vamos Sorrir Publicidade' src="/imagens/paciente/bannermenupaciente.png" width={'100%'} />

            </Grid>

          </Grid>

        </Grid>

        <ExibirJSONDev exibir={false} oque={[
          'Consultas Marcadas',
          rsConsultasMarcadas
        ]}></ExibirJSONDev>


      </div>
    </>
  )

}