import React, { useContext, useEffect, useState } from 'react'
import LogoTop from '../../../Layout/LogoTop'
import {
  Divider,
  Paper,
  TextField, Button, Grid, Typography, useTheme
} from '@mui/material'
import { useNavigate } from 'react-router-dom';

import './Paciente.css'
import CabecalhoTela from '../Componentes/CabecalhoTela';
import ComboBox from '../../../DevComponents/ComboBox';
import { ClinicasDisponiveisAgendamentoInterface } from '../../../ImportBackend/Interfaces/AgendamentoInterfaces';
import BackEndAPI from '../../../Services/BackEndAPI';
import { ContextoGlobal, ContextoGlobalInterface } from '../../../GlobalStates/ContextoGlobal';
import { MensagemTipo } from '../../../GlobalStates/MensagemState';
import ClsFormatacao from '../../../Utils/ClsFormatacao';
import ExibirJSONDev from '../../../DevComponents/ExibirJSONDev';
import InputText from '../../../DevComponents/InputText';

export default function TrocarSenha () {

  const clsApi = new BackEndAPI()

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface

  const [erros, setErros] = useState<Record<string, string>>( {} )

  const [dados, setDados] = useState( {
    senha: '',
    novaSenha: '',
    confirmacaoNovaSenha: '',
  } )

  const [exibirSenhaState, setExibirSenhaState] = useState(false)


  const theme = useTheme()

  const nav = useNavigate()

  const btOpcao = ( caminho: string ) => {
    nav( caminho )
  }

  const handleExibirSenha = () => {
    setExibirSenhaState( !exibirSenhaState )
  }

  return (
    <>
      <div className='imagemFundoBranco'>

        <LogoTop />

        <Grid
          container
          minHeight='100VH'
        >

          <Grid item xs={12} md={5} sx={{ margin: 'auto' }}>

            <Grid container>

              <Grid item xs={12} mb={5}>

                <CabecalhoTela titulo={'Troque sua senha'} />

              </Grid>

              <Grid item xs={11} sx={{ mt: 2 }}>
                <Paper sx={{ backgroundColor: theme.cores.cinzaFundo, padding: 2, mb: 2 }}>

                  <Grid container>

                    <Grid item xs={12} mt={2}>

                      <InputText
                        dados={dados}
                        field='senha'
                        label='Senha'
                        type={exibirSenhaState ? "text" : "password"}
                        setState={setDados}
                        iconeEnd='visibility'
                        onClickIconeEnd={handleExibirSenha}
                        erros={erros}
                      />

                    </Grid>

                    <Grid item xs={12} mt={2}>

                      <InputText
                        dados={dados}
                        field='novaSenha'
                        label='Nova Senha'
                        type={exibirSenhaState ? "text" : "password"}
                        setState={setDados}
                        iconeEnd='visibility'
                        onClickIconeEnd={handleExibirSenha}
                        erros={erros}
                      />

                    </Grid>

                    <Grid item xs={12} mt={2}>

                      <InputText
                        dados={dados}
                        field='confirmacaoNovaSenha'
                        label='Confirmar Nova Senha'
                        type={exibirSenhaState ? "text" : "password"}
                        setState={setDados}
                        iconeEnd='visibility'
                        onClickIconeEnd={handleExibirSenha}
                        erros={erros}
                      />

                    </Grid>

                  </Grid>

                  <Grid item xs={12} px={3} my={4}>
                    <Button
                      fullWidth
                      variant='contained'
                      onClick={() => btOpcao( '/ConsultarConsultas' )}
                      sx={{ borderRadius: 2, backgroundColor: theme.palette.secondary.main }}>
                      Confirmar Troca
                    </Button>
                  </Grid>

                </Paper>

              </Grid>


              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <Button
                  onClick={() => btOpcao( '/PacienteMenu' )}
                  sx={{
                    color: theme.palette.secondary.main,
                    fontWeight: 'bold',
                    mb: 2
                  }}
                  variant='text'>Voltar
                </Button>
              </Grid>

              <img alt='Vamos Sorrir Publicidade' src="/imagens/paciente/bannermenupaciente.png" width={'100%'} />

            </Grid>

          </Grid>

        </Grid>

        <ExibirJSONDev oque={[
          'Token',
          'Dados'
        ]}></ExibirJSONDev>

      </div>
    </>
  )

}