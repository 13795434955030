import { Divider, Typography, useTheme } from '@mui/material'
import React, { useContext } from 'react'
import { ContextoGlobal, ContextoGlobalInterface } from '../../../GlobalStates/ContextoGlobal'
import Condicional from '../../../Layout/Condicional'

export default function CabecalhoTela ( { titulo }: { titulo: string } ) {

  const { loginState } = ( useContext( ContextoGlobal ) as ContextoGlobalInterface )

  const theme = useTheme()

  const emHTML = ( oque: string ) => {
    return <span dangerouslySetInnerHTML={{ __html: oque }} />
  }

  return (
    <>

      <Condicional condicao={loginState.logado}>
        <Typography sx={{ ml: 5, mt: 5 }} variant='h6' fontWeight='bold' color='secondary'>
          {loginState.nome.split( ' ' )[0]}
        </Typography>

        {/*
      <Typography sx={{ ml: 5 }} variant='caption' color={theme.cores.cinzaTexto}>
        <b>Clínica:</b> Divinópolis | Av. 1o de Junho, 224
      </Typography>
*/}

        <Divider sx={{
          mt: 1, ml: 5,
          borderBottomWidth: '2px',
          opacity: '70%',
          backgroundColor: theme.palette.primary.main,
          maxWidth: '300px'
        }} />

      </Condicional>

      <Typography sx={{ mt: 2, ml: 5 }} variant='body1' fontSize={18} fontWeight='bold' color='secondary'>
        {emHTML( titulo )}
      </Typography>

      <Condicional condicao={!loginState.logado}>
        <Divider sx={{
          mt: 1, ml: 5,
          borderBottomWidth: '2px',
          opacity: '70%',
          backgroundColor: theme.palette.primary.main,
          maxWidth: '300px'
        }} />
      </Condicional>

    </>
  )

}