import React from 'react'
import { Card, CardContent, Typography, styled, useTheme } from '@mui/material'

interface PropsInterface {
  data: string
  diaSemana: string
  horario: string
  clinica: string
  centralizarTitulos?: boolean
}

export default function CardConsulta ( { data, diaSemana, horario, clinica, centralizarTitulos = false }: PropsInterface ) {

  const theme = useTheme()

  const Titulo = styled( Typography )`
    font-style: italic;
    text-align: ${centralizarTitulos ? 'center' : 'left'};
    font-weight: bold;
    color: ${theme.palette.secondary.main};
  `

  const Descricao = styled( Typography )`
    font-style: italic;
    text-align: center;
    font-weight: bold;
    color: ${theme.palette.secondary.main};
  `

  return (
    <>
      <Card sx={{ borderRadius: 4, border: '1px solid', borderColor: theme.palette.secondary.main }}>
        <CardContent>

          <Titulo variant='body1' mt={1}>Dia</Titulo>

          <Descricao variant='body2'>
            {data} | {diaSemana} - {horario}
          </Descricao>

          <Titulo variant='body1' mt={1}>Clínica</Titulo>

          <Descricao variant='body2'>
            {clinica}
          </Descricao>

        </CardContent>
      </Card>
    </>
  )

}