import React from 'react'

import './LogoTop.css'
import { useNavigate } from 'react-router-dom'

export default function LogoTop () {

  const nav = useNavigate()

  return (
    <>
      <img alt='Logomarca Vamos Sorrir'
        className="logoTop"
        width='100%'
        src={'/imagens/home/logo.png'}
        onClick={() => nav('/')}
      >
      </img>
    </>
  )

}