import React, { useState, useEffect, useContext } from 'react'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import BackEndAPI from '../../Services/BackEndAPI'
import { ContextoGlobal, ContextoGlobalInterface } from '../../GlobalStates/ContextoGlobal'
import { MensagemTipo } from '../../GlobalStates/MensagemState'
import DataTable, { DataTableCabecalhoInterface } from '../../DevComponents/DataTable'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { RespostaPadraoInterface } from '../../ImportBackend/Interfaces/PadraoInterfaces'
import Typography from '@mui/material/Typography'
import ClsValidacao from '../../Utils/ClsValidacao'
import ClsUtils from 'zlib-utils'
import InputText from '../../DevComponents/InputText'
import { Container, IconButton, Paper } from '@mui/material'
import ClsFormatacao from '../../Utils/ClsFormatacao'
import { FeriadoNacionalInterface } from '../../ImportBackend/Interfaces/FeriadoNacionalInterfaces'
import { useNavigate } from 'react-router-dom'

export default function FeriadosNacionais () {

  const ResetDados: FeriadoNacionalInterface = {
    idFeriadoNacional: 0,
    data: '',
    descricao: ''
  }

  const clsApi = new BackEndAPI()

  const [rsFeriadoNacional, setRsFeriadoNacionais] = useState<Array<FeriadoNacionalInterface>>( [] )
  const [rsFeriado, setRsFeriado] = useState<FeriadoNacionalInterface>( ResetDados )

  const [erros, setErros] = useState( {} )

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface
  const { mensagemState, setMensagemState } = contexto

  const clsFormatos: ClsFormatacao = new ClsFormatacao()

  const Cabecalho: Array<DataTableCabecalhoInterface> = [
    {
      campo: 'data',
      cabecalho: 'Data',
      alinhamento: 'left',
      format: ( v ) => clsFormatos.dataISOtoUser( v )
    },
    {
      campo: 'descricao',
      cabecalho: 'Descrição',
      alinhamento: 'left'
    }
  ]

  const btExcluirFeriado = ( rsFeriadoNacional: FeriadoNacionalInterface ) => {

    const mutation = `
        excluirFeriadoNacional(data: "${rsFeriadoNacional.data}") {
          ok
          mensagem
        }
      `

    clsApi.mutation<RespostaPadraoInterface>( mutation, 'excluirFeriadoNacional', 'Excluindo Feriado...', contexto ).then( rs => {

      if ( rs.ok ) {

        setRsFeriado( { ...rsFeriadoNacional, idFeriadoNacional: 0 } )

        pesquisarFeriadoNacional()

      } else {

        setMensagemState( {
          ...mensagemState,
          exibir: true,
          mensagem: rs.mensagem,
          tipo: MensagemTipo.Error,
          exibirBotao: true
        } )

      }

    } )

  }

  const abortController: AbortController = new AbortController()

  const pesquisarFeriadoNacional = () => {
    const query = `
      getFeriadosNacionais {
        idFeriadoNacional
        data
        descricao
      }
    `

    clsApi.query<Array<FeriadoNacionalInterface>>( query, 'getFeriadosNacionais', 'Recebendo Cadastro de Feriados...', contexto, abortController ).then( rsFeriadoNacionais => {
      setRsFeriadoNacionais( rsFeriadoNacionais )

    } ).catch( () => {

      setMensagemState( {
        ...mensagemState,
        titulo: 'Erro! Consulte Suporte!',
        exibir: true,
        mensagem: 'Erro ao Consultar Feriados!',
        tipo: MensagemTipo.Error,
        exibirBotao: true
      } )

    } )
  }

  useEffect( () => {

    pesquisarFeriadoNacional()

    return () => {

      abortController.abort()

    }

    //eslint-disable-next-line
  }, [] )

  const validarDados = () => {

    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.eData( 'data', rsFeriado, erros, retorno )
    retorno = clsValidacao.naoVazio( 'descricao', rsFeriado, erros, retorno )

    setErros( erros )

    return retorno
  }

  const btIncluirFeriado = () => {

    if ( validarDados() ) {

      const mutation = `
        incluirFeriadoNacional(dados: ${( new ClsUtils() ).ConverterEmGql( rsFeriado )}) {
          ok
          mensagem
        }
      `

      clsApi.mutation<RespostaPadraoInterface>( mutation, 'incluirFeriadoNacional', 'Incluindo Feriado...', contexto ).then( rs => {

        if ( rs.ok ) {

          pesquisarFeriadoNacional()
          setRsFeriado( ResetDados )

        } else {

          setMensagemState( {
            ...mensagemState,
            exibir: true,
            mensagem: rs.mensagem,
            tipo: MensagemTipo.Error,
            exibirBotao: true
          } )

        }

      } )

    }

  }

  const navigate = useNavigate()

  const btFechar = () => {
    navigate( '/' )
  }

  return (
    <>
      <Container maxWidth="sm" sx={{ mt: 5 }}>

        <Paper variant="outlined" sx={{ padding: 2 }}>

          <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography component="h5" variant="h5" align="left">
                Feriados
                <Typography variant="body2" gutterBottom>
                  Feriados Nacionais - Válidos para Todas as Clínicas
                </Typography>
              </Typography>

              <IconButton onClick={() => btFechar()}>
                <CloseIcon />
              </IconButton>
            </Grid>

            <Grid item xs={12}>

              <InputText
                dados={rsFeriado}
                field='data'
                label='Data'
                setState={setRsFeriado}
                erros={erros}
                tipo='date'
              />

            </Grid>

            <Grid item xs={12}>

              <InputText
                dados={rsFeriado}
                field='descricao'
                label='Descrição'
                setState={setRsFeriado}
                erros={erros}
                maxLength={50}
              />

            </Grid>

            <Grid item xs={12} sx={{ mt: 3 }}>
              <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => btIncluirFeriado()}>Incluir Feriado</Button>
            </Grid>

            <Grid item xs={12} sx={{ mt: 3 }}>

              <Typography component="h6" variant="h6" align="left">
                Feriados Locais Para Esta Clínica
              </Typography>

              <DataTable
                dados={rsFeriadoNacional}
                cabecalho={Cabecalho}
                acoes={[{ icone: 'delete', toolTip: 'Apagar Feriado', onAcionador: btExcluirFeriado }]}
              />

            </Grid>

          </Grid>

        </Paper>

      </Container>

    </>
  )
}