import React from 'react'

import { createBrowserRouter } from 'react-router-dom'
import ErroAplicacao from '../Layout/ErroAplicacao'
import Layout from '../Layout/Layout'
import Usuarios from '../View/Crud/Sistema/Usuarios'
import Grupos from '../View/Crud/Sistema/Grupos'
import Logout from '../View/Controller/Logout'
import AlterarSenha from '../View/Controller/AlterarSenha'
import Login from '../View/Controller/Login'

import AgendamentoAvaliacao from '../View/App/AgendamentoAvaliacao/AgendamentoAvaliacao'
import ConfirmaAgendamento from '../View/App/ConfirmaAgendamento/ConfirmaAgendamento'
import Home from '../View/App/Home/Home'
import LoginPaciente from '../View/App/Login/LoginPaciente'
import PacienteMenu from '../View/App/Paciente/PacienteMenu'
import ConsultarConsultas from '../View/App/Paciente/ConsultarConsultas'
import NovoOrcamento from '../View/App/Paciente/NovoOrcamento'
import AgendarManutencao from '../View/App/Paciente/AgendarManutencao'
import MarcarOutrasEspecialidades from '../View/App/Paciente/MarcarOutrasEspecialidades'
import TrocarSenha from '../View/App/Paciente/TrocarSenha'
import Clinicas from '../View/Crud/Clinicas'
import Teste from '../exemplo_apagar/teste'
import Dentistas from '../View/Crud/Dentistas'
import FeriadosNacionais from '../View/Crud/FeriadosNacionais'
import Pacientes from '../View/Crud/Pacientes'
import PacienteAlterarSenhaLink from '../View/App/Paciente/PacienteSenhaClienteLink'
import SetupWhatsAPP from '../View/SetupWhatsAPP/SetupWhatsAPP'
import DownloadBaseEcuro from '../View/Relatorios/DownloadBaseEcuro'
import Parametros from '../View/Crud/Parametros'
import UnidadesOrganizacionais from '../View/Crud/UnidadesOrganizacionais'
import Clientes from '../View/Crud/Clientes/Clientes'
import RelAgendamentos from '../RelatoriosCRM/RelAgendamentos'
import RelFaturamento from '../RelatoriosCRM/RelFaturamento'

// TODO - Incluir Novo Módulo / Link

export const router = createBrowserRouter( [
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErroAplicacao />,
    children: [
      // Opções de Administradores / Pós Login
      {
        path: "Parametros",
        element: <Parametros />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "Clinicas",
        element: <Clinicas />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "Clientes",
        element: <Clientes />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "Dentistas",
        element: <Dentistas />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "Pacientes",
        element: <Pacientes />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "FeriadosNacionais",
        element: <FeriadosNacionais />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "Grupos",
        element: <Grupos />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "Login",
        element: <Login />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "Admin",
        element: <Login />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "Logout",
        element: <Logout />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "AlterarSenha",
        element: <AlterarSenha />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "/AlterarSenhaPaciente/:token",
        element: <PacienteAlterarSenhaLink />
      },
      {
        path: "Usuarios",
        element: <Usuarios />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "SetupWhatsAPP",
        element: <SetupWhatsAPP />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "DownloadEcuro",
        element: <DownloadBaseEcuro />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "relAgendamento",
        element: <RelAgendamentos />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "relFaturamento",
        element: <RelFaturamento />,
        errorElement: <ErroAplicacao />
      },

      // Menu Pacientes - Usuário do APP
      {
        path: "AgendamentoAvaliacao",
        element: <AgendamentoAvaliacao />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "ConfirmaAgendamento",
        element: <ConfirmaAgendamento />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "LoginPaciente",
        element: <LoginPaciente />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "PacienteMenu",
        element: <PacienteMenu />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "ConsultarConsultas",
        element: <ConsultarConsultas />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "NovoOrcamento",
        element: <NovoOrcamento />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "AgendarManutencao",
        element: <AgendarManutencao />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "MarcarOutrasEspecialidades",
        element: <MarcarOutrasEspecialidades />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "TrocarSenha",
        element: <TrocarSenha />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "UnidadesOrganizacionais",
        element: <UnidadesOrganizacionais />,
        errorElement: <ErroAplicacao />
      },
      {
        path: "/",
        element: <Home />,
        errorElement: <ErroAplicacao />
      }
    ]
  },
  {
    path: "/ErroAplicacao",
    element: <ErroAplicacao />
  },
  {
    path: "/Teste",
    element: <Teste />
  },
] );