import React, { useState, useEffect, useContext } from 'react'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Condicional from '../../Layout/Condicional'
import ShowText from '../../DevComponents/ShowText'
import BackEndAPI from '../../Services/BackEndAPI'
import { ContextoGlobal, ContextoGlobalInterface } from '../../GlobalStates/ContextoGlobal'
import { MensagemTipo } from '../../GlobalStates/MensagemState'
import DataTable, { DataTableCabecalhoInterface } from '../../DevComponents/DataTable'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { RespostaPadraoInterface } from '../../ImportBackend/Interfaces/PadraoInterfaces'
import Typography from '@mui/material/Typography'
import ClsValidacao from '../../Utils/ClsValidacao'
import ClsUtils from 'zlib-utils'
import InputText from '../../DevComponents/InputText'
import { StatusForm } from './Dentistas'
import { Box, Container, Paper, Stack } from '@mui/material'
import ClsFormatacao from '../../Utils/ClsFormatacao'
import { DentistaAgendaInterface, DentistaInterface } from '../../ImportBackend/Interfaces/DentistaInterfaces'
import ComboBox from '../../DevComponents/ComboBox'
import { EMDESENVOLVIMENTO } from '../../ImportBackend/Config/emDesenvolvimento'

interface PropsInterface {
  statusForm: StatusForm
  dados: DentistaInterface
  onCancelar: () => void
}

export default function DentistasAgenda ( { statusForm, dados, onCancelar }: PropsInterface ) {

  const ResetDados: DentistaAgendaInterface = {
    idDentistaAgenda: 0,
    idDentista: 0,
    tipo: 'B',
    dtInicio: '',
    dtTermino: '',
    hrInicio: '',
    hrTermino: '',
    seg: false,
    ter: false,
    qua: false,
    qui: false,
    sex: false,
    sab: false,
    dom: false
  }

  const clsApi = new BackEndAPI()

  const [rsDentistaAgenda, setRsDentistaAgenda] = useState<Array<DentistaAgendaInterface>>( [] )
  const [rsEdicao, setRsEdicao] = useState<DentistaAgendaInterface>( ResetDados )

  const [erros, setErros] = useState( {} )

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface
  const { mensagemState, setMensagemState } = contexto

  const clsFormatos: ClsFormatacao = new ClsFormatacao()

  const Cabecalho: Array<DataTableCabecalhoInterface> = [
    {
      campo: 'tipo',
      cabecalho: 'Tipo',
      alinhamento: 'left',
      format: ( v: string ) => v === 'B' ? 'Bloqueio' : 'Liberado'
    },
    {
      campo: 'dtInicio',
      cabecalho: 'Período',
      alinhamento: 'left',
      format: ( v: string, rs: DentistaAgendaInterface ) => clsFormatos.dataISOtoUser( v ).concat( ' - ' ).concat( clsFormatos.dataISOtoUser( rs.dtTermino ) )
    },
    {
      campo: 'hrInicio',
      cabecalho: 'Intervalo',
      alinhamento: 'left',
      format: ( v: string, rs: DentistaAgendaInterface ) => v.concat( ' - ' ).concat( rs.hrTermino )
    },
    {
      campo: 'dom',
      cabecalho: 'D',
      alinhamento: 'left',
      format: ( v: boolean ) => v ? 'S' : ''
    },
    {
      campo: 'seg',
      cabecalho: 'S',
      alinhamento: 'left',
      format: ( v: boolean ) => v ? 'S' : ''
    },
    {
      campo: 'ter',
      cabecalho: 'T',
      alinhamento: 'left',
      format: ( v: boolean ) => v ? 'S' : ''
    },
    {
      campo: 'qua',
      cabecalho: 'Q',
      alinhamento: 'left',
      format: ( v: boolean ) => v ? 'S' : ''
    },
    {
      campo: 'qui',
      cabecalho: 'Q',
      alinhamento: 'left',
      format: ( v: boolean ) => v ? 'S' : ''
    },
    {
      campo: 'sex',
      cabecalho: 'S',
      alinhamento: 'left',
      format: ( v: boolean ) => v ? 'S' : ''
    },
    {
      campo: 'sab',
      cabecalho: 'S',
      alinhamento: 'left',
      format: ( v: boolean ) => v ? 'S' : ''
    }
  ]

  const btExcluirAgenda = ( rsDentistaAgenda: DentistaAgendaInterface ) => {

    const mutation = `
        excluirDentistaAgenda(idDentistaAgenda: ${rsDentistaAgenda.idDentistaAgenda}) {
          ok
          mensagem
        }
      `

    clsApi.mutation<RespostaPadraoInterface>( mutation, 'excluirDentistaAgenda', 'Excluindo Agenda...', contexto ).then( rs => {

      if ( rs.ok ) {

        setRsEdicao( { ...rsDentistaAgenda, idDentistaAgenda: 0 } )

        pesquisarDentistaAgenda()

      } else {

        setMensagemState( {
          ...mensagemState,
          exibir: true,
          mensagem: rs.mensagem,
          tipo: MensagemTipo.Error,
          exibirBotao: true
        } )

      }

    } )

  }

  const abortController: AbortController = new AbortController()

  const pesquisarDentistaAgenda = () => {
    const query = `
      getDentistaAgenda(idDentista: ${dados.idDentista}) {
        idDentistaAgenda
        idDentista
        tipo
        dtInicio
        dtTermino
        hrInicio
        hrTermino
        seg
        ter
        qua
        qui
        sex
        sab
        dom
      }
    `

    clsApi.query<Array<DentistaAgendaInterface>>( query, 'getDentistaAgenda', 'Recebendo Agenda...', contexto, abortController ).then( rsDentistaAgenda => {
      setRsDentistaAgenda( rsDentistaAgenda )

    } ).catch( () => {

      setMensagemState( {
        ...mensagemState,
        titulo: 'Erro! Consulte Suporte!',
        exibir: true,
        mensagem: 'Erro ao Consultar Agendas da Clínica!',
        tipo: MensagemTipo.Error,
        exibirBotao: true
      } )

    } )
  }

  useEffect( () => {

    if ( [StatusForm.EditandoAgenda].includes( statusForm ) ) {

      pesquisarDentistaAgenda()

    }

    return () => {

      abortController.abort()

    }

    //eslint-disable-next-line
  }, [dados, statusForm] )

  const validarDados = () => {

    let retorno: boolean = true
    let retornoData: boolean = true
    let retornoHorario: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.naoVazio( 'tipo', rsEdicao, erros, retorno )
    retornoData = clsValidacao.eData( 'dtInicio', rsEdicao, erros, retornoData )
    retornoData = clsValidacao.eData( 'dtTermino', rsEdicao, erros, retornoData )
    retornoHorario = clsValidacao.eHorario( 'hrInicio', rsEdicao, erros, retornoHorario )
    retornoHorario = clsValidacao.eHorario( 'hrTermino', rsEdicao, erros, retornoHorario )

    if ( retornoData ) {
      if ( rsEdicao.dtInicio > rsEdicao.dtTermino ) {
        erros.dtInicio = 'Deve ser < Término!'
        retornoData = false
      }
    }

    if ( retornoHorario ) {
      if ( rsEdicao.hrInicio > rsEdicao.hrTermino ) {
        erros.hrInicio = 'Deve ser < Término!'
        retornoHorario = false
      }
    }

    setErros( erros )

    return retorno && retornoData && retornoHorario
  }

  const btIncluirAgenda = () => {

    if ( validarDados() ) {

      const mutation = `
        incluirDentistaAgenda(dados: ${( new ClsUtils() ).ConverterEmGql( { ...rsEdicao, idDentista: dados.idDentista, idDentistaAgenda: 0 } )}) {
          ok
          mensagem
        }
      `

      clsApi.mutation<RespostaPadraoInterface>( mutation, 'incluirDentistaAgenda', 'Incluindo Agenda...', contexto ).then( rs => {

        if ( rs.ok ) {

          pesquisarDentistaAgenda()
          // setRsEdicao( ResetDados )

        } else {

          setMensagemState( {
            ...mensagemState,
            exibir: true,
            mensagem: rs.mensagem,
            tipo: MensagemTipo.Error,
            exibirBotao: true
          } )

        }

      } )

    }

  }

  return (
    <>
      <Condicional condicao={[StatusForm.EditandoAgenda].includes( statusForm )}>

        <Grid item xs={12}>
          <ShowText dados={dados} field='nome' label='Dentista' />
        </Grid>

        <Grid item xs={12}>

          <Container maxWidth="md" sx={{ mt: 5 }}>

            <Paper variant="outlined" sx={{ padding: 2 }}>

              <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

                <Grid item xs={12} sm={4}>

                  <ComboBox
                    dados={rsEdicao}
                    opcoes={[{ id: 'B', descricao: 'Bloqueado' }, { id: 'L', descricao: 'Liberado' }]}
                    field='tipo'
                    label='Tipo'
                    setState={setRsEdicao}
                    disabled={statusForm === StatusForm.Excluindo}
                    erros={erros}
                    campoDescricao='descricao'
                    campoID='id'
                  />

                </Grid>

                <Grid item xs={12} sm={4} sx={{ pl: { sm: 1 } }}>

                  <InputText
                    dados={rsEdicao}
                    field='dtInicio'
                    label='Início'
                    setState={setRsEdicao}
                    disabled={statusForm === StatusForm.Excluindo}
                    erros={erros}
                    tipo='date'
                  />

                </Grid>

                <Grid item xs={12} sm={4} sx={{ pl: { sm: 1 } }}>

                  <InputText
                    dados={rsEdicao}
                    field='dtTermino'
                    label='Término'
                    setState={setRsEdicao}
                    disabled={statusForm === StatusForm.Excluindo}
                    erros={erros}
                    tipo='date'
                  />

                </Grid>

                <Grid item xs={6} sm={2} sx={{ pl: { md: 1 } }}>

                  <InputText
                    dados={rsEdicao}
                    field='hrInicio'
                    label='Início'
                    setState={setRsEdicao}
                    disabled={statusForm === StatusForm.Excluindo}
                    erros={erros}
                    mask="00:00"
                  />

                </Grid>

                <Grid item xs={6} sm={2} sx={{ pl: 1 }}>

                  <InputText
                    dados={rsEdicao}
                    field='hrTermino'
                    label='Término'
                    setState={setRsEdicao}
                    disabled={statusForm === StatusForm.Excluindo}
                    erros={erros}
                    mask="00:00"
                  />

                </Grid>

                <Grid item xs={12} sm={8} sx={{ pl: { md: 1 }, mt: 2 }}>

                  <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>

                    <Stack direction="row" spacing={1}>

                      <InputText
                        dados={rsEdicao}
                        field='dom'
                        label='D'
                        setState={setRsEdicao}
                        disabled={statusForm === StatusForm.Excluindo}
                        erros={erros}
                        tipo='checkbox'
                        labelPlacement='top'
                      />

                      <InputText
                        dados={rsEdicao}
                        field='seg'
                        label='S'
                        setState={setRsEdicao}
                        disabled={statusForm === StatusForm.Excluindo}
                        erros={erros}
                        tipo='checkbox'
                        labelPlacement='top'
                      />

                      <InputText
                        dados={rsEdicao}
                        field='ter'
                        label='T'
                        setState={setRsEdicao}
                        disabled={statusForm === StatusForm.Excluindo}
                        erros={erros}
                        tipo='checkbox'
                        labelPlacement='top'
                      />

                      <InputText
                        dados={rsEdicao}
                        field='qua'
                        label='Q'
                        setState={setRsEdicao}
                        disabled={statusForm === StatusForm.Excluindo}
                        erros={erros}
                        tipo='checkbox'
                        labelPlacement='top'
                      />

                      <InputText
                        dados={rsEdicao}
                        field='qui'
                        label='Q'
                        setState={setRsEdicao}
                        disabled={statusForm === StatusForm.Excluindo}
                        erros={erros}
                        tipo='checkbox'
                        labelPlacement='top'
                      />

                      <InputText
                        dados={rsEdicao}
                        field='sex'
                        label='S'
                        setState={setRsEdicao}
                        disabled={statusForm === StatusForm.Excluindo}
                        erros={erros}
                        tipo='checkbox'
                        labelPlacement='top'
                      />

                      <InputText
                        dados={rsEdicao}
                        field='sab'
                        label='S'
                        setState={setRsEdicao}
                        disabled={statusForm === StatusForm.Excluindo}
                        erros={erros}
                        tipo='checkbox'
                        labelPlacement='top'
                      />

                    </Stack>

                  </Box>

                </Grid>

                {/*

                <Grid item xs={12}>

                  <InputText
                    dados={rsEdicao}
                    field='descricao'
                    label='Descrição'
                    setState={setRsEdicao}
                    disabled={statusForm === StatusForm.Excluindo}
                    erros={erros}
                    maxLength={50}
                  />

                </Grid>
  */}

                <Grid item xs={12} sx={{ mt: 3 }}>
                  <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => btIncluirAgenda()}>Incluir Agenda</Button>
                  <Button variant='contained' startIcon={<CloseIcon />} sx={{ py: 1 }} onClick={() => onCancelar()}>Cancelar</Button>
                </Grid>

                <Grid item xs={12} sx={{ mt: 3 }}>

                  <Typography component="h6" variant="h6" align="left">
                    Agenda do Dentista
                  </Typography>

                  <DataTable
                    dados={rsDentistaAgenda}
                    cabecalho={Cabecalho}
                    acoes={[{ icone: 'delete', toolTip: 'Apagar Agenda', onAcionador: btExcluirAgenda }]}
                  />

                </Grid>

                <Condicional condicao={EMDESENVOLVIMENTO}>
                  <Grid item xs={12}>
                    <pre >
                      {JSON.stringify( rsEdicao, null, 4 )}
                    </pre>
                  </Grid>

                  <Grid item xs={12}>
                    <pre >
                      {JSON.stringify( erros, null, 4 )}
                    </pre>
                  </Grid>
                </Condicional>

              </Grid>

            </Paper>

          </Container>

        </Grid>

      </Condicional>

    </>
  )

}