import React, { useState, useEffect, useContext } from 'react'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Condicional from '../../Layout/Condicional'
import ShowText from '../../DevComponents/ShowText'
import BackEndAPI from '../../Services/BackEndAPI'
import { ContextoGlobal, ContextoGlobalInterface } from '../../GlobalStates/ContextoGlobal'
import { MensagemTipo } from '../../GlobalStates/MensagemState'
import DataTable, { DataTableCabecalhoInterface } from '../../DevComponents/DataTable'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { RespostaPadraoInterface } from '../../ImportBackend/Interfaces/PadraoInterfaces'
import Typography from '@mui/material/Typography'
import ClsValidacao from '../../Utils/ClsValidacao'
import ClsUtils from 'zlib-utils'
import InputText from '../../DevComponents/InputText'
import { StatusForm } from './Dentistas'
import { Box, Container, Paper, Stack } from '@mui/material'
import ClsFormatacao from '../../Utils/ClsFormatacao'
import { DentistaEspecialidadeInterface, DentistaInterface } from '../../ImportBackend/Interfaces/DentistaInterfaces'
import ComboBox from '../../DevComponents/ComboBox'
import { EMDESENVOLVIMENTO } from '../../ImportBackend/Config/emDesenvolvimento'
import { EspecialidadesEcuroTypes } from '../../ImportBackend/types/ConstantesDataTypes'

interface PropsInterface {
  statusForm: StatusForm
  dados: DentistaInterface
  onCancelar: () => void
}

export default function DentistasEspecialidades ( { statusForm, dados, onCancelar }: PropsInterface ) {

  const ResetDados: DentistaEspecialidadeInterface = {
    idDentistaEspecialidade: 0,
    idDentista: dados.idDentista as number,
    minutoEntreConsultas: 0,
    idEcuroEspecialidade: '',
    ativo: true
  }

  const clsApi = new BackEndAPI()

  const [rsDentistaEspecialidade, setRsDentistaEspecialidade] = useState<Array<DentistaEspecialidadeInterface>>( [] )
  const [rsEdicao, setRsEdicao] = useState<DentistaEspecialidadeInterface>( ResetDados )

  const [erros, setErros] = useState( {} )

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface
  const { mensagemState, setMensagemState } = contexto

  const clsFormatos: ClsFormatacao = new ClsFormatacao()

  const Cabecalho: Array<DataTableCabecalhoInterface> = [
    {
      campo: 'idEcuroEspecialidade',
      cabecalho: 'Especialidade',
      alinhamento: 'left',
      format: ( rs: string ) => EspecialidadesEcuroTypes.find( v => v.specialityId === rs )?.descricao
    },
    {
      campo: 'minutoEntreConsultas',
      cabecalho: 'Tempo Entre Consultas',
      alinhamento: 'left'
    },
    {
      campo: 'ativo',
      cabecalho: 'Ativo',
      alinhamento: 'left',
      format: ( v: boolean ) => v ? 'Sim' : 'Não'
    }
  ]

  const btExcluirEspecialidade = ( rsDentistaEspecialidade: DentistaEspecialidadeInterface ) => {

    const mutation = `
        excluirDentistaEspecialidade(idDentistaEspecialidade: ${rsDentistaEspecialidade.idDentistaEspecialidade}) {
          ok
          mensagem
        }
      `

    clsApi.mutation<RespostaPadraoInterface>( mutation, 'excluirDentistaEspecialidade', 'Excluindo Especialidade...', contexto ).then( rs => {

      if ( rs.ok ) {

        setRsEdicao( { ...rsDentistaEspecialidade, idDentista: dados.idDentista as number } )

        pesquisarDentistaEspecialidade()

      } else {

        setMensagemState( {
          ...mensagemState,
          exibir: true,
          mensagem: rs.mensagem,
          tipo: MensagemTipo.Error,
          exibirBotao: true
        } )

      }

    } )

  }

  const abortController: AbortController = new AbortController()

  const pesquisarDentistaEspecialidade = () => {
    const query = `
      getDentistaEspecialidade(idDentista: ${dados.idDentista}) {
        idDentistaEspecialidade
        idDentista
        minutoEntreConsultas
        idEcuroEspecialidade
        ativo
      }
    `

    clsApi.query<Array<DentistaEspecialidadeInterface>>( query, 'getDentistaEspecialidade', 'Recebendo Especialidade...', contexto, abortController ).then( rsDentistaEspecialidade => {
      setRsDentistaEspecialidade( rsDentistaEspecialidade )

    } ).catch( () => {

      setMensagemState( {
        ...mensagemState,
        titulo: 'Erro! Consulte Suporte!',
        exibir: true,
        mensagem: 'Erro ao Consultar Especialidades da Clínica!',
        tipo: MensagemTipo.Error,
        exibirBotao: true
      } )

    } )
  }

  useEffect( () => {

    if ( [StatusForm.EditandoEspecialidades].includes( statusForm ) ) {

      pesquisarDentistaEspecialidade()

    }

    return () => {

      abortController.abort()

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dados, statusForm] )

  const validarDados = () => {

    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.naoVazio( 'minutoEntreConsultas', rsEdicao, erros, retorno )
    retorno = clsValidacao.naoVazio( 'idEcuroEspecialidade', rsEdicao, erros, retorno )

    setErros( erros )

    return retorno

  }

  const btIncluirEspecialidade = () => {

    if ( validarDados() ) {

      const mutation = `
        incluirDentistaEspecialidade(dados: ${( new ClsUtils() ).ConverterEmGql( { ...rsEdicao, idDentista: dados.idDentista, idDentistaEspecialidade: 0 } )}) {
          ok
          mensagem
        }
      `

      clsApi.mutation<RespostaPadraoInterface>( mutation, 'incluirDentistaEspecialidade', 'Incluindo Especialidade...', contexto ).then( rs => {

        if ( rs.ok ) {

          pesquisarDentistaEspecialidade()
          // setRsEdicao( ResetDados )

        } else {

          setMensagemState( {
            ...mensagemState,
            exibir: true,
            mensagem: rs.mensagem,
            tipo: MensagemTipo.Error,
            exibirBotao: true
          } )

        }

      } )

    }

  }

  return (
    <>
      <Condicional condicao={[StatusForm.EditandoEspecialidades].includes( statusForm )}>

        <Grid item xs={12}>
          <ShowText dados={dados} field='nome' label='Dentista' />
        </Grid>

        <Grid item xs={12}>

          <Container maxWidth="md" sx={{ mt: 5 }}>

            <Paper variant="outlined" sx={{ padding: 2 }}>

              <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

                <Grid item xs={12} sm={7}>

                  <ComboBox
                    dados={rsEdicao}
                    opcoes={EspecialidadesEcuroTypes}
                    field='idEcuroEspecialidade'
                    label='Especialidade'
                    setState={setRsEdicao}
                    disabled={statusForm === StatusForm.Excluindo}
                    erros={erros}
                    campoDescricao='descricao'
                    campoID='specialityId'
                  />

                </Grid>

                <Grid item xs={8} sm={3} sx={{ pl: { sm: 1 } }}>

                  <InputText
                    dados={rsEdicao}
                    field='minutoEntreConsultas'
                    label='Tempo InterConsultas'
                    setState={setRsEdicao}
                    disabled={statusForm === StatusForm.Excluindo}
                    erros={erros}
                    maxLength={3}
                    tipo='number'
                    mask='000'
                  />

                </Grid>

                <Grid item xs={4} sm={2} sx={{ mt: 4, pl: 1 }}>
                  <InputText
                    dados={rsEdicao}
                    field='ativo'
                    label='Ativo'
                    setState={setRsEdicao}
                    disabled={statusForm === StatusForm.Excluindo}
                    erros={erros}
                    tipo='checkbox'
                  />
                </Grid>

                {/*

                <Grid item xs={12}>

                  <InputText
                    dados={rsEdicao}
                    field='descricao'
                    label='Descrição'
                    setState={setRsEdicao}
                    disabled={statusForm === StatusForm.Excluindo}
                    erros={erros}
                    maxLength={50}
                  />

                </Grid>
  */}

                <Grid item xs={12} sx={{ mt: 3 }}>
                  <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => btIncluirEspecialidade()}>Incluir</Button>
                  <Button variant='contained' startIcon={<CloseIcon />} sx={{ py: 1 }} onClick={() => onCancelar()}>Cancelar</Button>
                </Grid>

                <Grid item xs={12} sx={{ mt: 3 }}>

                  <Typography component="h6" variant="h6" align="left">
                    Especialidade do Dentista
                  </Typography>

                  <DataTable
                    dados={rsDentistaEspecialidade}
                    cabecalho={Cabecalho}
                    acoes={[{ icone: 'delete', toolTip: 'Apagar', onAcionador: btExcluirEspecialidade }]}
                  />

                </Grid>

                <Condicional condicao={EMDESENVOLVIMENTO}>
                  <Grid item xs={12}>
                    <pre >
                      {JSON.stringify( rsEdicao, null, 4 )}
                    </pre>
                  </Grid>

                  <Grid item xs={12}>
                    <pre >
                      {JSON.stringify( erros, null, 4 )}
                    </pre>
                  </Grid>
                </Condicional>

              </Grid>

            </Paper>

          </Container>

        </Grid>

      </Condicional>

    </>
  )

}