import React, { useContext, useState, useEffect } from 'react'
import { ContextoGlobal, ContextoGlobalInterface } from '../../../GlobalStates/ContextoGlobal'
import BackEndAPI from '../../../Services/BackEndAPI'
import { Button, Divider, Grid, Link, Paper, Typography } from '@mui/material'
import InputText from '../../../DevComponents/InputText'
import { MensagemTipo } from '../../../GlobalStates/MensagemState'
import { EMDESENVOLVIMENTO, VERSAO_SISTEMA } from '../../../ImportBackend/Config/emDesenvolvimento'
import ClsUtils from 'zlib-utils'
import Condicional from '../../../Layout/Condicional'
import { useNavigate } from 'react-router-dom'
import { UsuarioLogadoInterface, VersaoTypeInterface } from '../../../ImportBackend/Interfaces/LoginInterfaces'
import MsgErroAplicacao from '../../../DevComponents/MsgErroAplicacao'
import { useTheme } from '@mui/material'
import LogoTop from '../../../Layout/LogoTop'
import ClsValidacao from '../../../Utils/ClsValidacao'

interface LoginInterface {
  login: string
  senha: string
}

export default function LoginPaciente () {

  const theme = useTheme()

  const dados = { senha: '', login: '' }

  const contextoGlobal = useContext( ContextoGlobal ) as ContextoGlobalInterface

  const { mensagemState, setMensagemState } = ( useContext( ContextoGlobal ) as ContextoGlobalInterface )
  const { loginState, setLoginState } = ( useContext( ContextoGlobal ) as ContextoGlobalInterface )

  const [usuarioState, setUsuarioState] = useState<LoginInterface>( dados )

  const [exibirSenhaState, setExibirSenha] = useState( false )

  const [versaoOK, setVersaoOK] = useState( true )

  const handleExibirSenha = () => {
    setExibirSenha( !exibirSenhaState )
  }

  const navigate = useNavigate()

  const clsApi = new BackEndAPI()

  const irPaginaInicial = ( rsUsuarioLogado: UsuarioLogadoInterface ) => {

    setLoginState( {
      ...loginState,
      permissoes: [],
      idUsuario: rsUsuarioLogado.idUsuario,
      logado: true,
      token: rsUsuarioLogado.token,
      nome: rsUsuarioLogado.nome,
      tipo: rsUsuarioLogado.tipo
    } )

    localStorage.setItem( 'token', rsUsuarioLogado.token )

    navigate( '/PacienteMenu' )
    /*
    if ( EMDESENVOLVIMENTO && PAGINAINICIALEMDESENVOLVIMENTO.length > 0 ) {
      navigate( PAGINAINICIALEMDESENVOLVIMENTO )
    } else {
      navigate( '/PacienteMenu' )
    }
    */

  }

  const abortController: AbortController = new AbortController()

  useEffect( () => {

    const query: string = `
        versao {
          versao
          desenvolvimento
        }
      `

    clsApi.query<VersaoTypeInterface>( query, 'versao', 'Verificando Versao...', contextoGlobal, abortController ).then( rs => {

      setMensagemState( { ...mensagemState, exibir: false } )

      if ( rs.desenvolvimento === EMDESENVOLVIMENTO && rs.versao === VERSAO_SISTEMA ) {
        setVersaoOK( true )
        logarPorToken()
      } else {
        // navigate( '/ErroVersao' )
        setVersaoOK( false )
      }

    } ).catch( () => {

      navigate( '/ErroAplicacao' )

    } )

    return () => {

      abortController.abort()

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [] )

  const logarPorToken = () => {

    const token = localStorage.getItem( 'token' );

    if ( token && token.length > 0 ) {

      const mutation: string = `
        pacienteLogarPorToken {
          idUsuario
          mensagem
          ok
          tipo
          token
          nome
        }
      `

      clsApi.mutation<UsuarioLogadoInterface>( mutation, 'pacienteLogarPorToken', 'Login Automático...', contextoGlobal ).then( rs => {

        if ( rs.ok && rs.token && rs.token.length > 0 && rs.nome && rs.nome.length > 0 ) {

          irPaginaInicial( rs )

        }

      } ).catch( () => {

        setMensagemState( {
          ...mensagemState,
          exibir: true,
          mensagem: 'Login Não Realizado!',
          tipo: MensagemTipo.Error,
          exibirBotao: true
        } )

      } )

    }

  }

  const logar = () => {

    if ( validarDados() ) {

      const mutation: string = `
        pacienteLogar(login: ${( new ClsUtils() ).ConverterEmGql( usuarioState )} ) {
          idUsuario
          mensagem
          ok
          tipo
          token
          nome
        }
      `

      clsApi.mutation<UsuarioLogadoInterface>( mutation, 'pacienteLogar', 'Logando', contextoGlobal ).then( rs => {

        if ( rs.ok && rs.token && rs.token.length > 0 && rs.nome && rs.nome.length > 0 ) {

          irPaginaInicial( rs )

        } else {
          setMensagemState( {
            ...mensagemState,
            exibir: true,
            mensagem: 'Verifique Usuário / Senha!',
            tipo: MensagemTipo.Error,
            exibirBotao: true
          } )
        }

      } ).catch( () => {

        setMensagemState( {
          ...mensagemState,
          exibir: true,
          mensagem: 'Login Não Realizado!',
          tipo: MensagemTipo.Error,
          exibirBotao: true
        } )

      } )

    }

  }

  const [erros, setErros] = useState( {} )

  const validarDados = ( validarSenha: boolean = true ): boolean => {

    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.eCPF( 'login', usuarioState, erros, retorno )
    if ( validarSenha ) retorno = clsValidacao.tamanho( 'senha', usuarioState, erros, retorno, false, 6, 20 )

    setErros( erros )

    return retorno

  }

  const esqueciSenha = () => {
    if ( validarDados( false ) ) {
      // pacienteEsqueciSenha

      const mutation: string = `
        pacienteEsqueciSenha(cpf: ${( new ClsUtils() ).ConverterEmGql( usuarioState.login )} ) {
          mensagem
          ok
        }
      `

      clsApi.mutation<UsuarioLogadoInterface>( mutation, 'pacienteEsqueciSenha', 'Enviando Link', contextoGlobal ).then( rs => {
        if ( rs.ok ) {
          setMensagemState( {
            cb: null,
            exibir: true,
            exibirBotao: true,
            mensagem: 'Link para Alteração de Senha Enviado por Email',
            tipo: MensagemTipo.Info,
            titulo: "Link Enviado Com Sucesso"
          } )
        } else {
          setMensagemState( {
            cb: null,
            exibir: true,
            exibirBotao: true,
            mensagem: rs.mensagem,
            tipo: MensagemTipo.Info,
            titulo: "Link Não Enviado!"
          } )
        }

      } )

    }
  }



  return (
    <>
      <div className='imagemFundoBranco'>

        <LogoTop />

        <Condicional condicao={!versaoOK}>
          <MsgErroAplicacao titulo='Versão Desatualizada!' mensagem='Atualize a tela com CTRL + F5 ou botão reload no seu celular!'>
            <Button variant='contained' onClick={() => window.location.reload()} sx={{ width: '100%', backgroundColor: '#ff6333' }}>Atualizar!</Button>
          </MsgErroAplicacao>
        </Condicional>
        <Condicional condicao={versaoOK}>
          <Grid
            container
            justifyContent='center'
            height='100vh'
          >

            <Grid item xs={12} sx={{ padding: 2, mt: 5 }} >

              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <div>

                  <Typography sx={{ ml: 5 }} variant='h5' fontWeight='bold' color='secondary'>
                    Acompanhe <br />suas consultas
                  </Typography>

                  <Divider sx={{ mt: 3, ml: 5, borderBottomWidth: '3px', opacity: '30%', backgroundColor: theme.palette.primary.main }} />

                </div>

              </Grid>

            </Grid>

            <Grid item xs={10} sm={8} md={6} lg={4} sx={{ mt: -10 }} >
              <Paper sx={{ backgroundColor: theme.cores.cinzaFundo, padding: 2 }}>

                <Grid>

                  <Grid item>
                    <InputText
                      dados={usuarioState}
                      field='login'
                      label='CPF'
                      setState={setUsuarioState}
                      mask="cpf"
                      erros={erros}
                    />
                  </Grid>

                  <Grid item>
                    <InputText
                      dados={usuarioState}
                      field='senha'
                      label='Senha'
                      type={exibirSenhaState ? "text" : "password"}
                      setState={setUsuarioState}
                      iconeEnd='visibility'
                      onClickIconeEnd={handleExibirSenha}
                      erros={erros}
                    />
                  </Grid>

                  <Grid item sx={{ mt: 3 }}>
                    <Link color='secondary' onClick={() => esqueciSenha()}>
                      * Esqueci a Senha
                    </Link>
                  </Grid>

                  <Grid item>
                    <Button
                      fullWidth
                      variant='contained'
                      onClick={() => logar()}
                      sx={{ mt: 5, borderRadius: 5 }}>Entrar
                    </Button>
                  </Grid>

                </Grid>

              </Paper>
            </Grid>
          </Grid>
        </Condicional >
      </div>

    </>
  )

}