import React, { useContext, useEffect, useState } from 'react'

// import { FormControlLabel, FormGroup, Grid, Paper, Switch, Typography, useTheme } from '@mui/material'
import { Grid, Paper, Typography, useTheme } from '@mui/material'

import './AgendamentoAvaliacao.css'
import InputText from '../../../DevComponents/InputText'
import ComboBox from '../../../DevComponents/ComboBox'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { BotaoConfirmar } from '../../../CssGlobal/Botoes'
import LogoTop from '../../../Layout/LogoTop'
import ClsValidacao from '../../../Utils/ClsValidacao'
import { ContextoGlobal, ContextoGlobalInterface } from '../../../GlobalStates/ContextoGlobal'
import { CidadesDisponiveisAgendamentoInterface, ClinicasDisponiveisAgendamentoInterface, HorariosDisponiveisAgendamentoInterface } from '../../../ImportBackend/Interfaces/AgendamentoInterfaces'
import { MensagemTipo } from '../../../GlobalStates/MensagemState'
import BackEndAPI from '../../../Services/BackEndAPI'
import { DateTime } from 'luxon'
import ExibirJSONDev from '../../../DevComponents/ExibirJSONDev'
import ClsUtils from 'zlib-utils'
import { DTFORMAT } from '../../../Utils/ClsFormatacao'
import { AgendamentoClienteSiteInputInterface, rsAgendamentoClienteSiteRealizadoInterface } from '../../../ImportBackend/Interfaces/AgendamentoClienteInterfaces'
import { ECURO_ID_ESPECIALIDADES } from '../../../ImportBackend/types/ConstantesDataTypes'

interface idDescricaoInterface {
  id: string
  descricao: string
}

export default function AgendamentoAvaliacao () {

  const contextoGlobal = ( useContext( ContextoGlobal ) as ContextoGlobalInterface )

  const { mensagemState, setMensagemState } = contextoGlobal
  // const [location, setLocation] = useState( { latitude: 0, longitude: 0 } )

  const clsApi: BackEndAPI = new BackEndAPI()

  const [dadosAuxiliares, setDadosAuxiliares] = useState( {
    usarGeolocalizacao: false,
    cidade: ''
  } )

  const [dados, setDados] = useState<AgendamentoClienteSiteInputInterface>( {
    nome: '',
    fone: '',
    whatsAPP: '',
    dataNascimento: '',
    idClinica: 0,
    data: '',
    horario: '',
  } )

  const [erros, setErros] = useState( {} )

  const [rsCidadesEClinicasDisponiveis, setRsCidadesEClinicasDisponiveis] = useState<CidadesDisponiveisAgendamentoInterface>(
    {
      cidades: [],
      clinicas: []
    }
  )

  const [rsCidadesDisponiveis, setRsCidadesDisponiveis] = useState<Array<idDescricaoInterface>>( [] )
  const [rsClinicasDisponiveis, setRsClinicasDisponiveis] = useState( Array<ClinicasDisponiveisAgendamentoInterface> )

  const [rsDia, setRsDia] = useState<Array<idDescricaoInterface>>( [] )
  const [rsHora, setRsHora] = useState<Array<idDescricaoInterface>>( [] )

  const [rsHorariosDisponiveis, setRsHorariosDisponiveis] = useState<Array<HorariosDisponiveisAgendamentoInterface>>()

  const navigate: NavigateFunction = useNavigate()

  const theme = useTheme()

  const validarDados = (): boolean => {

    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.naoVazio( 'nome', dados, erros, retorno )
    retorno = clsValidacao.eTelefone( 'fone', dados, erros, retorno )
    retorno = clsValidacao.eTelefone( 'whatsAPP', dados, erros, retorno )
    retorno = clsValidacao.eData( 'dataNascimento', dados, erros, retorno, false, 'Forneça uma Data Válida', 'Forneça uma Data Válida', DTFORMAT.USUARIO )
    retorno = clsValidacao.eData( 'data', dados, erros, retorno )
    retorno = clsValidacao.eHorario( 'horario', dados, erros, retorno, false )

    setErros( erros )

    return retorno

  }

  const btConfirmarAvaliacao = () => {
    if ( validarDados() ) {

      let tmpDados = { ...dados }

      tmpDados.dataNascimento = DateTime.fromFormat( tmpDados.dataNascimento, DTFORMAT.USUARIO ).toFormat( DTFORMAT.BANCO )

      const mutation: string = `
        agendarHorarioClienteSite(dados: ${( new ClsUtils() ).ConverterEmGql( tmpDados )}) {
          data
          hora
          ok
          mensagem
          diaSemana
          descricaoClinica        
        }
      `

      clsApi.mutation<rsAgendamentoClienteSiteRealizadoInterface>( mutation, 'agendarHorarioClienteSite', 'Agendando Horário...', contextoGlobal ).then( ( rsAgendamentoRealizado: rsAgendamentoClienteSiteRealizadoInterface ) => {

        // console.log( rsAgendamentoRealizado )

        if ( rsAgendamentoRealizado.ok ) {

          navigate( '/ConfirmaAgendamento', { state: { ...rsAgendamentoRealizado, nome: dados.nome } } )

        } else {
          setMensagemState( {
            mensagem: 'Não Foi Possível Realizar o Agendamento!',
            exibir: true,
            exibirBotao: true,
            titulo: 'Tente Novamente!',
            tipo: MensagemTipo.Error,
            cb: null
          } )
        }
      } )

    }

    //  navigate( '/ConfirmaAgendamento' )

  }

  /*
  const btHabilitarGeoLocalizacao = ( elemento: React.ChangeEvent<HTMLInputElement> ) => {
    if ( elemento.target.checked ) {

      const sucesso = ( pos: GeolocationPosition ) => {
        setLocation( {
          latitude: pos.coords.latitude,
          longitude: pos.coords.longitude
        } )
        setDadosAuxiliares( { ...dadosAuxiliares, usarGeolocalizacao: true } )
      }

      const erro = () => {
        setMensagemState( {
          mensagem: 'Não é Possível usar geolocalização!',
          exibir: true,
          exibirBotao: true,
          titulo: 'Localização Não Disponível!',
          tipo: MensagemTipo.Warning,
          cb: null
        } )
      }

      const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      };

      navigator.geolocation.getCurrentPosition( sucesso, erro, options );

    }
  }
  */

  useEffect( () => {
    const query: string = `
      getCidadesDisponiveisAgendamento {
        cidades
        clinicas {
          idClinica
          descricao
          cidade
        }
      }
    `

    clsApi.query<CidadesDisponiveisAgendamentoInterface>( query, 'getCidadesDisponiveisAgendamento', 'Pesquisando Cidades...', contextoGlobal ).then( tmpRsCidadesEClinicasDisponiveis => {

      let tmpRsCidadesDisponiveis: Array<idDescricaoInterface> = []

      tmpRsCidadesEClinicasDisponiveis.cidades.forEach( cidade => {
        tmpRsCidadesDisponiveis.push( {
          id: cidade,
          descricao: cidade
        } )

      } )

      setRsCidadesEClinicasDisponiveis( tmpRsCidadesEClinicasDisponiveis )

      setRsCidadesDisponiveis( tmpRsCidadesDisponiveis )

      if ( tmpRsCidadesEClinicasDisponiveis.cidades.length === 0 ) {

        setMensagemState( {
          ...mensagemState,
          exibir: true,
          mensagem: 'Não Há Dados para Agendamento. Consulte Suporte!.',
          titulo: 'Não é possível marcar consulta',
          tipo: MensagemTipo.Error,
          exibirBotao: true
        } )

      } /* else if ( tmpRsCidadesEClinicasDisponiveis.cidades.length === 1 ) {

        setDadosAuxiliares( { ...dadosAuxiliares, cidade: tmpRsCidadesEClinicasDisponiveis.cidades[0] } )

        if ( tmpRsCidadesEClinicasDisponiveis.clinicas.length === 1 ) {
          setRsClinicasDisponiveis( tmpRsCidadesEClinicasDisponiveis.clinicas )
          setDados( { ...dados, idClinica: tmpRsCidadesEClinicasDisponiveis.clinicas[0].idClinica } )
        }
        
      } */

    } ).catch( err => {
      setMensagemState( {
        ...mensagemState,
        exibir: true,
        mensagem: 'Erro ao Receber Permissões. Consulte Suporte.',
        titulo: 'Não é possível marcar consulta',
        tipo: MensagemTipo.Error,
        exibirBotao: true
      } )
    } )

    // eslint-disable-next-line
  }, [] )

  const updateHorariosDisponiveis = (
    rsHorariosDisponiveis: Array<HorariosDisponiveisAgendamentoInterface>,
    idClinica: number
  ) => {

    let rsDia: Array<idDescricaoInterface> = []

    rsHorariosDisponiveis.forEach( rsHorario => {

      if ( rsDia.findIndex( v => v.id === rsHorario.data ) < 0 ) {
        rsDia.push( { id: rsHorario.data, descricao: DateTime.fromFormat( rsHorario.data, DTFORMAT.BANCO ).toFormat( DTFORMAT.USUARIO ) } )
      }

    } )

    rsDia = rsDia.sort()

    setRsHorariosDisponiveis( rsHorariosDisponiveis )
    setRsDia( rsDia )
    setDados( { ...dados, idClinica: idClinica, data: '', horario: '' } )

  }

  const pesquisarHorariosDisponiveisDaClinica = ( rsClinica: ClinicasDisponiveisAgendamentoInterface ) => {

    if ( rsClinica && rsClinica.idClinica ) {

      const query: string = `
        getHorariosAvaliacaoDisponiveisPorClinica(idClinica: ${rsClinica.idClinica}, idEcuroEspecialidade: "${ECURO_ID_ESPECIALIDADES.AVALIACAO}") {
          data
          horario
          idDentistas
        }
      `

      clsApi.query<Array<HorariosDisponiveisAgendamentoInterface>>( query, 'getHorariosAvaliacaoDisponiveisPorClinica', 'Pesquisando Horários...', contextoGlobal ).then( rsHorariosDisponiveis => {
        updateHorariosDisponiveis( rsHorariosDisponiveis, rsClinica.idClinica )
      } )
    } else {
      updateHorariosDisponiveis( [], 0 )
    }
  }

  const alterarDiaAgendamento = ( rsDia: idDescricaoInterface ) => {

    let rsHora: Array<idDescricaoInterface> = []

    if ( rsDia && rsHorariosDisponiveis ) {

      rsHorariosDisponiveis.forEach( horariodisponivel => {

        if ( horariodisponivel.data === rsDia.id && rsHora.findIndex( v => v.id === horariodisponivel.horario ) < 0 ) {
          rsHora.push( { id: horariodisponivel.horario, descricao: horariodisponivel.horario } )
        }

      } )

    }

    setDados( { ...dados, data: rsDia ? rsDia.id : '', horario: '' } )
    setRsHora( rsHora )

  }

  const alterarWhatsAPP = ( v: string ) => {
    let tmpDados = { ...dados }

    if ( tmpDados.fone === tmpDados.whatsAPP || tmpDados.fone.length !== 15 ) {
      tmpDados.fone = v
    }

    tmpDados.whatsAPP = v

    setDados( tmpDados )
  }

  const onChangeCidade = ( rsCidade: idDescricaoInterface ) => {

    let tmpClinicasDisponiveis: Array<ClinicasDisponiveisAgendamentoInterface> = []

    if ( rsCidade && rsCidade.id.length > 0 ) {
      // console.log( 'Executando Filtro....', rsCidade )

      setDadosAuxiliares( { ...dadosAuxiliares, cidade: rsCidade.id } )

      rsCidadesEClinicasDisponiveis.clinicas.forEach( rsClinica => {
        if ( rsClinica.cidade === rsCidade.id ) {
          tmpClinicasDisponiveis.push( rsClinica )
        }
      } )


      if ( tmpClinicasDisponiveis.length === 1 ) {
        pesquisarHorariosDisponiveisDaClinica( tmpClinicasDisponiveis[0] )
      }


    }

    setRsClinicasDisponiveis( tmpClinicasDisponiveis )

  }

  return (
    <>
      <div className='imagemFundoBranco'>

        <LogoTop />

        <Grid
          container
          minHeight='100VH'
        >

          <Grid item xs={12} md={6} sx={{ margin: 'auto' }}>

            <Grid container>

              <Grid item xs={12} sx={{ padding: 2 }} >

                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >

                  <Typography variant='h5' fontWeight='bold' color='secondary'>
                    Agende agora <br />mesmo e experimente <br />nossa avaliação.
                  </Typography>

                </Grid>

              </Grid>

              <Grid item xs={12}>

                <Paper sx={{ maxWidth: '90%', padding: '5%', margin: '5%', backgroundColor: theme.cores.cinzaFundo }}>

                  <Grid container>

                    <Grid item xs={12}>
                      <InputText
                        dados={dados}
                        field='nome'
                        label='Nome Completo'
                        tipo='uppercase'
                        erros={erros}
                        setState={setDados}
                        maxLength={50}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <InputText
                        dados={dados}
                        field='whatsAPP'
                        label='Whats App'
                        erros={erros}
                        type='tel'
                        mask='tel'
                        onChange={( v: string ) => alterarWhatsAPP( v )}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <InputText
                        dados={dados}
                        field='fone'
                        label='Telefone'
                        erros={erros}
                        type='tel'
                        mask='tel'
                        setState={setDados}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <InputText
                        dados={dados}
                        type='tel'
                        field='dataNascimento'
                        label='Data Nascimento'
                        setState={setDados}
                        erros={erros}
                        mask='00/00/0000'
                      />
                    </Grid>
                    {/*
                <Condicional condicao={"geolocation" in navigator}>
                <Grid item xs={12} sx={{ mt: 3 }}>
                <FormGroup>
                <FormControlLabel name="txtUsarLocalizacao" control={<Switch checked={dadosAuxiliares.usarGeolocalizacao} onChange={( v ) => { btHabilitarGeoLocalizacao( v ) }} />} label="Usar localização e sugerir clínica mais próxima" />
                </FormGroup>
                </Grid>
                </Condicional>
  */}

                    <Grid item xs={12}>

                      <ComboBox
                        campoDescricao='descricao'
                        campoID='id'
                        opcoes={rsCidadesDisponiveis}
                        dados={dadosAuxiliares}
                        field='cidade'
                        label='Agendar Para a Cidade de:'
                        mensagemPadraoCampoEmBranco='Escolha a Cidade'
                        erros={erros}
                        onChange={( rs: idDescricaoInterface ) => onChangeCidade( rs )}
                      />

                    </Grid>

                    <Grid item xs={12}>

                      <ComboBox
                        campoDescricao='descricao'
                        campoID='idClinica'
                        opcoes={rsClinicasDisponiveis}
                        dados={dados}
                        field='idClinica'
                        label={rsClinicasDisponiveis.length > 1 ? 'Clínicas Disponíveis:' : 'Clínica Disponível'}
                        mensagemPadraoCampoEmBranco='Escolha a Clínica'
                        onChange={( v: ClinicasDisponiveisAgendamentoInterface ) => pesquisarHorariosDisponiveisDaClinica( v )}
                        erros={erros}
                      />

                    </Grid>

                    <Grid item xs={12}>
                      <ComboBox
                        campoDescricao='descricao'
                        campoID='id'
                        opcoes={rsDia}
                        dados={dados}
                        field='data'
                        label='Datas Disponíveis'
                        onChange={( v: idDescricaoInterface ) => alterarDiaAgendamento( v )}
                        mensagemPadraoCampoEmBranco='Escolha o dia'
                        erros={erros}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <ComboBox
                        campoDescricao='descricao'
                        campoID='id'
                        opcoes={rsHora}
                        dados={dados}
                        field='horario'
                        label='Hr. Disponível'
                        setState={setDados}
                        mensagemPadraoCampoEmBranco='Escolha o Horário'
                        erros={erros}
                      />
                    </Grid>

                    {/*

                  <Grid item xs={12} sx={{ textAlign: 'center', mb: 5, mt: 6 }}>
                    <BotaoDataHorario>Clique Aqui e Escolha a Data e Horário</BotaoDataHorario>
                  </Grid>
                  */}

                    <Grid item xs={12} sx={{ textAlign: 'center', mb: 5, mt: 3 }}>
                      <BotaoConfirmar onClick={() => btConfirmarAvaliacao()}>Confirmar Avaliação</BotaoConfirmar>
                    </Grid>

                  </Grid>

                </Paper>

              </Grid>

              <ExibirJSONDev oque={['rsCidadesEClinicasDisponiveis', rsCidadesEClinicasDisponiveis, 'Dados: ', dados, 'Erros', erros]} />

            </Grid>

          </Grid>

        </Grid>

      </div >

    </>
  )

}