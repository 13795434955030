import React, { useState, useEffect, useContext } from 'react'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Condicional from '../../Layout/Condicional'
import ShowText from '../../DevComponents/ShowText'
import { ClinicaFeriadoInterface, ClinicaInterface } from '../../ImportBackend/Interfaces/ClinicaInterfaces'
import BackEndAPI from '../../Services/BackEndAPI'
import { ContextoGlobal, ContextoGlobalInterface } from '../../GlobalStates/ContextoGlobal'
import { MensagemTipo } from '../../GlobalStates/MensagemState'
import DataTable, { DataTableCabecalhoInterface } from '../../DevComponents/DataTable'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { RespostaPadraoInterface } from '../../ImportBackend/Interfaces/PadraoInterfaces'
import Typography from '@mui/material/Typography'
import ClsValidacao from '../../Utils/ClsValidacao'
import ClsUtils from 'zlib-utils'
import InputText from '../../DevComponents/InputText'
import { StatusForm } from './Clinicas'
import { Container, Paper } from '@mui/material'
import ClsFormatacao from '../../Utils/ClsFormatacao'

interface PropsInterface {
  statusForm: StatusForm
  dados: ClinicaInterface
  onCancelar: () => void
}

export default function ClinicasFeriados ( { statusForm, dados, onCancelar }: PropsInterface ) {

  const ResetDados: ClinicaFeriadoInterface = {
    data: '',
    descricao: '',
    idClinica: dados.idClinica as number,
    idClinicaFeriado: 0
  }

  const clsApi = new BackEndAPI()

  const [rsClinicaFeriado, setRsClinicaFeriados] = useState<Array<ClinicaFeriadoInterface>>( [] )
  const [rsFeriado, setRsFeriado] = useState<ClinicaFeriadoInterface>( ResetDados )

  const [erros, setErros] = useState( {} )

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface
  const { mensagemState, setMensagemState } = contexto

  const clsFormatos: ClsFormatacao = new ClsFormatacao()

  const Cabecalho: Array<DataTableCabecalhoInterface> = [
    {
      campo: 'data',
      cabecalho: 'Data',
      alinhamento: 'left',
      format: ( v ) => clsFormatos.dataISOtoUser( v )
    },
    {
      campo: 'descricao',
      cabecalho: 'Descrição',
      alinhamento: 'left'
    }
  ]

  const btExcluirFeriado = ( rsClinicaFeriado: ClinicaFeriadoInterface ) => {

    const mutation = `
        excluirClinicaFeriado(idClinica: ${dados.idClinica as number}, data: "${rsClinicaFeriado.data}") {
          ok
          mensagem
        }
      `

    clsApi.mutation<RespostaPadraoInterface>( mutation, 'excluirClinicaFeriado', 'Excluindo Feriado...', contexto ).then( rs => {

      if ( rs.ok ) {

        setRsFeriado( { ...rsClinicaFeriado, idClinicaFeriado: 0 } )

        pesquisarClinicaFeriado()

      } else {

        setMensagemState( {
          ...mensagemState,
          exibir: true,
          mensagem: rs.mensagem,
          tipo: MensagemTipo.Error,
          exibirBotao: true
        } )

      }

    } )

  }

  const abortController: AbortController = new AbortController()

  const pesquisarClinicaFeriado = () => {
    const query = `
      getClinicaFeriados(idClinica: ${dados.idClinica as number}) {
        idClinica
        descricao
        data
        idClinicaFeriado
      }
    `

    clsApi.query<Array<ClinicaFeriadoInterface>>( query, 'getClinicaFeriados', 'Recebendo Cadastro de Feriados...', contexto, abortController ).then( rsClinicaFeriados => {
      setRsClinicaFeriados( rsClinicaFeriados )

    } ).catch( () => {

      setMensagemState( {
        ...mensagemState,
        titulo: 'Erro! Consulte Suporte!',
        exibir: true,
        mensagem: 'Erro ao Consultar Feriados da Clínica!',
        tipo: MensagemTipo.Error,
        exibirBotao: true
      } )

    } )
  }

  useEffect( () => {

    if ( [StatusForm.EditandoFeriados].includes( statusForm ) ) {

      pesquisarClinicaFeriado()

    }

    return () => {

      abortController.abort()

    }

    //eslint-disable-next-line
  }, [dados, statusForm] )

  const validarDados = () => {

    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.eData( 'data', rsFeriado, erros, retorno )
    retorno = clsValidacao.naoVazio( 'descricao', rsFeriado, erros, retorno )

    setErros( erros )

    return retorno
  }

  const btIncluirFeriado = () => {

    if ( validarDados() ) {

      const mutation = `
        incluirClinicaFeriado(dados: ${( new ClsUtils() ).ConverterEmGql( { ...rsFeriado, idClinica: dados.idClinica } )}) {
          ok
          mensagem
        }
      `

      clsApi.mutation<RespostaPadraoInterface>( mutation, 'incluirClinicaFeriado', 'Incluindo Feriado...', contexto ).then( rs => {

        if ( rs.ok ) {

          pesquisarClinicaFeriado()
          setRsFeriado( ResetDados )

        } else {

          setMensagemState( {
            ...mensagemState,
            exibir: true,
            mensagem: rs.mensagem,
            tipo: MensagemTipo.Error,
            exibirBotao: true
          } )

        }

      } )

    }

  }

  return (
    <>
      <Condicional condicao={[StatusForm.EditandoFeriados].includes( statusForm )}>


        <Grid item xs={12}>

          <Container maxWidth="sm" sx={{ mt: 5 }}>

            <Paper variant="outlined" sx={{ padding: 2 }}>

              <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

                <Grid item xs={12}>
                  <ShowText dados={dados} field='descricao' label='Clínica' />
                </Grid>

                <Grid item xs={12}>

                  <InputText
                    dados={rsFeriado}
                    field='data'
                    label='Data'
                    setState={setRsFeriado}
                    disabled={statusForm === StatusForm.Excluindo}
                    erros={erros}
                    tipo='date'
                  />

                </Grid>

                <Grid item xs={12}>

                  <InputText
                    dados={rsFeriado}
                    field='descricao'
                    label='Descrição'
                    setState={setRsFeriado}
                    disabled={statusForm === StatusForm.Excluindo}
                    erros={erros}
                    maxLength={50}
                  />

                </Grid>

                <Grid item xs={12} sx={{ mt: 3 }}>
                  <Button variant='contained' startIcon={<CheckIcon />} sx={{ my: 1, py: 1, mr: 2 }} onClick={() => btIncluirFeriado()}>Incluir Feriado</Button>
                  <Button variant='contained' startIcon={<CloseIcon />} sx={{ py: 1 }} onClick={() => onCancelar()}>Cancelar</Button>
                </Grid>

                <Grid item xs={12} sx={{ mt: 3 }}>

                  <Typography component="h6" variant="h6" align="left">
                    Feriados Locais Para Esta Clínica
                  </Typography>

                  <DataTable
                    dados={rsClinicaFeriado}
                    cabecalho={Cabecalho}
                    acoes={[{ icone: 'delete', toolTip: 'Apagar Feriado', onAcionador: btExcluirFeriado }]}
                  />

                </Grid>

              </Grid>

            </Paper>

          </Container>

        </Grid>

      </Condicional>

    </>
  )

}