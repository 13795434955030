import React, { useContext } from 'react'

import { Button, Grid, Typography, Divider, Stack, Icon } from '@mui/material'

import { styled } from '@mui/material/styles'

import Carousel from 'react-material-ui-carousel'

import './Home.css'
import LogoBottom from '../../../Layout/LogoBottom'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { THEME } from '../../../Config/Theme'
import useWindowDimensions from '../../../DevComponents/useWindowDimensions'
import Condicional from '../../../Layout/Condicional'
import { ContextoGlobal, ContextoGlobalInterface } from '../../../GlobalStates/ContextoGlobal'
import { TipoUsuario } from '../../../ImportBackend/Interfaces/RolesInterfaces'

const BotaoStyled = styled( Button )`
  width: 190px;
  font-weight: bold;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 15px;  
  background-color: white;
  color: ${THEME.palette.secondary.main};;
  &:hover {
    background-color: ${THEME.palette.primary.main};
    color: white;
  }
`;

export default function Home () {

  const navigate: NavigateFunction = useNavigate()

  const imagens = [{
    arquivo: 'banner01.png',
    mensagem: 'Vamos Sorrir 01'
  },
  {
    arquivo: 'banner02.png',
    mensagem: 'Vamos Sorrir 02'
  },
  {
    arquivo: 'banner03.png',
    mensagem: 'Vamos Sorrir 03'
  }]

  const btNaoSouPaciente = () => {
    navigate( '/AgendamentoAvaliacao' )
  }

  const btSouPaciente = () => {
    navigate( '/LoginPaciente' )
  }

  const { width, height } = useWindowDimensions();

  const contexto: ContextoGlobalInterface = useContext( ContextoGlobal ) as ContextoGlobalInterface

  return (
    <>
      <Condicional condicao={contexto.loginState.tipo !== TipoUsuario.SISTEMA}>

        <div className='fundoHome'>

          <Grid
            container
            minHeight='100VH'
          >

            <Grid item xs={12} sx={{ textAlign: 'center', mb: 1 }}>

              <Carousel
                fullHeightHover={false}
                swipe={false}
                sx={{ margin: 'auto' }}
              >
                {
                  imagens.map( ( imagem, index ) =>
                    <img alt={imagem.mensagem}
                      width={width <= 450 ? width : 450}
                      height={width <= 450 ? width : 450}
                      src={'/imagens/home/bannerhome/'.concat( imagem.arquivo )}
                      key={index}>
                    </img>
                  )
                }
              </Carousel>
            </Grid>

            <Grid item xs={12} sx={{ textAlign: 'center', mb: 1 }}>
              <Stack spacing={2} sx={{ mt: 1, mb: 1, alignItems: 'center', }}>

                <img alt='Vamos Sorrir Apontador'
                  className='imgApontador'
                  src={'/imagens/icones/apontador.png'}>
                </img>

                <div>
                  <Typography color='white' sx={{ fontSize: '18px', fontFamily: 'Roboto' }}>
                    Agende Agora a Sua
                  </Typography>
                  <Typography color='white' sx={{ fontWeight: 'bold', fontSize: '35px', fontFamily: 'Roboto' }}>
                    Avaliação
                  </Typography>

                  <Divider color='white' sx={{ height: '2px' }} />

                </div>
                <BotaoStyled onClick={() => btNaoSouPaciente()}>Não Sou Paciente!</BotaoStyled>
                <BotaoStyled onClick={() => btSouPaciente()}>Já Sou Paciente!</BotaoStyled>
              </Stack>

            </Grid>

          </Grid>

          <LogoBottom />

        </div >

      </Condicional>

    </>
  )

}