import React, { useContext, useState } from 'react'
import { ContextoGlobal, ContextoGlobalInterface } from '../GlobalStates/ContextoGlobal'
import BackEndAPI from '../Services/BackEndAPI'
import { Button, Container, Grid, IconButton, Paper, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close'
import InputText from '../DevComponents/InputText'
import { relFaturamentoInputInterface, rsRelFaturamentoInterface } from '../ImportBackend/Interfaces/RelatoriosInterfaces'
import SelectMultiplo from '../DevComponents/SelectMultiplo'
import ExibirJSONDev from '../DevComponents/ExibirJSONDev'
import PesquisarTabela from '../DevComponents/PesquisarTabela'
import { UsuarioInterface } from '../ImportBackend/Interfaces/UsuarioInterfaces'
import { AppointmentStatusEcuroTypes } from '../ImportBackend/types/ConstantesDataTypes'
import DataTable, { DataTableCabecalhoInterface } from '../DevComponents/DataTable'
import ClsFormatacao, { DTFORMAT } from '../Utils/ClsFormatacao'
import { clsUtils } from 'zlib-utils'
import ClsValidacao from '../Utils/ClsValidacao'
import Condicional from '../Layout/Condicional'
import ClsExportDataTableCSV from '../Utils/ClsExportDataTableCSV'

export default function RelFaturamento () {

  const clsFormatos: ClsFormatacao = new ClsFormatacao()

  const Cabecalho: Array<DataTableCabecalhoInterface> = [
    {
      campo: 'nome',
      cabecalho: 'Nome',
      alinhamento: 'left'
    },
    {
      campo: 'cpf',
      cabecalho: 'CPF',
      alinhamento: 'left'
    },
    {
      campo: 'nomeUsuarioCadastro',
      cabecalho: 'Cadastrado por',
      alinhamento: 'left'
    },
    {
      campo: 'clienteBaseEcuro',
      cabecalho: 'Base Ecuro',
      alinhamento: 'left',
      format: ( v ) => parseInt( v ) ? 'Sim' : 'Não'
    },
    {
      campo: 'nomeUsuarioFaturamento',
      cabecalho: 'Usuário',
      alinhamento: 'left'
    },
    {
      campo: 'descricaoClinica',
      cabecalho: 'Clínica',
      alinhamento: 'left'
    },
    {
      campo: 'vr_pagamento',
      cabecalho: 'Valor',
      alinhamento: 'left',
      format: ( v ) => clsFormatos.currency( v )
    },
    {
      campo: 'dataAtualizacao',
      cabecalho: 'Data Atualização',
      alinhamento: 'left',
      format: ( v, rs ) => `${v ? clsFormatos.fromMills( parseInt( v ), DTFORMAT.USUARIO ) : ''}`
    },
  ]

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface

  const abortController: AbortController = new AbortController()

  const navigate = useNavigate()

  const clsApi = new BackEndAPI()

  const ResetPesquisa: relFaturamentoInputInterface = {
    usuario: 0,
    unidadeOrganizacional: 0,
    inicio: '',
    termino: '',
    rsClinicas: []
  }

  const [rsRelFaturamento, setRsRelFaturamento] = useState<Array<rsRelFaturamentoInterface>>( [] )

  const [rsPesquisa, setRsPesquisa] = useState<relFaturamentoInputInterface>( ResetPesquisa )

  const [erros, setErros] = useState<Record<string, string>>( {} )

  const btFechar = () => {
    navigate( '/' )
  }

  const validarDados = (): boolean => {

    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.eData( 'inicio', rsPesquisa, erros, retorno, true )
    retorno = clsValidacao.eData( 'termino', rsPesquisa, erros, retorno, true )

    if ( rsPesquisa.inicio.length > 0 && rsPesquisa.termino.length > 0 && retorno && rsPesquisa.inicio > rsPesquisa.termino ) {
      erros.termino = 'Data final não pode ser menor que a data inicial'
      retorno = false
    }

    setErros( erros )

    return retorno

  }

  const btPesquisar = () => {

    if ( validarDados() ) {

      const query: string = `
        relFaturamento (pesquisa: ${clsUtils.ConverterEmGql( { ...rsPesquisa, rsClinicas: rsPesquisa.rsClinicas.map( ( v: any ) => v.idClinica ) } )}) {
          nome
          cpf
          idUsuarioCadastro
          nomeUsuarioCadastro
          clienteBaseEcuro
          idUsuarioFaturamento
          nomeUsuarioFaturamento
          idClinica
          descricaoClinica
          vr_pagamento
          dataAtualizacao
        }
      `

      clsApi.query<Array<rsRelFaturamentoInterface>>( query, 'relFaturamento', 'Gerando Relatório...', contexto, abortController ).then( ( rs ) => {

        setRsRelFaturamento( rs )

      } )

      return () => {

        abortController.abort()

      }

    }

  }

  const btDownload = () => {
    const clsExportaCSV: ClsExportDataTableCSV = new ClsExportDataTableCSV()

    clsExportaCSV.exportCSV( 'Faturamentos', rsRelFaturamento, Cabecalho )
  }

  return (
    <>
      <Container maxWidth="xl" sx={{ mt: 5 }}>

        <Paper variant="outlined" sx={{ padding: 2 }}>
          <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography component="h5" variant="h5" align="left">
                Faturamentos
                <Typography variant="body2" gutterBottom>

                </Typography>
              </Typography>

              <IconButton onClick={() => btFechar()}>
                <CloseIcon />
              </IconButton>
            </Grid>

            <Grid item xs={12} >

              <SelectMultiplo
                fieldsRetornoObjeto={['idClinica', 'descricao']}
                dados={rsPesquisa}
                field='rsClinicas'
                fieldIdSelect='idClinica'
                fieldSelect='descricao'
                label='Clínicas'
                setState={setRsPesquisa}
                pesquisaInicial={{
                  mensagem: 'Pesquisando Clínicas',
                  tipo: 'Query',
                  pesquisa: 'getClinicas {idClinica, descricao}',
                  objRetorno: 'getClinicas'
                }
                }
              />

            </Grid>

            <Grid item xs={12} md={3}>

              <InputText
                dados={rsPesquisa}
                field='inicio'
                label='Início'
                type='date'
                setState={setRsPesquisa}
                erros={erros}
              />

            </Grid>

            <Grid item xs={12} md={3} sx={{ pl: { md: 1 } }}>

              <InputText
                dados={rsPesquisa}
                field='termino'
                label='Término'
                type='date'
                setState={setRsPesquisa}
                erros={erros}
              />

            </Grid>

            <Grid item xs={12} md={3} sx={{ pl: { md: 1 } }}>

              <PesquisarTabela<UsuarioInterface>
                field='idUnidadeOrganizacional'
                fieldSet='unidadeOrganizacional'
                label='Unidade Organizacional'
                dados={rsPesquisa}
                setState={setRsPesquisa}
                campoQueryPesquisaID='idUnidadeOrganizacional'
                campoQueryPesquisa='pesquisa'
                camposRetornoQueryPesquisa='{idUnidadeOrganizacional, descricao}'
                campoLabelQueryPesquisa='descricao'
                nomeQueryPesquisa='getUnidadesOrganizacionais'
                nomeQueryPesquisaID='getUnidadeOrganizacionalPorId'
                mensagemPesquisa='Procurando Usuarios...'
                disabled={rsPesquisa.usuario !== 0}
                valorAtribuirLimpar={0}
              />

            </Grid>

            <Grid item xs={12} md={3} sx={{ pl: { md: 1 } }}>

              <PesquisarTabela<UsuarioInterface>
                field='idUsuario'
                fieldSet='usuario'
                label='Usuário'
                dados={rsPesquisa}
                setState={setRsPesquisa}
                campoQueryPesquisaID='idUsuario'
                campoQueryPesquisa='pesquisa'
                camposRetornoQueryPesquisa='{idUsuario, nome}'
                campoLabelQueryPesquisa='nome'
                nomeQueryPesquisa='getUsuarios'
                nomeQueryPesquisaID='getUsuarioPorId'
                mensagemPesquisa='Procurando Usuarios...'
                disabled={rsPesquisa.unidadeOrganizacional !== 0}
                valorAtribuirLimpar={0}
              />

            </Grid>

            <Grid item xs={12} textAlign='right' sx={{ mt: 3 }}>
              <Button variant='contained' onClick={() => { btPesquisar() }}>Pesquisar</Button>
            </Grid>

            <Grid item xs={12} sx={{ mt: 3 }}>
              <DataTable dados={rsRelFaturamento} cabecalho={Cabecalho} />
            </Grid>
            <Condicional condicao={rsRelFaturamento.length > 0}>
              <Grid item xs={12} textAlign='right' sx={{ mt: 3 }}>
                <Button variant='contained' onClick={() => { btDownload() }}>Download</Button>
              </Grid>
            </Condicional>

          </Grid>

        </Paper>

      </Container >

      <ExibirJSONDev oque={['Pesquisa', rsPesquisa, 'Status Ecuro', AppointmentStatusEcuroTypes]} />

    </>
  )

}