import React, { useState, useEffect, useContext } from 'react'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { UsuarioInterface } from '../../ImportBackend/Interfaces/UsuarioInterfaces'
import Condicional from '../../Layout/Condicional'
import { StatusForm } from './Sistema/Usuarios'
import CloseIcon from '@mui/icons-material/Close'
import ShowText from '../../DevComponents/ShowText'
import { ClinicaInterface } from '../../ImportBackend/Interfaces/ClinicaInterfaces'
import BackEndAPI from '../../Services/BackEndAPI'
import { ContextoGlobal, ContextoGlobalInterface } from '../../GlobalStates/ContextoGlobal'
import { MensagemTipo } from '../../GlobalStates/MensagemState'
import DataTable, { DataTableCabecalhoInterface } from '../../DevComponents/DataTable'
import SearchText from '../../DevComponents/SearchText'
import { RespostaPadraoInterface } from '../../ImportBackend/Interfaces/PadraoInterfaces'
import Typography from '@mui/material/Typography'

interface PropsInterface {
  statusForm: StatusForm
  dados: UsuarioInterface
  clsApi: BackEndAPI
  onCancelar: () => void
}

export default function UsuariosClinicas ( { clsApi, statusForm, dados, onCancelar }: PropsInterface ) {

  const [rsClinicas, setRsClinicas] = useState<Array<ClinicaInterface>>( [] )

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface
  const { mensagemState, setMensagemState } = contexto

  const Cabecalho: Array<DataTableCabecalhoInterface> = [
    {
      campo: 'descricao',
      cabecalho: 'Descrição',
      alinhamento: 'left'
    }
  ]

  const btExcluirClinica = ( rsClinica: ClinicaInterface ) => {

    const mutation = `
        excluirClinicaUsuario(idUsuario: ${dados.idUsuario}, idClinica: ${rsClinica.idClinica}) {
          ok
          mensagem
        }
      `

    clsApi.mutation<RespostaPadraoInterface>( mutation, 'excluirClinicaUsuario', 'Excluindo Clinica...', contexto ).then( rs => {

      if ( rs.ok ) {

        pesquisarUsuarioClinicas()

      } else {

        setMensagemState( {
          ...mensagemState,
          exibir: true,
          mensagem: rs.mensagem,
          tipo: MensagemTipo.Error,
          exibirBotao: true
        } )

      }

    } )

  }

  const abortController: AbortController = new AbortController()

  const pesquisarUsuarioClinicas = () => {
    const query = `
      getUsuarioClinicasPermitidasPorIdUsuario(idUsuario: ${dados.idUsuario}) {
        idClinica
        descricao
      }
    `

    clsApi.query<Array<ClinicaInterface>>( query, 'getUsuarioClinicasPermitidasPorIdUsuario', 'Recebendo Clinicas...', contexto, abortController ).then( rsClinicas => {
      setRsClinicas( rsClinicas )

    } ).catch( () => {

      setMensagemState( {
        ...mensagemState,
        titulo: 'Erro! Consulte Suporte!',
        exibir: true,
        mensagem: 'Erro ao Consultar Clinicas de Usuários!',
        tipo: MensagemTipo.Error,
        exibirBotao: true
      } )

    } )
  }

  useEffect( () => {

    if ( [StatusForm.EditandoClinicas].includes( statusForm ) ) {

      pesquisarUsuarioClinicas()

    }

    return () => {

      abortController.abort()

    }

    //eslint-disable-next-line
  }, [dados, statusForm] )

  const [novoClinica, setNovoClinica] = useState<ClinicaInterface | null>( null )

  const novoClinicaSelecionado = ( v: ClinicaInterface | null ) => {

    setNovoClinica( v )

    if ( v ) {

      const mutation = `
        incluirClinicaUsuario(idUsuario: ${dados.idUsuario}, idClinica: ${v.idClinica}) {
          ok
          mensagem
        }
      `

      clsApi.mutation<RespostaPadraoInterface>( mutation, 'incluirClinicaUsuario', 'Incluindo Clinica...', contexto ).then( rs => {

        if ( rs.ok ) {

          pesquisarUsuarioClinicas()

        } else {

          setMensagemState( {
            ...mensagemState,
            exibir: true,
            mensagem: rs.mensagem,
            tipo: MensagemTipo.Error,
            exibirBotao: true
          } )

        }

      } )

    }

  }

  return (
    <>
      <Condicional condicao={[StatusForm.EditandoClinicas].includes( statusForm )}>
        <Grid item xs={12}>
          <ShowText dados={dados} field='nome' label='Nome' />
        </Grid>

        <Condicional condicao={dados.permitirTodasClinicas}>
          <Grid item xs={12}>
            <Typography component="h6" variant="h6" align="center" sx={{ mt: 5 }}>
              Usuário com Acesso a todas as Clinicas.
            </Typography>
          </Grid>

        </Condicional>
        <Condicional condicao={!dados.permitirTodasClinicas}>

          <Grid item xs={12}>

            <SearchText
              onChange={novoClinicaSelecionado}
              objQuery='{idClinica, descricao}'
              campoPesquisa='descricao'
              campoID='idClinica'
              label='Adicionar Clinica'
              value={novoClinica}
              mensagemQuery='Procurando Clinicas...'
              campoQuery='pesquisa'
              nomeQuery='getClinicas'
            />

          </Grid>

          <Grid item xs={12} sx={{ mt: 3 }}>

            <Typography component="h6" variant="h6" align="left">
              Clinicas Ativas Para Este Usuário
            </Typography>

            <DataTable
              dados={rsClinicas}
              cabecalho={Cabecalho}
              acoes={[{ icone: 'delete', toolTip: 'Cancelar Permissão', onAcionador: btExcluirClinica }]}
            />

          </Grid>

        </Condicional>

        <Grid item xs={12} sx={{ mt: 3, textAlign: 'right' }}>

          <Button variant='contained' startIcon={<CloseIcon />} sx={{ py: 1 }} onClick={() => onCancelar()}>Cancelar</Button>

        </Grid>

      </Condicional>
    </>
  )

}