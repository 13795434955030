import React from 'react'
import ReactDOM from 'react-dom/client'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'

import { RouterProvider } from 'react-router-dom'
import { router } from './Router/Router'

import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client'
import { ENDPOINT_GRAPHQL } from './ImportBackend/Config/emDesenvolvimento'

import { setContext } from '@apollo/client/link/context'

import './CssGlobal/CssDebug.css'

import './CssGlobal/CssPrint.css'
import { ThemeProvider } from '@mui/material'
import { THEME } from './Config/Theme'

import './CssGlobal/CssGlobal.css'

// import '@fontsource/roboto/300.css'
// import '@fontsource/roboto/400.css'
// import '@fontsource/roboto/500.css'
// import '@fontsource/roboto/700.css'

/*
const getToken = () => {
  const token = localStorage.getItem('token')
  return token ? `Bearer ${token}` : ''
}

const client = new ApolloClient({
  uri: `${graphQLServerURL}/graphql`,
  request: (operation) => {
    operation.setContext({
      headers: {
        authorization: getToken(),
      },
    })
  },
})
*/

const httpLink = createHttpLink( {
  uri: ENDPOINT_GRAPHQL,
} )


const getToken = () => {
  const token = localStorage.getItem( 'token' )
  return token
}

const authLink = setContext( ( _, { headers } ) => {
  // get the authentication token from local storage if it exists

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: getToken() ? `Bearer ${getToken()}` : "",
    }
  }
} )

const client = new ApolloClient( {
  link: authLink.concat( httpLink ),
  cache: new InMemoryCache( { addTypename: false, } ),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache'
    },
    mutate: {
      fetchPolicy: 'no-cache'
    }
  }
} )

/*
const client = new ApolloClient( {
  uri: ENDPOINT_GRAPHQL,
  cache: new InMemoryCache(),
} )
*/

const root = ReactDOM.createRoot(
  document.getElementById( 'root' ) as HTMLElement
)

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <ThemeProvider theme={THEME}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </ApolloProvider>
  </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

/*
function sendToAnalytics ( { id, name, value } ) {
  ga( 'send', 'event', {
    eventCategory: 'Web Vitals',
    eventAction: name,
    eventValue: Math.round( name === 'CLS' ? value * 1000 : value ), // values must be integers
    eventLabel: id, // id unique to current page load
    nonInteraction: true, // avoids affecting bounce rate
  } )
}

reportWebVitals( sendToAnalytics )
*/
reportWebVitals()
