import React, { useContext } from 'react'
import { ContextoGlobal, ContextoGlobalInterface } from '../../GlobalStates/ContextoGlobal'
import BackEndAPI from '../../Services/BackEndAPI'
import Grid from '@mui/material/Grid'
import { Button, Container, IconButton, Paper, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import CloseIcon from '@mui/icons-material/Close'

export default function DownloadBaseEcuro () {

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface

  const navigate = useNavigate()

  const clsApi = new BackEndAPI()

  const btDownloadEcuro = () => {

    const query: string = 'getMovimentacaoCadastrosAPP'
    clsApi.query<string>( query, 'getMovimentacaoCadastrosAPP', 'Recebendo Planilha...', contexto ).then( rs => {

      const link = document.createElement( 'a' );
      link.href = URL.createObjectURL( new Blob( [rs], { type: 'text/csv;charset=utf-8' } ) );
      link.download = 'MovimentacaoEcuro';
      link.click();
      URL.revokeObjectURL( link.href );

    } )

  }

  return (
    <>
      <Container maxWidth="sm" sx={{ mt: 10 }}>

        <Paper variant="outlined" sx={{ padding: 3 }}>

          <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 5 }}>
              <Typography component="h5" variant="h5" align="left">
                Ecuro
                <Typography variant="body2" gutterBottom>
                  Download da Movimentação dos Cadastros Realizados pelo APP
                </Typography>
              </Typography>

              <IconButton onClick={() => navigate( '/' )}>
                <CloseIcon />
              </IconButton>
            </Grid>

            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <Button variant='contained' onClick={() => btDownloadEcuro()}>Download Movimentacao Ecuro</Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </>
  )

}