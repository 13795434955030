import React, { useContext } from 'react'
import LogoTop from '../../../Layout/LogoTop'
import { Avatar, Button, Grid, Paper, styled, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import './Paciente.css'
import '../Home/Home.css'
import CabecalhoTela from '../Componentes/CabecalhoTela';
import { RespostaPadraoInterface } from '../../../ImportBackend/Interfaces/PadraoInterfaces';
import { ClsLogout } from '../../Controller/LogoutCls';
import { ContextoGlobal, ContextoGlobalInterface } from '../../../GlobalStates/ContextoGlobal';
import BackEndAPI from '../../../Services/BackEndAPI';

const AvatarImagem = styled( Avatar )`
  width: 50px;
  height: 50px;
  border-width: 3px;
  border-style: solid;
  border-color: #0FB1AD;
  border-radius: 15px;
`;

export default function PacienteMenu () {

  const contextoGlobal = useContext( ContextoGlobal ) as ContextoGlobalInterface

  const { setLoginState } = ( useContext( ContextoGlobal ) as ContextoGlobalInterface )
  const { setLayoutState } = useContext( ContextoGlobal ) as ContextoGlobalInterface

  const abortController: AbortController = new AbortController()

  const theme = useTheme()

  const nav = useNavigate()

  const clsApi = new BackEndAPI()

  const btOpcao = ( caminho: string ) => {
    nav( caminho )
  }

  const logoutPaciente = () => {

    const mutation: string = `
      pacienteLogout {
        ok
        mensagem
      }
    `

    clsApi.mutation<RespostaPadraoInterface>( mutation, 'pacienteLogout', 'Saindo do APP...', contextoGlobal, abortController ).then( rs => {

      if ( rs.ok ) {

        ( new ClsLogout() ).efetuarLogout( setLoginState, setLayoutState, nav )

      }

    } )

  }

  return (
    <>
      <div className='imagemFundoBranco'>
        <LogoTop />

        <Grid
          container
          minHeight='100VH'
        >

          <Grid item xs={12} md={6} sx={{ margin: 'auto' }}>

            <Grid container>

              <Grid item xs={12} mb={5}>

                <CabecalhoTela titulo={'Para qual serviço você<br />deseja atendimento?'} />

              </Grid>

              <Grid item xs={12} sx={{ mb: 5 }}>

                <Paper sx={{
                  backgroundColor: theme.cores.cinzaFundo,
                  padding: 2,
                  mb: 2,
                  maxWidth: '90%',
                  margin: 'auto'
                }}>

                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >

                    <Grid item xs={2} my={1}>
                      <AvatarImagem variant='rounded' alt="Vamos Sorrir - Minhas Consultas" src="/imagens/icones/minhasconsultas.png" />
                    </Grid>

                    <Grid item xs={10} my={1} pl={2}>
                      <Button
                        fullWidth
                        variant='contained'
                        onClick={() => btOpcao( '/ConsultarConsultas' )}
                        sx={{ borderRadius: 2, backgroundColor: theme.palette.secondary.main }}>
                        Minhas Consultas
                      </Button>
                    </Grid>

                    <Grid item xs={2} my={1}>
                      <AvatarImagem variant='rounded' alt="Vamos Sorrir - Orto" src="/imagens/icones/orto.png" />
                    </Grid>

                    <Grid item xs={10} my={1} pl={2}>
                      <Button
                        fullWidth
                        variant='contained'
                        onClick={() => btOpcao( '/AgendarManutencao' )}
                        sx={{ borderRadius: 2, backgroundColor: theme.palette.secondary.main }}>
                        Manutenção de Orto
                      </Button>
                    </Grid>

                    <Grid item xs={2} my={1}>
                      <AvatarImagem variant='rounded' alt="Vamos Sorrir - Outras Especialidades" src="/imagens/icones/outrasespecialidades.png" />
                    </Grid>

                    <Grid item xs={10} my={1} pl={2}>
                      <Button
                        fullWidth
                        variant='contained'
                        onClick={() => btOpcao( '/MarcarOutrasEspecialidades' )}
                        sx={{ borderRadius: 2, backgroundColor: theme.palette.secondary.main }}>
                        Outras Especialidades
                      </Button>
                    </Grid>

                    <Grid item xs={2} my={1}>
                      <AvatarImagem variant='rounded' alt="Vamos Sorrir - Novo Orçamento" src="/imagens/icones/novoorcamento.png" />
                    </Grid>

                    <Grid item xs={10} my={1} pl={2}>
                      <Button
                        fullWidth
                        variant='contained'
                        onClick={() => btOpcao( '/NovoOrcamento' )}
                        sx={{ borderRadius: 2, backgroundColor: theme.palette.secondary.main }}>
                        Novo Orçamento
                      </Button>
                    </Grid>

                    <Grid item xs={2} my={1}>
                      <AvatarImagem variant='rounded' alt="Vamos Sorrir - Alterar Senha" src="/imagens/icones/alterarsenha.png" />
                    </Grid>

                    <Grid item xs={10} my={1} pl={2}>
                      <Button
                        fullWidth
                        variant='contained'
                        onClick={() => btOpcao( '/TrocarSenha' )}
                        sx={{ borderRadius: 2, backgroundColor: theme.palette.secondary.main }}>
                        Trocar Minha Senha
                      </Button>
                    </Grid>

                    <Grid item xs={2} my={1}>
                      <AvatarImagem variant='rounded' alt="Vamos Sorrir - Sair APP" src="/imagens/icones/sair.png" />
                    </Grid>

                    <Grid item xs={10} my={1} pl={2}>
                      <Button
                        fullWidth
                        variant='contained'
                        onClick={() => logoutPaciente()}
                        sx={{ borderRadius: 2, backgroundColor: theme.palette.secondary.main }}>
                        Encerrar Sessão APP
                      </Button>
                    </Grid>

                  </Grid>

                </Paper>

              </Grid>

              <Grid item xs={12} sx={{ textAlign: 'center' }}>

                <img alt='Vamos Sorrir Publicidade'
                  src="/imagens/paciente/bannermenupaciente.png"
                  className='imgResponsive'
                />

              </Grid>

            </Grid>

          </Grid>

        </Grid>

      </div>
    </>
  )

}