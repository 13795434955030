// Status do Contrato
/*
export enum TipoConsultaType {
  AVALIACAO = 'AV',
  ORTODONTIA = 'OR',
  OUTRAS = 'OU'
}

export const TipoConsultaTypes = [
  { idTipoConsulta: TipoConsultaType.AVALIACAO, descricao: 'Avaliação' },
  { idTipoConsulta: TipoConsultaType.ORTODONTIA, descricao: 'Ortodontia' },
  { idTipoConsulta: TipoConsultaType.OUTRAS, descricao: 'Outras' }
]
*/
// Ecuro Especialidades

export interface EspecialidadesEcuroInterface {
  specialityId: string
  descricao: string
}

export const EspecialidadesEcuroTypes: Array<EspecialidadesEcuroInterface> = [
  { specialityId: '01721964-940e-4de3-91ee-928132c8f478', descricao: 'Pediatria' },
  { specialityId: '24f8018c-6ee8-4b6d-ac69-082db6af4976', descricao: 'Alinhadores Invisíveis' },
  { specialityId: '29c265e2-9924-4ace-99bd-d61ce4bf482f', descricao: 'Outros' },
  { specialityId: '6af3ce7d-0903-43a9-b7a3-9b668f585752', descricao: 'Escaneamento' },
  { specialityId: '6e577463-d4a8-4a2d-bfeb-680473a46b0f', descricao: 'Estética' },
  { specialityId: '75185971-d682-4ef8-a72f-bb57e856e6d3', descricao: 'Procedimentos especiais' },
  { specialityId: '7b84125f-dc14-4e83-bc2e-2004a2c8c0b6', descricao: 'Radiologia' },
  { specialityId: '7f945176-078f-448e-aa9b-6d5cd95961e7', descricao: 'Ortodontia' },
  { specialityId: '8409c08e-f3fa-43a0-b9bd-53128cecdbdc', descricao: 'Avaliação' },
  { specialityId: '9f552dcc-d7e4-4906-aa42-dcbbd94ed638', descricao: 'Cirurgia' },
  { specialityId: 'a4ca7259-2849-44b7-9d93-f5e9f891f0ba', descricao: 'Cursos e Aulas' },
  { specialityId: 'bb1ba0bb-cbae-4c5b-8bd2-133c6bcbb593', descricao: 'Implante' },
  { specialityId: 'd4fe1a9a-7ba3-42dd-b023-5c85b47fa5aa', descricao: 'Teste Covid' },
  { specialityId: 'd5f1612a-e000-47d4-a72f-081b35f9c2b1', descricao: 'Endodontia' },
  { specialityId: 'd5f1612a-e000-47d4-a72f-081b35f9c2bx', descricao: 'Medicina' },
  { specialityId: 'e9d893b3-1de5-4f13-895d-37b06a2ea52f', descricao: 'Clinico Geral' }
]

export enum ECURO_ID_ESPECIALIDADES {
  PEDIATRIA = "01721964-940e-4de3-91ee-928132c8f478",
  ALINHADORESINVISIVEIS = "24f8018c-6ee8-4b6d-ac69-082db6af4976",
  OUTROS = "29c265e2-9924-4ace-99bd-d61ce4bf482f",
  ESCANEAMENTO = "6af3ce7d-0903-43a9-b7a3-9b668f585752",
  ESTETICA = "6e577463-d4a8-4a2d-bfeb-680473a46b0f",
  PROCEDIMENTOSESPECIAIS = "75185971-d682-4ef8-a72f-bb57e856e6d3",
  RADIOLOGIA = "7b84125f-dc14-4e83-bc2e-2004a2c8c0b6",
  ORTODONTIA = "7f945176-078f-448e-aa9b-6d5cd95961e7",
  AVALIACAO = "8409c08e-f3fa-43a0-b9bd-53128cecdbdc",
  CIRURGIA = "9f552dcc-d7e4-4906-aa42-dcbbd94ed638",
  CURSOSEAULAS = "a4ca7259-2849-44b7-9d93-f5e9f891f0ba",
  IMPLANTE = "bb1ba0bb-cbae-4c5b-8bd2-133c6bcbb593",
  TESTECOVID = "d4fe1a9a-7ba3-42dd-b023-5c85b47fa5aa",
  ENDODONTIA = "d5f1612a-e000-47d4-a72f-081b35f9c2b1",
  MEDICINA = "d5f1612a-e000-47d4-a72f-081b35f9c2bx",
  CLINICOGERAL = "e9d893b3-1de5-4f13-895d-37b06a2ea52f"
}

// Ecuro Status
export interface AppointmentStatusEcuroInterface {
  idStatus: number
  descricao: string
}

// Status Fornecido pelo Alex / Ecuro
export const AppointmentStatusEcuroTypes: Array<AppointmentStatusEcuroInterface> = [
  { idStatus: 1, descricao: 'Pendente' },
  { idStatus: 2, descricao: 'Sem Resposta' },
  { idStatus: 3, descricao: 'Reagendado' },
  { idStatus: 4, descricao: 'Confirmado' },
  { idStatus: 5, descricao: 'Cancelado' },
  { idStatus: 6, descricao: 'CheckIn Sala de Espera' },
  { idStatus: 7, descricao: 'Atendendo' },
  { idStatus: 8, descricao: 'Concluído' },
  { idStatus: 9, descricao: 'Sem Solução' },
  { idStatus: 10, descricao: 'Finalizado' },
  { idStatus: 11, descricao: 'Esperando Retorno' },
  { idStatus: 12, descricao: 'Retorno Criado' }
]

export enum AppointmentStatusEcuroType {
  PENDENTE = 1,
  SEM_RESPOSTA = 2,
  REAGENDADO = 3,
  CONFIRMADO = 4,
  CANCELADO = 5,
  CHECKIN_SALA_DE_ESPERA = 6,
  ATENDENDO = 7,
  CONCLUIDO = 8,
  SEM_SOLUCAO = 9,
  FINALIZADO = 10,
  ESPERANDO_RETORNO = 11,
  RETORNO_CRIADO = 12
}

/*
export interface StatusContratoTypesInterface {
  idStatus: string
  descricao: string
}

export enum StatusContratoType {
  CADASTRADO = 'CD',
  EMITIDO = 'EM',
  CANCELADO = 'CA',
  CONCLUIDO = 'CO'
}

export const StatusContratoTypes: Array<StatusContratoTypesInterface> = [
  { idStatus: StatusContratoType.CADASTRADO, descricao: 'Cadastrado' },
  { idStatus: StatusContratoType.CANCELADO, descricao: 'Cancelado' },
  { idStatus: StatusContratoType.CONCLUIDO, descricao: 'Concluído' },
  { idStatus: StatusContratoType.EMITIDO, descricao: 'Emitido' }
]

// Status da Parcela

export interface StatusParcelaTypesInterface {
  idStatus: string
  descricao: string
}

export enum StatusParcelaType {
  // 
  CADASTRADA = 'CD',
  // 
  EMITIDA = 'EM',
  // 
  CANCELADA = 'CA',
  // 
  QUITADA = 'PG'
}

export const StatusParcelaTypes: Array<StatusParcelaTypesInterface> = [
  { idStatus: StatusParcelaType.CADASTRADA, descricao: 'Cadastrada' },
  { idStatus: StatusParcelaType.CANCELADA, descricao: 'Cancelada' },
  { idStatus: StatusParcelaType.QUITADA, descricao: 'Quitada' },
  { idStatus: StatusParcelaType.EMITIDA, descricao: 'Emitida' }
]
*/