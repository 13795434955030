import React from 'react'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { Outlet } from 'react-router-dom'
import { THEME } from '../Config/Theme'
import { ContextoGlobal } from '../GlobalStates/ContextoGlobal'
import useLayoutState from '../GlobalStates/LayoutState'
import Mensagem from '../DevComponents/Mensagem'
import BottomBar from './BottomBar'
import TopAppBar from './TopAppBar'

import Menu from './Menu'
import Condicional from './Condicional'
import Login from '../View/Controller/Login'
import useMensagemState from '../GlobalStates/MensagemState'
import useLoginState from '../GlobalStates/LoginState'
import { useApolloClient } from '@apollo/client'
import NavegacaoIcones from './NavegacaoIcones'
import { TipoUsuario } from '../ImportBackend/Interfaces/RolesInterfaces'
import LoginPaciente from '../View/App/Login/LoginPaciente'
import ExibirJSONDev from '../DevComponents/ExibirJSONDev'

// TODO - Tela Inicial - Modelo Usuário ou Admin
export const EMDESENVOLVIMENTO = false

// import { EMDESENVOLVIMENTO } from '../ImportBackend/Config/emDesenvolvimento'

export default function Layout () {

  const { layoutState, setLayoutState } = useLayoutState()
  const { mensagemState, setMensagemState } = useMensagemState()
  const { loginState, setLoginState } = useLoginState()

  // TODO - Incluir Novo Módulo / Link
  /*
  const LivreAutenticacao = [
    'AlterarSenhaPaciente'
  ]
    */

  const LoginObrigatorioPaciente = [
    'PacienteMenu',
    'ConsultarConsultas',
    'AgendarManutencao',
    'MarcarOutrasEspecialidades',
    'NovoOrcamento',
    'TrocarSenha'
  ]

  const LoginObrigatorio = [
    'Dentistas',
    'ContaCorrente',
    'Pacientes',
    'Produtos',
    'Regionais',
    'Clinicas',
    'FeriadosNacionais',
    'Saldos',
    'relAgendamento',
    'relFaturamento',
    // 'relContratosEmitidos',
    // 'relCoraOnLine',
    // 'relContaCorrente',
    // 'relParcelas',
    // 'relMovimentacaoFinanceira',
    'Grupos',
    'Usuarios',
    'SetupWhatsAPP',
    'Login',
    'Admin',
    'DownloadEcuro',
    'Clientes'
  ]

  const chkLoginObrigatorio = ( logins: Array<string>, permitirQualquerParteURL: boolean = false ): boolean => {

    let retorno: boolean = false
    let url: string = window.location.href.toUpperCase()


    logins.forEach( v => {

      if ( permitirQualquerParteURL ) {

        retorno = retorno || url.toUpperCase().includes( v.toUpperCase() )

      } else {

        retorno = retorno || url.split( '/' ).pop()?.toUpperCase() === v.toUpperCase()

      }
    } )


    return retorno

  }

  const loginObrigatorioPaciente = chkLoginObrigatorio( LoginObrigatorioPaciente )

  const loginObrigatorioSistema = chkLoginObrigatorio( LoginObrigatorio )

  // const loginNaoObrigatorio = chkLoginObrigatorio( LivreAutenticacao )

  return (
    <>
      <ContextoGlobal.Provider value={{
        layoutState: layoutState,
        setLayoutState: setLayoutState,
        mensagemState: mensagemState,
        setMensagemState: setMensagemState,
        loginState: loginState,
        setLoginState: setLoginState,
        apolloClient: useApolloClient()
      }}>
        <CssBaseline />
        <ThemeProvider theme={THEME}>

          <Mensagem />

          <Condicional condicao={loginState.logado && loginState.tipo === TipoUsuario.SISTEMA}>

            <TopAppBar />

            <NavegacaoIcones></NavegacaoIcones>

            <Outlet />

            <BottomBar></BottomBar>

            <Menu></Menu>

          </Condicional>

          <Condicional condicao={ (!loginState.logado && loginObrigatorioSistema) || (EMDESENVOLVIMENTO && !loginState.logado)}>

            <Login />

          </Condicional>

          <Condicional condicao={!loginState.logado && !loginObrigatorioSistema && !loginObrigatorioPaciente}>

            <Outlet />

          </Condicional>

          <Condicional condicao={!loginState.logado && loginObrigatorioPaciente}>

            <LoginPaciente />

          </Condicional>

          <Condicional condicao={loginState.logado && loginState.tipo === TipoUsuario.PACIENTE}>
            <Outlet />
          </Condicional>

          <ExibirJSONDev exibir={false} oque={[
            'Tipo Usuario', loginState.tipo,
            'NOme Usuario', loginState.nome,
            'chkLoginObrigatorio( LoginObrigatorioPaciente )', chkLoginObrigatorio( LoginObrigatorioPaciente ),
            'chkLoginObrigatorio( LoginObrigatorio )', chkLoginObrigatorio( LoginObrigatorio ),
            'Logado', loginState.logado,
          ]} />

        </ThemeProvider>

      </ContextoGlobal.Provider>

    </>
  )
}