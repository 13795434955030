import React, { useContext, useEffect } from 'react'
import { ContextoGlobal, ContextoGlobalInterface } from '../../../GlobalStates/ContextoGlobal'
import BackEndAPI from '../../../Services/BackEndAPI'
import { Button, Container, Dialog, DialogActions, Grid, IconButton, Paper, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close'
import { RespostaPadraoInterface } from '../../../ImportBackend/Interfaces/PadraoInterfaces'
import { MensagemTipo } from '../../../GlobalStates/MensagemState'
import { ClienteInterface } from '../../../ImportBackend/Interfaces/ClienteInterfaces'
import InputText from '../../../DevComponents/InputText'

interface PropsInterface {
  rsCliente: ClienteInterface
  exibirAvisoClienteExistente: boolean
  setExibirAvisoClienteExistente: React.Dispatch<React.SetStateAction<boolean>>
}

export default function ClientesAvisoClienteExistenteModal ( { rsCliente, exibirAvisoClienteExistente, setExibirAvisoClienteExistente }: PropsInterface ) {

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface

  const { mensagemState, setMensagemState } = ( useContext( ContextoGlobal ) as ContextoGlobalInterface )

  const abortController: AbortController = new AbortController()

  const navigate = useNavigate()

  const clsApi = new BackEndAPI()

  // const [rsAmostras,setRsAmostras] = useState<Array<AmostraInterface>>([])

  const btFechar = () => {
    navigate( '/' )
  }
  /*
  const pesquisar = () => {
  
    const query: string = `
        getAmostrasPorMapaProducao {
          ok
          mensagem
    }
      `
  
    clsApi.query<Array<AmostraInterface>>( query, 'getAmostrasPorMapaProducao', 'Pesquisando Amostras...', contexto, abortController ).then( ( rs ) => {
  
      setRsAmostras( rs )
    } )
  
    return () => {
  
      abortController.abort()
    }
  }      
  
  /*
    useEffect( () => {
  
      pesquisar()
  
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] )
  
  */

  const onCloseDialog = () => {
    setExibirAvisoClienteExistente( false )
  }

  return (
    <>

      <Dialog onClose={onCloseDialog} open={exibirAvisoClienteExistente}>

        <Grid container sx={{ display: 'flex', padding: '30px', alignItems: 'stretch' }}>

          <Grid item xs={12}>
            <Typography variant='h6'>Cliente já cadastrado no APP</Typography>
          </Grid>

          <Grid item xs={12} md={6}>

            <InputText
              dados={rsCliente}
              field='cpf'
              label='CPF'
              mask="cpf"
              disabled
            />

          </Grid>

          <Grid item xs={12} md={6} sx={{ pl: { md: 1 } }}>
            <InputText
              dados={rsCliente}
              field='fone'
              label='Fone'
              type='tel'
              mask='tel'
              disabled
            />
          </Grid>

          <Grid item xs={12} md={8}>

            <InputText
              dados={rsCliente}
              field='nome'
              label='Nome'
              tipo='uppercase'
              disabled
              maxLength={50}
            />

          </Grid>

          <Grid item xs={12} md={4} sx={{ pl: { md: 1 } }}>
            <InputText
              dados={rsCliente}
              field='whatsAPP'
              label='Whats App'
              type='tel'
              mask='tel'
              disabled
            />
          </Grid>

        </Grid>

        <DialogActions>
          <Button autoFocus onClick={onCloseDialog}>
            Fechar
          </Button>
        </DialogActions>

      </Dialog>

    </>
  )

}