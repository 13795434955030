import React, { useState, useContext, useEffect } from 'react'
import { UnidadeOrganizacionalInterface } from '../../ImportBackend/Interfaces/UnidadeOrganizacionalInterfaces'
import { StatusForm } from './UnidadesOrganizacionais'
import BackEndAPI from '../../Services/BackEndAPI'
import DataTable, { DataTableCabecalhoInterface } from '../../DevComponents/DataTable'
import { Container, Grid, Paper } from '@mui/material'
import PesquisarTabela from '../../DevComponents/PesquisarTabela'
import ExibirJSONDev from '../../DevComponents/ExibirJSONDev'
import { ContextoGlobal, ContextoGlobalInterface } from '../../GlobalStates/ContextoGlobal'
import Condicional from '../../Layout/Condicional'
import { RespostaPadraoInterface } from '../../ImportBackend/Interfaces/PadraoInterfaces'
import { UsuarioInterface } from '../../ImportBackend/Interfaces/UsuarioInterfaces'
import ShowText from '../../DevComponents/ShowText'

interface PropsInterface {
  statusForm: StatusForm
  rsUnidadeOrganizacional: UnidadeOrganizacionalInterface
}

export default function UnidadesOrganizacionaisUsuarios ( { statusForm, rsUnidadeOrganizacional }: PropsInterface ) {

  const clsApi: BackEndAPI = new BackEndAPI()

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface

  const [pesquisa, setPesquisa] = useState( { descricao: '', idUsuario: 0 } )

  const [dados, setDados] = useState<Array<UsuarioInterface>>( [] )

  const Cabecalho: Array<DataTableCabecalhoInterface> = [
    {
      campo: 'nome',
      cabecalho: 'Usuário',
      alinhamento: 'left'
    }
  ]

  const onChangeUsuario = ( rs: { idUsuario: number, nome: string } ) => {

    if ( rs && rs.idUsuario > 0 ) {

      const cadastrado = dados.findIndex( v => v.idUsuario === rs.idUsuario )

      if ( cadastrado < 0 ) {

        const mutation: string = `
          incluirUnidadeOrganizacionalUsuario(idUnidadeOrganizacional: ${rsUnidadeOrganizacional.idUnidadeOrganizacional}, idUsuario: ${rs.idUsuario}) {
            ok
            mensagem
          }
        `

        clsApi.mutation<RespostaPadraoInterface>( mutation, 'incluirUnidadeOrganizacionalUsuario', 'Incluindo Usuário...', contexto ).then( rs => {

          if ( rs.ok ) {

            loadRsUnidadesOrganizacionaisUsuarios()

          }

        } )

      }

    }

    setPesquisa( { idUsuario: 0, descricao: '' } )

  }


  const btExcluirUsuarioUnidadeOrganizacional = ( rs: UsuarioInterface, indice: number ) => {

    const mutation: string = `
      delUnidadeOrganizacionalUsuario(idUnidadeOrganizacional: ${rsUnidadeOrganizacional.idUnidadeOrganizacional}, idUsuario: ${rs.idUsuario}) {
        ok
        mensagem
      }
    `

    clsApi.mutation<RespostaPadraoInterface>( mutation, 'delUnidadeOrganizacionalUsuario', 'Excluindo Usuário...', contexto ).then( () => {

      loadRsUnidadesOrganizacionaisUsuarios()

    } )

  }

  const loadRsUnidadesOrganizacionaisUsuarios = () => {

    const query: string = `
      getUnidadesOrganizacionaisUsuarios(idUnidadeOrganizacional: ${rsUnidadeOrganizacional.idUnidadeOrganizacional}) {
        idUsuario
        nome
      }
    `

    clsApi.query<Array<UsuarioInterface>>( query, 'getUnidadesOrganizacionaisUsuarios', 'Pesquisando Usuarios...', contexto ).then( rs => {

      setDados( rs )

    } )

  }

  useEffect( () => {
    if ( rsUnidadeOrganizacional && rsUnidadeOrganizacional.idUnidadeOrganizacional && rsUnidadeOrganizacional.idUnidadeOrganizacional > 0 ) {
      loadRsUnidadesOrganizacionaisUsuarios()
    } else {
      setDados( [] )
    }
    //eslint-disable-next-line
  }, [rsUnidadeOrganizacional.idUnidadeOrganizacional] )

  return (
    <>
      <Condicional condicao={statusForm === StatusForm.Editando || statusForm === StatusForm.Excluindo || statusForm === StatusForm.EditandoUsuarios}>

        <Container maxWidth="md" sx={{ mt: 5 }}>

          <Paper variant="outlined" sx={{ padding: 2 }}>
            <Grid item xs={12}>
              <ShowText
                dados={rsUnidadeOrganizacional}
                field='descricao'
                label='Unidade Organizacional'
              />
            </Grid>
            <Grid item xs={12}>

              <PesquisarTabela<any>
                onChange={( rs ) => onChangeUsuario( rs )}
                field='idUsuario'
                fieldSet='idUsuario'
                label='Usuário'
                dados={pesquisa}
                campoQueryPesquisaID='idUsuario'
                campoQueryPesquisa='pesquisa'
                camposRetornoQueryPesquisa='{idUsuario, nome}'
                campoLabelQueryPesquisa='nome'
                nomeQueryPesquisa='getUsuarios'
                nomeQueryPesquisaID='getUsuarioPorId'
                mensagemPesquisa='Procurando Usuarios...'
                disabled={statusForm !== StatusForm.EditandoUsuarios}
              />

            </Grid>

            <Grid item xs={12} sx={{ mt: 3 }}>
              <DataTable dados={dados} cabecalho={Cabecalho}
                acoes={[{ icone: 'delete', toolTip: 'Exluir', onDisabled: () => statusForm !== StatusForm.EditandoUsuarios, onAcionador: btExcluirUsuarioUnidadeOrganizacional }]}
              />
            </Grid>

            <ExibirJSONDev oque={['Dados Pacientes Clínicas: ', dados]} />

            <ExibirJSONDev oque={['Pesquisa Pacientes Clínicas: ', pesquisa]} />

          </Paper>
        </Container>
      </Condicional>
    </>
  )

}
