import React, { useContext, useEffect, useState } from 'react'
import { ContextoGlobal, ContextoGlobalInterface } from '../../../GlobalStates/ContextoGlobal'
import BackEndAPI from '../../../Services/BackEndAPI'
import { Button, Grid, IconButton, Paper, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'
import { RespostaPadraoInterface } from '../../../ImportBackend/Interfaces/PadraoInterfaces'
import { MensagemTipo } from '../../../GlobalStates/MensagemState'
import { ClinicaInterface } from '../../../ImportBackend/Interfaces/ClinicaInterfaces'
import ComboBox from '../../../DevComponents/ComboBox'
import ShowText from '../../../DevComponents/ShowText'
import { AgendamentoClienteCRMInputInterface, AgendamentoClienteInterface } from '../../../ImportBackend/Interfaces/AgendamentoClienteInterfaces'
import DataTable, { DataTableCabecalhoInterface } from '../../../DevComponents/DataTable'
import { AppointmentStatusEcuroTypes, EspecialidadesEcuroTypes } from '../../../ImportBackend/types/ConstantesDataTypes'
import Condicional from '../../../Layout/Condicional'
import { rsDisponibilidadeDentistaInterface } from '../../../ImportBackend/Interfaces/AgendamentoInterfaces'
import ClsValidacao from '../../../Utils/ClsValidacao'
import ExibirJSONDev from '../../../DevComponents/ExibirJSONDev'
import InputText from '../../../DevComponents/InputText'
import { clsUtils } from 'zlib-utils'
import ClsAcesso from '../../../Utils/ClsAcesso'
import { SISTEMA_PERMISSOES } from '../../../ImportBackend/types/AcessosDataTypes'
import ClienteAgendamentoForm from './ClienteAgendamentoForm'

interface PropsInterface {
  onCloseAgendamento: () => void
  rsCliente: {
    idCliente: number
    nome: string
    cpf: string
    idClinica: number
  }
}

interface PesquisaInterface {
  idClinica: number
}

interface ExclusaoAgendamentoInterface {
  idEcuroAppointment: string
  data: string
}

export enum StatusForm {
  Incluindo,
  Excluindo,
  Pesquisando,
  Editando
}

export default function ClienteAgendamentos ( { rsCliente, onCloseAgendamento }: PropsInterface ) {

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface

  const [statusForm, setStatusForm] = useState<StatusForm>( StatusForm.Pesquisando )

  const { setMensagemState } = ( useContext( ContextoGlobal ) as ContextoGlobalInterface )

  const abortController: AbortController = new AbortController()

  const clsApi = new BackEndAPI()

  const ResetDados: AgendamentoClienteCRMInputInterface = {
    data: '',
    horario: '',
    idCliente: rsCliente.idCliente,
    idClinica: rsCliente.idClinica,
    idDentista: 0,
    idEcuroEspecialidade: ''
  }

  const [dados, setDados] = useState<AgendamentoClienteCRMInputInterface>( ResetDados )
  const [dadosEdicaoAgendamento, setDadosEdicaoAgendamento] = useState<AgendamentoClienteCRMInputInterface>( ResetDados )
  const [rsExclusaoAgendamento, setRsExclusaoAgendamento] = useState<ExclusaoAgendamentoInterface>( {
    data: '',
    idEcuroAppointment: ''
  } )

  const btFechar = () => {
    onCloseAgendamento()
  }

  const [erros, setErros] = useState( {} )

  const [rsClinicasPermitidasPorUsuario, setRsClinicasPermitidasPorUsuario] = useState<Array<ClinicaInterface>>( [] )

  const [rsPesquisa, setRsPesquisa] = useState<PesquisaInterface>( { idClinica: rsCliente.idClinica } )

  const [rsAgendamentosCliente, setRsAgendamentosCliente] = useState<Array<AgendamentoClienteInterface>>( [] )

  const [rsDentistasDisponiveis, setRsDentistasDisponiveis] = useState<Array<rsDisponibilidadeDentistaInterface>>( [] )

  const permiteAlterarOutrosUsuarios = ( new ClsAcesso() ).chkAcesso( contexto.loginState.permissoes, SISTEMA_PERMISSOES.CLIENTES.MODULO, SISTEMA_PERMISSOES.CLIENTES.PERMISSOES.ACESSAR_OUTROS_USUARIOS )

  const Cabecalho: Array<DataTableCabecalhoInterface> = [
    {
      campo: 'data',
      cabecalho: 'Data',
      alinhamento: 'left'
    },
    {
      campo: 'horario',
      cabecalho: 'Horário',
      alinhamento: 'left'
    },
    {
      campo: 'AppointmentStatusEcuro',
      cabecalho: 'Status',
      alinhamento: 'left',
      format: ( idStatus: number ) => {
        const indice = AppointmentStatusEcuroTypes.findIndex( v => v.idStatus === idStatus )
        if ( indice >= 0 ) {
          return AppointmentStatusEcuroTypes[indice].descricao
        } else {
          return 'Sem Status Definido'
        }
      }
    }
  ]

  const pesquisarUsuarioClinicasPermitidas = () => {

    const query: string = `
        getUsuarioClinicasPermitidasPorAcesso {
          idClinica
          idEcuroClinica
          ativa
          descricao
          cidade
        }
      `

    clsApi.query<Array<ClinicaInterface>>( query, 'getUsuarioClinicasPermitidasPorAcesso', 'Pesquisando Clínicas...', contexto, abortController ).then( ( rs ) => {

      // console.log( rs )
      setRsClinicasPermitidasPorUsuario( rs )
    } )

    return () => {

      abortController.abort()
    }
  }

  const pesquisarAgendamentosUsuarioClinicas = ( idClinica: number ) => {

    const query: string = `
        listar_e_AtualizarStatusAgendamentosCliente(idClinica: ${idClinica}, idCliente: ${rsCliente.idCliente}) {
          idAgendamento
          idUsuario
          idCliente
          idClinica
          data
          horario
          idEcuroAppointment
          dataHoraAtualizacaoStatusEcuro
          AppointmentStatusEcuro
          idEcuroPaciente
          idEcuroClinica        
        }
      `

    clsApi.query<Array<AgendamentoClienteInterface>>( query, 'listar_e_AtualizarStatusAgendamentosCliente', 'Pesquisando Clínicas...', contexto, abortController ).then( ( rs ) => {
      // console.log( 'Agendamentos: ', rs )
      setRsAgendamentosCliente( rs )
    } )

    return () => {

      abortController.abort()

    }
  }

  useEffect( () => {

    pesquisarUsuarioClinicasPermitidas()

    pesquisarAgendamentosUsuarioClinicas( rsCliente.idClinica )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [] )

  const onChangeClinica = ( rs: ClinicaInterface ) => {

    if ( rs && rs.idClinica ) {

      setRsPesquisa( { idClinica: rs.idClinica as number } )
      pesquisarAgendamentosUsuarioClinicas( rs.idClinica as number )
      setDados( { ...ResetDados, idClinica: rs.idClinica as number } )

    } else {

      setRsPesquisa( { idClinica: 0 } )
      setRsAgendamentosCliente( [] )
      setDados( ResetDados )

    }

  }

  const btIncluirAgendamento = () => {
    setStatusForm( StatusForm.Incluindo )
    setDados( { ...ResetDados, idClinica: rsPesquisa.idClinica } )
  }

  const btAlterarAgendamento = ( rs: AgendamentoClienteInterface ) => {

    setDadosEdicaoAgendamento( {
      data: rs.data,
      horario: rs.horario,
      idCliente: rsCliente.idCliente,
      idClinica: dados.idClinica,
      idDentista: dados.idDentista,
      idEcuroEspecialidade: dados.idEcuroEspecialidade,
    } )

    setDados( {
      idAgendamento: rs.idAgendamento,
      data: '',
      horario: '',
      idCliente: rsCliente.idCliente,
      idClinica: dados.idClinica,
      idDentista: 0,
      idEcuroEspecialidade: dados.idEcuroEspecialidade
    } )

    setRsDentistasDisponiveis( [] )

    setStatusForm( StatusForm.Editando )
  }

  const btCancelarAgendamento = ( rs: AgendamentoClienteInterface ) => {

    setDadosEdicaoAgendamento( {
      data: rs.data,
      horario: rs.horario,
      idCliente: rsCliente.idCliente,
      idClinica: dados.idClinica,
      idDentista: dados.idDentista,
      idEcuroEspecialidade: dados.idEcuroEspecialidade,
    } )

    setRsExclusaoAgendamento( {
      data: rs.data,
      idEcuroAppointment: rs.idEcuroAppointment
    } )

    setStatusForm( StatusForm.Excluindo )

  }


  const btConfirmarAgendamento = () => {

    if ( validarDados() ) {

      const mutation: string = `
        agendarHorarioClienteCRM(dados: ${clsUtils.ConverterEmGql( dados )}) {
          ok
          mensagem
        }
      `

      clsApi.mutation<RespostaPadraoInterface>( mutation, 'agendarHorarioClienteCRM', 'Agendando Consulta...', contexto, abortController ).then( ( rs ) => {

        if ( rs.ok ) {
          setStatusForm( StatusForm.Pesquisando )
          pesquisarAgendamentosUsuarioClinicas( dados.idClinica )
        } else {
          setMensagemState( {
            cb: null,
            exibir: true,
            exibirBotao: true,
            tipo: MensagemTipo.Error,
            mensagem: rs.mensagem,
            titulo: "Erro ao marcar consulta!"
          } )
        }

      } )

      return () => {

        abortController.abort()

      }

    }

  }

  const btConfirmarCancelamento = () => {

    const mutation = `
        cancelarAgendamento (idEcuroAgendamento: "${rsExclusaoAgendamento.idEcuroAppointment}", data: "${rsExclusaoAgendamento.data}") {
          ok
          mensagem
        }
      `

    clsApi.mutation<RespostaPadraoInterface>( mutation, 'cancelarAgendamento', 'Cancelando Consulta...', contexto, abortController ).then( ( rs ) => {

      if ( rs.ok ) {
        setStatusForm( StatusForm.Pesquisando )
        pesquisarAgendamentosUsuarioClinicas( dados.idClinica )
      } else {
        setMensagemState( {
          cb: null,
          exibir: true,
          exibirBotao: true,
          tipo: MensagemTipo.Error,
          mensagem: rs.mensagem,
          titulo: "Erro ao marcar consulta!"
        } )
      }

    } )

    return () => {

      abortController.abort()

    }

  }

  const btCancelar = () => {
    setStatusForm( StatusForm.Pesquisando )
  }

  const validarDados = (): boolean => {

    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    retorno = clsValidacao.eData( 'data', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'idCliente', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'idEcuroEspecialidade', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'idClinica', dados, erros, retorno )
    retorno = clsValidacao.naoVazio( 'idDentista', dados, erros, retorno )
    retorno = clsValidacao.eHorario( 'horario', dados, erros, retorno )

    setErros( erros )

    return retorno

  }

  return (
    <>
      <Paper variant="outlined" sx={{ padding: { xs: 1, md: 3 } }}>
        <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end', mb: 3 }}>
            <IconButton onClick={() => btFechar()}>
              <CloseIcon />
            </IconButton>
          </Grid>

          <Grid item xs={12}>
            <ShowText dados={rsCliente} field='nome' label='Cliente' />
          </Grid>

          <Grid item xs={12}>
            <ShowText dados={rsCliente} field='cpf' label='CPF' />
          </Grid>

          <Grid item xs={12}>

            <ComboBox<ClinicaInterface>
              field='idClinica'
              label='Clínica'
              dados={rsPesquisa}
              onChange={( rs: ClinicaInterface ) => onChangeClinica( rs )}
              campoDescricao='descricao'
              campoID='idClinica'
              opcoes={rsClinicasPermitidasPorUsuario}
              // disabled={statusForm !== StatusForm.Pesquisando}
              disabled={true}
            />

          </Grid>

          <Condicional condicao={statusForm === StatusForm.Pesquisando && rsPesquisa.idClinica !== 0}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
              <Typography component="h6" variant="h6" align="left">
                Agendamentos no App
              </Typography>

              <IconButton onClick={() => btIncluirAgendamento()}>
                <AddIcon />
              </IconButton>
            </Grid>

            <Grid item xs={12}>

              <DataTable<AgendamentoClienteInterface>
                cabecalho={Cabecalho}
                dados={rsAgendamentosCliente}
                acoes={[
                  { icone: 'create', onAcionador: ( rs ) => btAlterarAgendamento( rs ), toolTip: 'Alterar', onDisabled: ( rs ) => rs.idUsuario !== contexto.loginState.idUsuario && !permiteAlterarOutrosUsuarios },
                  { icone: 'delete', onAcionador: ( rs ) => btCancelarAgendamento( rs ), toolTip: 'Cancelar Agendamento', onDisabled: ( rs ) => rs.idUsuario !== contexto.loginState.idUsuario && !permiteAlterarOutrosUsuarios }
                ]}
              />

            </Grid>

          </Condicional>

          <Condicional condicao={statusForm === StatusForm.Editando || statusForm === StatusForm.Excluindo}>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'left', mt: 3 }}>
              <Typography component="h5" variant="h5" align="left">
                {( statusForm === StatusForm.Editando ? "Alteração" : "Cancelamento" ).concat( " de Agendamento" )}
                <Typography variant="body2" gutterBottom>
                  Dados do Agendamento
                </Typography>
              </Typography>
            </Grid>

            <Grid item xs={12} md={4}>

              <ComboBox
                dados={dadosEdicaoAgendamento}
                opcoes={EspecialidadesEcuroTypes}
                field='idEcuroEspecialidade'
                label='Especialidade'
                disabled
                erros={erros}
                campoDescricao='descricao'
                campoID='specialityId'
                onChange={() => { }}
              />

            </Grid>

            <Grid item xs={12} md={4} sx={{ pl: { md: 1 } }}>

              <InputText
                dados={dadosEdicaoAgendamento}
                field='data'
                label='Data'
                type='date'
                disabled
                onChange={() => { }}
              />

            </Grid>

            <Grid item xs={12} md={4} sx={{ pl: { md: 1 } }}>

              <ComboBox
                campoDescricao='hora'
                campoID='hora'
                dados={dadosEdicaoAgendamento}
                field='horario'
                label='Horário'
                disabled
                opcoes={[{ hora: dadosEdicaoAgendamento.horario }]}
                onChange={() => { }}
              />

            </Grid>

          </Condicional>

          <Condicional condicao={statusForm === StatusForm.Incluindo || statusForm === StatusForm.Editando}>

            <ClienteAgendamentoForm
              dados={dados}
              rsDentistasDisponiveis={rsDentistasDisponiveis}
              setRsDentistasDisponiveis={setRsDentistasDisponiveis}
              erros={erros}
              setDados={setDados}
              statusForm={statusForm}
              idClinica={rsPesquisa.idClinica}
            />

          </Condicional>

          <Condicional condicao={statusForm !== StatusForm.Pesquisando}>

            <Grid item xs={12} textAlign='right' sx={{ mt: 3 }}>
              <Condicional condicao={statusForm === StatusForm.Editando || statusForm === StatusForm.Incluindo}>
                <Button variant='contained' onClick={() => { btConfirmarAgendamento() }} sx={{ mr: 2 }}>{statusForm === StatusForm.Incluindo ? 'Agendar' : 'Reagendar'}</Button>
              </Condicional>
              <Condicional condicao={statusForm === StatusForm.Excluindo}>
                <Button variant='contained' onClick={() => { btConfirmarCancelamento() }} sx={{ mr: 2 }}>Confirmar Cancelamento</Button>
              </Condicional>
              <Button variant='contained' onClick={() => { btCancelar() }}>Cancelar</Button>
            </Grid>

          </Condicional>

        </Grid>
      </Paper >

      <ExibirJSONDev oque={[
        'erros', erros,
        'Dados ClienteAgendamento.tsx', dados,
        'rsDentistasDisponiveis', rsDentistasDisponiveis
      ]} />

    </>
  )

}