import React, { ChangeEvent, ChangeEventHandler, EventHandler, useContext, useEffect, useState } from 'react'
import { ContextoGlobal, ContextoGlobalInterface } from '../../GlobalStates/ContextoGlobal'
import BackEndAPI from '../../Services/BackEndAPI'
import { Button, Container, Grid, IconButton, Paper, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close'
import { RespostaPadraoInterface } from '../../ImportBackend/Interfaces/PadraoInterfaces'
import { MensagemTipo } from '../../GlobalStates/MensagemState'
import { ParametrosInterface } from '../../ImportBackend/Interfaces/ParametrosInterfaces'
import InputText from '../../DevComponents/InputText'
import TextArea from '../../DevComponents/TextArea'
import ExibirJSONDev from '../../DevComponents/ExibirJSONDev'

import HelpIcon from '@mui/icons-material/Help';
import ParametrosHelp from './ParametrosHelp'
import ClsValidacao from '../../Utils/ClsValidacao'
import { clsUtils } from 'zlib-utils'

export default function Parametros () {

  const contexto = useContext( ContextoGlobal ) as ContextoGlobalInterface

  const [erros, setErros] = useState( {} )

  const [exibirParametrosHelp, setExibirParametrosHelp] = useState<boolean>( false )

  const abortController: AbortController = new AbortController()

  const navigate = useNavigate()

  const clsApi = new BackEndAPI()

  const ResetParametros: ParametrosInterface = {
    mensagemDiaAnteriorConsulta: '',
    mensagemDiaConsulta: '',
    enviarMensagemDiaAnteriorConsulta: false,
    enviarMensagemDiaConsulta: false
  }

  const [rsParametros, setRsParametros] = useState<ParametrosInterface>( ResetParametros )

  const btFechar = () => {
    navigate( '/' )
  }

  const validarDados = (): boolean => {

    let retorno: boolean = true
    let erros: { [key: string]: string } = {}

    let clsValidacao = new ClsValidacao()

    if ( rsParametros.enviarMensagemDiaConsulta ) {
      retorno = clsValidacao.naoVazio( 'mensagemDiaConsulta', rsParametros, erros, retorno )
    }

    if ( rsParametros.enviarMensagemDiaAnteriorConsulta ) {
      retorno = clsValidacao.naoVazio( 'mensagemDiaAnteriorConsulta', rsParametros, erros, retorno )
    }

    setErros( erros )

    return retorno

  }

  const btSalvar = () => {

    if ( validarDados() ) {
      const mutation: string = `
        updateParametros (dados: ${clsUtils.ConverterEmGql( rsParametros )}) {
          ok
          mensagem
        }
      `

      console.log( mutation )

      clsApi.mutation<RespostaPadraoInterface>( mutation, 'updateParametros', 'Atualizando Parametros...', contexto, abortController ).then( ( rs ) => {

        if ( rs.ok ) {
          btFechar()
        }

      } )


    }

  }

  const pesquisar = () => {

    const query: string = `
      getParametros {
        mensagemDiaConsulta
        mensagemDiaAnteriorConsulta
        enviarMensagemDiaAnteriorConsulta
        enviarMensagemDiaConsulta
      }
    `

    clsApi.query<ParametrosInterface>( query, 'getParametros', 'Pesquisando Parametros...', contexto, abortController ).then( ( rs ) => {

      setRsParametros( rs )

    } )

    return () => {

      abortController.abort()

    }
  }

  useEffect( () => {

    pesquisar()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [] )


  return (
    <>

      <Container maxWidth="lg" sx={{ mt: 5 }}>

        <ParametrosHelp exibir={exibirParametrosHelp} setExibir={setExibirParametrosHelp} />

        <Paper variant="outlined" sx={{ padding: 2 }}>
          <Grid container sx={{ display: 'flex', alignItems: 'stretch' }}>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
              <Typography component="h5" variant="h5" align="left">
                Parâmetros
                <Typography variant="body2" gutterBottom>
                  Setup para funcionamento do sistema
                </Typography>
              </Typography>

              <IconButton onClick={() => btFechar()}>
                <CloseIcon />
              </IconButton>
            </Grid>

            <Grid item xs={12} sx={{ mt: 3, textAlign: 'right' }}>
              <IconButton aria-label="delete" onClick={() => setExibirParametrosHelp( true )}>
                <HelpIcon fontSize="inherit" />
              </IconButton>
            </Grid>

            <Grid item xs={12} >
              <InputText
                field='enviarMensagemDiaConsulta'
                label='Enviar mensagem no dia da consulta'
                dados={rsParametros}
                setState={setRsParametros}
                erros={erros}
                tipo='checkbox'
              />

            </Grid>

            <Grid item xs={12} >

              <TextArea
                dados={rsParametros}
                field='mensagemDiaConsulta'
                label='Mensagem Para o Dia da Consulta'
                setState={setRsParametros}
                height='120px'
                erros={erros}
              />

            </Grid>

            <Grid item xs={12} >
              <InputText
                field='enviarMensagemDiaAnteriorConsulta'
                label='Enviar mensagem no dia anterior a consulta'
                dados={rsParametros}
                setState={setRsParametros}
                erros={erros}
                tipo='checkbox'
              />
            </Grid>

            <Grid item xs={12} >

              <TextArea
                dados={rsParametros}
                field='mensagemDiaAnteriorConsulta'
                label='Mensagem Para o Dia Anterior a Consulta'
                setState={setRsParametros}
                height='120px'
                erros={erros}
              />

            </Grid>
            <Grid item xs={12} sx={{ textAlign: 'right', mt: 3 }} >
              <Button variant='contained' onClick={() => btSalvar()}>Gravar Parâmetros</Button>
            </Grid>

          </Grid>
        </Paper>

      </Container>

      <ExibirJSONDev oque={['Parametros', rsParametros]} />

    </>
  )

}